const TableUrl = (payload, tableDetails, interAPi) => {
    if(interAPi){
        return {
            "url": `${process.env.VUE_APP_COMMON_SERVICES_TABLE}common/claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else{
        return {
            "url": `${process.env.VUE_APP_ALLOCATE_TABLE}surveyor/m/api/v1/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
}

const TableConfigUrl = (payload, tableDetails, interAPi='') => {
    if(interAPi){
        return {
            "url": `${process.env.VUE_APP_CASE_CONFIG_TABLE}config/${interAPi}/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else if(tableDetails.table_url=='searchRuntimeFieldsConfig'){
        return {
            "url": `${process.env.VUE_APP_CLAIM_TABLE}claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else{
        return {
            "url": `${process.env.VUE_APP_CASE_CONFIG_TABLE}config/user/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    
}



const DocumentTableUrl = (payload, tableDetails) => {
    return {
        "url": `${process.env.VUE_APP_DOCUMENT_TABLE}document/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
    }
}


const setTheme = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}configuration/api/commonConfig`,
        "mutation": "SET_THEME",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}

const getMenuPages = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/m/master/api/v1/fetchMenuPages`,
        "mutation": "GET_MENU_PAGES",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}

const GetWigetPages = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/m/master/api/v1/fetchWidgets`,
        "mutation": "GET_WIDEGET_PAGES",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}



const FetchCommunicationDetails = () =>{
    return {
        "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/fetchCommunicationDetails`,
        "mutation": "GET_COMMUNICATION_LIST",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}



const GetFormPage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPage`,
        "mutation": "GET_FORM_PAGE",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const GetPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "requests": {}
    }
}

const GetPopupPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_POP_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "encryption": "true",
        "module_name" : "multi_data_from",
        "root_access":"yes",
        "root_mutation_text":"GET_MD_ROOTSTATE_DETAILS",
    }
}

const PopUpPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "requests": {}
    }
}

const GetPageDetails = (name) => {
    if(name=='fetchClaimDetails'){
        return {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/claim/api/v1/${name}`,
            "mutation": "GET_CLAIM_DETAILS",
            "notify_message": "Success",
            "requests": {}
        }
    }
    else{
        return {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/claim/api/v1/${name}`,
            "mutation": "GET_PAGE_DETAILS",
            "notify_message": "Success",
            "requests": {}
        }
    }
}

const GetMultiReportsPageFields = () =>{
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "encryption": "true",
        "module_name" : "multi_data_from",
        "root_access":"yes",
        "root_mutation_text":"GET_MD_ROOTSTATE_DETAILS",
        "requests": {}
    }
}



const GetDropDownAPIs = (fieldName) => {
    console.log('fieldName', fieldName)
    let apiHolder = [{ 
        "lineOfBusiness":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLOBList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "documentName":{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/master/api/v1/fetchDocumentName`,
            "mutation": "GET_MASTER_LOOKUP",
            "module_name" : "multi_data_from",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "claimStatus":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchClaimStatusMaster`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "causeOffLoss":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchCauseOffLoss`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "location":{
            "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchUserLocationCodeList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "claimHandler":{
            "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchAllClaimHandlerNameList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "chName":{
            "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchAllClaimHandlerNameList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "module_name" : "multi_data_from",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "causeOfLoss" :{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchCauseOffLoss`,
            "mutation": "GET_MASTER_LOOKUP",
            "module_name" : "multi_data_from",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        }
    }]
    if(fieldName.includes('__')){
        let splitName = fieldName.split('__')
        if(splitName && splitName.length && (splitName.length>1) && (typeof apiHolder[0][splitName[1]] != "undefined")){
            return  apiHolder[0][splitName[1]]
        }
    }
    else if(typeof apiHolder[0][fieldName] != "undefined"){
        return  apiHolder[0][fieldName]
    }
    else{
        return ''
    }
}

const LookupByType = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLookUpByType`,
        "mutation": "GET_LOOKUP_VALUES",
        "send_payload": true,
        "notify_message": "Success",
        "requests": {}
    }
}

const LookupByTypeMulti = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLookUpByType`,
        "mutation": "GET_LOOKUP_VALUES",
        "send_payload": true,
        "notify_message": "Success",
        "module_name" : "multi_data_from",
        "requests": {}
    }
}



const MasterLookupByType = (masterAPI) => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/${masterAPI}`,
        "mutation": "GET_MASTER_VALUES",
        "notify_message": "Success",
        "requests": {}
    }
}



const GetPincodeCode = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/master/api/fetchLocationByPincode`,
        "mutation": "GET_PINCODE_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}

const UserCount = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/report/api/fetchUserCount`,
        "mutation": "GET_USER_COUNT",
        "notify_message": "Success",
        "requests": {}
    }
}

const UserSummaryCount = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/report/api/fetchClaimSummaryCount`,
        "mutation": "GET_SUMMARY_COUNT",
        "notify_message": "Success",
        "requests": {}
    }
}


const ReplicateUser = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveReplicateUser`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const serviceRatingDetails = () => {
    return {
        "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/fetchServiceRatingDetails`,
        "mutation": "RATING_DETAILS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const fetchMisReport = () => {
    return {
        "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_SURVEYOR}surveyor/m/report/api/v1/fetchMisReport`,
        "mutation": "MIS_REPORT_DATAS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const fetchPartyDetails = () => {
    return {
        "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_CONFIG}config/user/api/fetchPartyDetails`,
        "mutation": "PARTY_DETAILS_DATAS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const GetDashboardData = () => {
    return {
        "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/report/api/v1/fetchStatusCount`,
        "mutation": "GET_DASHBOARD_COUNT",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const GetPageAPIData = (pageame) => {
    let getpagename = (pageame) ? pageame.toLowerCase() : ''
    let apiData = {
        'inprogressclaim': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/fetchCommunicationDetails`,
            "mutation": "GET_PAGE_DETAILS",
        },

        'inprogressclaimsur': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/fetchCommunicationDetails`,
            "mutation": "GET_PAGE_DETAILS",
        },

        'feeinvoiceraise': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/fetchInvoiceDetails`,
            "mutation": "GET_PAGE_DETAILS",
        },

        'feeinvoiceapproval': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/fetchInvoiceDetails`,
            "mutation": "GET_PAGE_DETAILS",
        },
        'updateneft':{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/fetchNeftDetails`,
            "mutation": "GET_PAGE_DETAILS",
        },
        'panverification':{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/fetchClaimantPanDetails`,
            "mutation": "GET_PAGE_DETAILS",
        },
        "updateneftbankdetails":{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/fetchInsuredBankDetails`,
            "mutation": "UPFATE_NEFT_DETAILS",
        },
        // 'servicerating':{
        //     "url": `cccp/surveyor/m/communication/api/v1/fetchServiceRatingDetails`,
        //     "mutation": "GET_PAGE_DETAILS",
        // }
        'servicerating':{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchGradeQuestionnaire`,
            "mutation": "RATING_PAGE_FIELDS",
        },
        'todaysreview':{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/fetchClaimReviewDetails`,
            "mutation": "REVIEW_PAGE_FIELDS",
        }
    }
    

    let pageUrl = {};
    if(apiData[getpagename]){
        pageUrl = apiData[getpagename]
    }

    let tempData = {
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {},
        ...pageUrl
    }

    if(pageame){
        return tempData;
    }
    else{
        return '';
    }
    
}

const SavePageData = (pageame) => {
    let apiData ={
        'allocation': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/allocateSurveyorDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'communicationDetails': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/saveCommunicationDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'invoiceDetails': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/saveInvoiceDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'uploadDocument': {
            "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/uploadDocument`,
            "mutation": "SAVE_RESULTS",
        },
        'neftUploadDocument': {
            "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/uploadDocument`,
            "mutation": "SAVE_RESULTS",
        },
        'saveAction': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/saveAction`,
            "mutation": "SAVE_RESULTS",
        },
        'surveyDetails': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/allocateSurveyorDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'updateneft':{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/saveNeftDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'saveClaimPanDetails':{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/saveClaimantPanDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'claimReviewDetails':{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/saveClaimReview`,
            "mutation": "SAVE_RESULTS",
        },
        "serviceDetails":{
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/saveServiceRatingDetails`,
            "mutation": "SAVE_RESULTS",
        }
    }

    let pageUrl = {};
    if(apiData[pageame]){
        pageUrl = apiData[pageame]
    }
    
    if(pageUrl && Object.keys(pageUrl).length>0){
        return {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/allocateSurveyorDetails2`,
            "mutation": "SAVE_RESULTS",
            "notify_message": "Success",
            "success_message": "",
            "requests": {},
            ...pageUrl
        }
    }
    else{
        return false;
    }
}


const SaveFlashMessage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/home/api/saveFalshMessage`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveClientConfiguration = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/saveRuntimeFields`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveMenuAccess = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveUserAccess`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveAllocate = () => {
    return {
        "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/allocateSurveyorDetails`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}


const SaveInvoiceDetails = () => {
    return {
        "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/saveInvoiceDetails`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveAction = () => {
    return {
        "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/saveAction`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}




const SaveHierarchyPreference = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveHierarchyPreference`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}



const SaveHierarchyUserDetails = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveHierarchyUserDetails`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}



const GetTablePageData = (pageName) => {
    let url = '';
    if(pageName=='userTable'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/userSearch`; 
    }
    else if(pageName=='flashMessage'){
        url = `${process.env.VUE_APP_CONFIG}config/home/api/fetchFalshMessageList`; 
    }
    else if(pageName=='clientManagement'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/fetchHierarchyUserDetails`; 
    }
    else if(pageName.trim()=='userHierarchy'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/fetchHierarchyPreference`; 
    }
    else if(pageName.trim()=='clientFields1'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/searchRuntimeFieldsConfig`; 
    }

    return {
        "url": `${url}`,
        "mutation": "GET_TABLE_PAGE_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}
const FetchPageData = (pageName) => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/${pageName}`,
        "mutation": "GET_PAGE_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}


const DownloadDocument = () => {
    return {
        "url": `${process.env.VUE_APP_DOWNLOAD_SERVICES}document/api/downloadDocument`,
        "mutation": "GET_DOWNLOAD_DOCUMENT",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const FlashMessage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/home/api/fetchFlashMessages`,
        "mutation": "GET_FLASH_MESSAGE",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const fetchTemplateDocList = () => {
    return {
        "url": `${process.env.VUE_APP_DOWNLOAD_SERVICES}document/api/fetchTemplateDocList`,
        "mutation": "GET_DOC_LIST_DASHBOARD",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const FetchDashboardChartData = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/report/api/fetchClaimAnalysisCount`,
        "mutation": "GET_DASHBOARD_CHART",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}


const fetchUserHierarchy = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchUserHierarchyDetails`,
        "mutation": "GET_HIERERACHY_DASHBOARD",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const SaveForm = (apiName) => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/${apiName}`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveFormDetails = (apiName, pageName) => {
    let url = '';
    if(apiName && (pageName && pageName.trim()=="system-configuration")){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/${apiName}`
    }

    let finalResult = {
        "url": url,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
    return finalResult;
}

const UserProfile = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchUserProfile`,
        "mutation": "GET_USER_PROFILE",
        "notify_message": "Success",
        "requests": {}
    }
}

const verifyPandetails = () => {
    return {
        "url": `${process.env.VUE_APP_API_URL}/cccp/surveyor/m/payment/api/v1/verifyPANDetails`,
        "mutation": "VERIFY_PAN_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}


const FormUploadDocument = (details) => {
     return {
        "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/${(details) ? details : 'uploadTemplateDocument'}`,
        "mutation": "FORM_UPLOAD_DOCUMENT",
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const FormUploadClaimDocument = (details) => {
    return {
        "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/${(details) ? details : 'uploadDocument'}`,
        "mutation": "FORM_UPLOAD_DOCUMENT",
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const ferchnotification = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/notify/api/fetchRecentNotifications`,
        "mutation": "GET_NOTIFICATION_COUNT",
        "send_payload": true,
         "encryption": "true",
        "notify_message": "Success",
        "requests": {}
    }
}

const Savenotification = () => {
    return {
        "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_COMMON}common/notify/api/saveNotificationStatus`,
        "mutation": "GET_SUMMARY_COUNT",
        "notify_message": "Success",
        "requests": {}
    }
}

const SurveyorDetails = () => {
    return {
        "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/fetchAllocateSurveyorDetails`,
        "mutation": "GET_SURVEYOR_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}

const verifyPanNumber = () => {
    return {
        "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/verifyPANDetails`,
        "mutation": "VERIFY_PAN_NUMBER",
        "notify_message": "Success",
        "requests": {}
    }
}


export {
    verifyPanNumber,
    setTheme,
    getMenuPages,
    GetFormPage,
    UserProfile,
    GetPageFields,
    LookupByType,
    FetchPageData,
    MasterLookupByType,
    GetDropDownAPIs,
    TableUrl,
    DocumentTableUrl,
    SaveForm,
    SaveFormDetails,
    FormUploadDocument,
    FormUploadClaimDocument,
    DownloadDocument,
    TableConfigUrl,
    PopUpPageFields,
    GetPincodeCode,
    UserCount,
    UserSummaryCount,
    GetTablePageData,
    FlashMessage,
    fetchTemplateDocList,
    fetchUserHierarchy,
    ReplicateUser,
    SaveFlashMessage,
    SaveHierarchyPreference,
    SaveHierarchyUserDetails,
    SaveMenuAccess,
    SaveClientConfiguration,
    FetchDashboardChartData,
    GetMultiReportsPageFields,
    GetPageDetails,
    LookupByTypeMulti,
    SaveAllocate,
    SaveAction,
    FetchCommunicationDetails,
    GetPopupPageFields,
    SaveInvoiceDetails,
    SavePageData,
    GetPageAPIData,
    GetWigetPages,
    GetDashboardData,
    serviceRatingDetails,
    verifyPandetails,
    ferchnotification,
    Savenotification,
    SurveyorDetails,
    fetchMisReport,
    fetchPartyDetails
}