import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { BootstrapVue } from 'bootstrap-vue'
import VueResource from 'vue-resource'
// import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import * as Keycloak from 'keycloak-js'
import VueTour from 'vue-tour'
import VueCookies from 'vue-cookies';
import ToggleButton from 'vue-js-toggle-button'
import VueNumber from "vue-number-animation";
import VueApexCharts from 'vue-apexcharts'

import './assets/css/bootstrap.min.css'
import './assets/css/main.css'
import * as rules from 'vee-validate/dist/rules';
import * as VeeValidate from 'vee-validate';
import { messages } from './datas/validation_messages.json';
import './registerServiceWorker'
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'


console.log('SIT BUild')
Vue.config.productionTip = false
Vue.config.silent = true

window.onerror = function(msg, src, linenum, colnum, error) {
  console.log('errorHandler', msg, src, linenum, colnum, error)
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  // TODO: Perform any custom logic or log to server

};

window.addEventListener('error', function (e) {
  console.log('Inside error Listener', e.message)
 })
Object.keys(rules).forEach(rule => {
  VeeValidate.extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});



VeeValidate.extend('gst_number', {
  validate:  value => /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(value),
  message: messages['gst_number']
});

VeeValidate.extend('pan_number', {
  validate:  value => /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value),
  message: messages['pan_number']
});

VeeValidate.extend('claim_number', {
  validate:  value => /^OC-([0-9]){2}-([0-9]){4}-([0-9]){3,4}-([0-9]){8}?$/.test(value),
  message: messages['claim_number']
});

VeeValidate.extend('policy_number', {
  validate:  value => /^OG-([0-9]){2}-([0-9]){4}-([0-9]){4}-([0-9]){8}?$/.test(value),
  message: messages['policy_number']
});

VeeValidate.extend('ifsc_number', {
  validate:  value => /^[A-Za-z]{4}\d{7}$/.test(value),
  message: messages['ifsc_number']
});



VeeValidate.extend('one_digit', {
  validate:  value => /^\d+\.\d{0,1}$/.test(value),
  message: messages['one_digit']
});

VeeValidate.extend('decimal_value', {
  validate:  value => /^[1-9]\d*(\.\d+)?$/.test(value),
  message: messages['decimal_value']
});

VeeValidate.extend('fractions', {
  validate:(value)=>{
    let checkValue = value.toString();
      if((checkValue && !checkValue.startsWith('0')) && (checkValue && checkValue.includes('/') )){
          let checkSplit = checkValue.split('/')
          let finalResult = (checkSplit[0]/checkSplit[1]>0  && checkSplit[0]/checkSplit[1]<1)
          return (finalResult) ? true : false;
      }
      else{
          return false;
      }
  },
  message: messages['fractions']
});




VeeValidate.extend('alpha_numeric', {
  getMessage: field => 'The ' + field + 'should contain alphabetic characters.',
  validate:  value => /^[a-zA-Z0-9\-\s]+$/.test(value)
});

VeeValidate.extend('num_starts_zero', {
  validate:  value => /^0.*$/.test(value),
  message: messages['num_starts_zero']
});

VeeValidate.extend('mob_start_digits', {
  validate:  value => /^[6-9].*$/.test(value),
  message: messages['mob_start_digits']
});

VeeValidate.extend('greater_than_zero', {
   message: messages['greater_than_zero'],
   validate: (value) => {
     if (value > 0 ) return true;
     return false;
   }
});


let initOptions = {
  url: process.env.VUE_APP_KEY_CLOAK_URL,
  realm: process.env.VUE_APP_REALM,
  clientId: process.env.VUE_APP_CLIENT_ID,
  onLoad:'login-required'
}

if(window.ReactNativeWebView){
  initOptions['clientId'] = process.env.VUE_APP_MOBILE_CLIENT_ID
}

if(window.location.href.includes('payload')  || window.location.href.includes('reset-link')){
  new Vue({
    render: h => h(App),
    router,
    store,
    components: {
      App
    },
}).$mount('#app')
}else{
  let keycloak = Keycloak(initOptions);
  keycloak.init({ onLoad: initOptions.onLoad }).then((auth) =>{
     
   if(!auth) {
     window.location.reload();
   }
   else{
     if(store.state.auth && store.state.auth.auth){
        store.dispatch("SetLoginDetails", keycloak)
     }
     else{
        store.dispatch("SetLoginDetails", keycloak)
     }
   }
  
   new Vue({
       render: h => h(App),
       router,
       store,
       components: {
         App
       },
   }).$mount('#app')
  
  }).catch(() =>{
     Vue.$log.error("Authenticated Failed");
  });
  
  keycloak.onTokenExpired = () => {
    keycloak.updateToken(30).success((refreshed) => {
      if (refreshed) {
        store.dispatch("SetLoginDetails", keycloak)
      } 
    }).error(()=>{
        Vue.$log.error('Failed to refresh token');
    });
    keycloak.onAuthRefreshSuccess  = function() { console.log('authenticated'); }
  }
}

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(VueTour)
Vue.use(VueCookies);
Vue.use(ToggleButton)
Vue.use(VueNumber);
Vue.use(VueApexCharts)
Vue.use(VeeValidate, {
  enableAutoClasses: true
});

Vue.component('ApexChart', VueApexCharts)
Vue.component('ValidationObserver', VeeValidate.ValidationObserver);
Vue.component('ValidationProvider', VeeValidate.ValidationProvider);
Vue.component('v-select', vSelect)

