export default {
    menus () {
        return [{
                "id": "HOMELAYOUT",
                "path": "/",
                "name": "homelayout",
                "filePath": "container/pages/Layout",
                "auth" : true,
                "children": [
                {
                    "id": "HOME",
                    "path": "",
                    "name": "home",
                    "filePath": "container/pages/DashboardPage",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "PROFILE",
                    "path": "viewProfile",
                    "name": "viewProfile",
                    "filePath": "container/pages/Allocate/AllocateDetails",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "ALLOCATE",
                    "path": "pendingsurvey",
                    "name": "pendingsurvey",
                    "filePath": "container/pages/Allocate/Allocate",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "ALLOCATE",
                    "path": "allocate",
                    "name": "allocate",
                    "filePath": "container/pages/Allocate/Allocate",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "ALLOCATE",
                    "path": "challocate",
                    "name": "challocate",
                    "filePath": "container/pages/Allocate/Allocate",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "ALLOCATEDETAILS",
                    "path": "allocatedetails/:page_name",
                    "name": "allocatedetails",
                    "filePath": "container/pages/Allocate/AllocateDetails",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "SEARCH",
                    "path": "search",
                    "name": "search",
                    "filePath": "container/pages/Allocate/Allocate",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "MISREPORT",
                    "path": "misreport",
                    "name": "misreport",
                    "filePath": "container/pages/MisReport",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "SEARCHLAYOUT",
                    "path": "searchdetail",
                    "name": "searchdetail",
                    "filePath": "container/pages/Allocate/AllocateDetails",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "SEARCHPAGE",
                    "path": "searchdetails/:page_name",
                    "name": "searchdetails",
                    "filePath": "container/pages/Allocate/AllocateDetails",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "SEARCH",
                    "path": "misreport",
                    "name": "misreport",
                    "filePath": "container/pages/Search/Search",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                }]
            },
            {
                "id": "PROFILE",
                "path": "/profile",
                "name": "profile",
                "filePath": "container/pages/Profile",
                "auth" : true,
                "children": [],
                "roles":[]
            },
            {
                "id": "NOTIFICATION",
                "path": "/notification",
                "name": "Notification",
                "filePath": "container/pages/Notification",
                "auth" : true,
                "children": [],
                "roles":[]
            },
            
            {
                "id": "404",
                "path": "*",
                "name": "404",
                "filePath": "components/pages/404",
                "auth" : true,
                "children": [],
                "meta": {
                    "breadcrumb": 'Home'
                }
            },
            {
                "id": "FORGOTPASSWORD",
                "path": "/reset-link/forgot-password",
                "name": "ForgotPassword",
                "filePath": "container/pages/ForgotPassword",
                "auth" : true,
                "roles":[],
                "meta": {
                    "breadcrumb": 'Deterrence'
                }
            },
            {
                "id": "RESETPASSWORD",
                "path": "/reset-link/reset-password/:userid",
                "name": "ResetPassword",
                "filePath": "container/pages/ResetPassword",
                "auth" : true,
                "roles":[],
                "meta": {
                    "breadcrumb": 'Deterrence'
                }
            },
        ]
    }
}