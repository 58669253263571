import _ from 'lodash'
import {convert_difference ,convert_toiso ,convert_multi_date ,convert_ddmmyy } from './timer'
import {reportMenus_constants} from './constants'
import moment from 'moment'
let {report_pageCode, report_sectionCode } = reportMenus_constants
const AutoCalsPre = [{
    page_name : 'patient_visit_details',
    calcs: ['relationWithProposer','actualAge','pia','CalAge', 'idproof', 'patientDischarged', 'patientAvailable','accomadationType']
},
{
    page_name : 'discharged_questionnaire',
    calcs : ['Is the patient Contactable','admissionDateTime']
},
{
    page_name : 'patient_visit_questionnaire',
    calcs : ['descripencyAge','admissionDate']
},
{
    page_name: 'hospital_detail_questionnaire',
    calcs : ['prevHosp','admissionDateTimeRecord']
},
{
    page_name: 'qc_section',
    calcs : ['isDeterrenceRequired','qcInvStatus', 'statusOfInvestigation' ,'fraudSeverity','deterrenceField']
},
{
    page_name: 'qc2_section',
    calcs : ['qcInvStatus', 'statusOfInvestigation' ,'fraudSeverity','isDeterrenceRequired']
},
{
    page_name: 'qc3_section',
    calcs : ['qcInvStatus', 'statusOfInvestigation' ,'fraudSeverity','isDeterrenceRequired']
},
{
    page_name: 'invqc_section',
    calcs : ['qcInvStatus', 'statusOfInvestigation' ,'fraudSeverity']
},
{
    page_name: 'employee_verification',
    calcs : ['absentDateFrom','verificationRequired','insuredOccupation']
},
{
    page_name: 'loss_location_visit',
    calcs : ['lossOfCause']
},
{
    page_name: 'hospital_visit',
    calcs : ['indoorCasePaperCollected','hospitalCooperation','treatingDoctorDegree','paymentMode','doa']
},
{
    page_name : 'patient_demographics',
    calcs : ['age','idproof','relationWithInsured','relationWithProposer','actualAge']
},
{
    page_name : 'police_records',
    calcs : ['delayInFIR']
},
{
    page_name : 'statement_from_patient',
    calcs : ['accidentDate','roomType','anypreviousconsultation','paymentMode','admissionDate','accompaniedBySomeone','relationsPersonPatient','healthPolicyOtherInsurance']
},
{
    page_name : 'statement_from_treating_doctor',
    calcs : ['admissionDate','treatingDoctorDegree']
},
{
    page_name : 'radiologist_vist',
    calcs : ['reportSignedPerson','authorityAgreement']
},
{
    page_name : 'pathology_lab_pathologist_details',
    calcs : ['agreementBtwnPathologistAndLab','pathologyReportingAuthority']
},
{
    page_name : 'statement_from_family_doctor',
    calcs : ['whetherStatementfromFamilyDoctorAvailable',"treatingDoctorDegree"]
},
{
    page_name : 'lab_vist_questionnaire',
    calcs : ['pollutionCertificateavailable']
},
{
    page_name : 'hospital_infra_audit',
    calcs : ['infraAuditRequired']
}
]
const buildQueryParam = async(url, params) =>{
    if(url&&params){
        let query = url.split('?')[0] + '?'                         
        for (let d in params) 
            query += encodeURIComponent(d) + '='
                + encodeURIComponent(params[d]) + '&';                         
        return query.slice(0, -1) 
    }
}

const EncodeString = (enc_string) =>{
    if(enc_string){
        let _temp = JSON.stringify(enc_string);
        _temp  = window.btoa(_temp);
        return _temp;
    }
    return '';
}

const DeocdeString = (enc_string) =>{
    if(enc_string && window){
        let _temp = window.atob(enc_string);
        _temp  = JSON.parse(_temp);
        return _temp;
    }
    return '';
}

const ProcessCaseFields = (_curr_obj, payload) =>{
    let _temp_value = '';
    if (_curr_obj.field.includes('.')) {
        let _temp = _curr_obj.field.split('.');
       if(_temp.length==2){
            let _model_name = _.first(_temp)
            let _filed_name  = _.last(_temp)
            if(_model_name && _filed_name){
                let _main_model = payload[_model_name]
                if(Array.isArray(_main_model)){
                    let _temp_main_array = _.first(_main_model)
                    _temp_value =  (_temp_main_array && _filed_name  in _temp_main_array) ?  _temp_main_array[_filed_name] : '';
                }
                else{
                    if(_main_model && (_filed_name in _main_model)){
                        _temp_value =  _main_model[_filed_name];
                    }
                    else{
                        _temp_value = '';
                    }
                }
            
            }
       }
       else if(_temp.length==3){           
            let _main_model = payload[_temp[0]]
            if(!Array.isArray(_main_model)){
                _temp_value =  _main_model[_temp[1]][_temp[2]];
            }
       }
        
    }
    else if (_curr_obj.field in payload) {
        _temp_value =  payload[_curr_obj.field]
    }
    else{
        _temp_value = ''
    }
    return _temp_value;
}
const addToDisabledField = (field, form ,place, value = 0) => {
    field.disbabled = false                //if its disable we can't change the value
    form[place] = value
    field.disbabled = true
}
const dateValidation = (main_from , fields , startDate, endDate) => {         //end Date should be greater than start Date
    if (main_from[startDate]) { 
        
        let end_date = fields.find((list_sub) => list_sub.model == endDate)
        end_date.min_date = main_from[startDate]
    }
    if (main_from[startDate] && main_from[endDate] ) { 
        let diff = convert_difference(main_from[startDate], main_from[endDate], 'seconds')
        if (diff < 0) {
            main_from[endDate] = ''
        } 
    }
}
//differenceInDays(main_from,fields,'absentDateFrom','absentDateTo','autoCalculate')
const differenceInDays = (main_from , fields , startDate, endDate , difference) => {         //end Date should be greater than start Date
    var autoCalculate = fields.find((list_sub) => list_sub.model ==  difference )
    if (autoCalculate) {                            //for disable the field
        autoCalculate.disbabled = true;
    }
    if (main_from[startDate]) {            //for set min date
        var end_date = fields.find((list_sub) => list_sub.model == endDate )
        end_date.min_date = main_from[startDate]
    }
    if(main_from[startDate] && main_from[endDate]){
        let days = convert_difference(main_from[startDate], main_from[endDate], 'days')
        days < 0 ?  main_from[endDate] = '' : null
        if(days && days >= 0 ){
            main_from[difference] =  days
        }else{
            main_from[difference] =  0
        }
    }
}

const CalculateFromDatas = (form_value, fields, main_from, page_name, recived_fields, report_side_bar, main_page_name) => { 
   let _temp_details = AutoCalsPre.find((list)=>list.page_name == page_name)  
   if(_temp_details && _temp_details.calcs){
        _temp_details.calcs.forEach((list)=>{
            switch (list) {  
                //from to date validations
                case 'admissionDate':
                    dateValidation(main_from , fields , 'admissionDate' , 'dischargeDate');
                    break
                case 'doa':   
                    dateValidation(main_from , fields , 'doa' , 'dod');
                    break
                case 'admissionDateTimeRecord':   
                    dateValidation(main_from , fields , 'admissionDateTimeRecord' , 'dischargeDateTimeRecord');
                    break
                
                case 'admissionDateTime': 
                    dateValidation(main_from , fields , 'admissionDateTime' , 'dischargeDateTime');
                    break
                
                case 'accidentDate': {
                    let accidentDate = fields.find((list_sub) => list_sub.model == "accidentDate")
                    if(accidentDate){
                        accidentDate.max_date =  moment(Date.now()).toISOString()
                    }  
                }
                    break 
                   
                case 'pia':
                case 'age':    
                    
                    var ageValue = fields.find((list_sub) => list_sub.model == list)
                    var actual_age = fields.find((list_sub) => list_sub.model == "actualAge")
                    if (form_value.dob) {                                                //to set minimum date
                        var doa = fields.find((list_sub) => list_sub.model == "doa")     
                        doa.min_date = form_value.dob
                    }
                    if (form_value.dob && main_from.doa ) { 
                        if (convert_difference(form_value.dob, main_from.doa, 'days')<0) {
                            main_from["doa"] = ''
                            addToDisabledField(ageValue,main_from,list)    //if its disable we can't change the value
                            addToDisabledField(actual_age,main_from,'actualAge')
                        }
                    }
                    if(form_value.doa ){
                        let years = (form_value.doa, form_value.dob,'years')
                        if (years && years > 0) {
                            addToDisabledField(ageValue,main_from,list,years)
                        } else {
                            addToDisabledField(ageValue,main_from,list)
                        }
                    }
                    break 
                    case 'delayInFIR':
                        differenceInDays(main_from,fields,'accidentDate','dof','noOfDaysDelayFir')  
                    break
                    case 'absentDateFrom':  
                        //differenceInDays(main_from,fields,'absentDateFrom','absentDateTo','autoCalculate')
                    if (main_from[list]) {
                         var autoCalculate = fields.find((list_sub) => list_sub.model == "autoCalculate")
                        if (autoCalculate) {                            //for disable the field
                            autoCalculate.disbabled = true;
                        }
                        if (main_from.absentDateFrom) {            //for set min date
                            var absentDateTo = fields.find((list_sub) => list_sub.model == "absentDateTo")
                            absentDateTo.min_date = main_from.absentDateFrom
                        }
                        //let checkDisplay = fields.find((list_sub) => list_sub.model == "resignationReason")       // because of visibility condition checks
                        //if (checkDisplay.check_display == 'true') {
                            
                            if(main_from.absentDateTo && main_from.absentDateFrom){
                                let days = convert_difference(main_from.absentDateFrom, main_from.absentDateTo, 'days')
                                days < 0 ?  main_from["absentDateTo"] = '' : null
                                autoCalculate.disbabled = false
                                if(days && days >= 0 ){
                                    main_from['autoCalculate'] =  (days + 1)
                                }else if(days == 0){
                                    main_from['autoCalculate'] =  (days + 1)
                                }else{
                                    main_from['autoCalculate'] =  0
                                }
                                autoCalculate.disbabled = true
                            } 
                        //}
                        
                    }
                        
    
                    break
                case 'actualAge':
                    var _age = fields.find((list_sub) => list_sub.model == "actualAge")
                    if (_age) {
                        _age.disbabled = true;
                    }
                    if (form_value.doa && form_value.actualDob) {
                        let years = convert_difference(form_value.doa, form_value.actualDob, 'years')
                        var actualAge = fields.find((list_sub) => list_sub.model == "actualAge")
                        if (years && years > 0) {
                            addToDisabledField(actualAge,main_from,list,years)       //if its disable we can't change the value
                        } else {
                            addToDisabledField(actualAge,main_from,list)
                        }
                    }
                    break
                    case 'isDeterrenceRequired' :  {
                    var _qcRemarks = fields.find((data) => data.model == "qcRemarks" )  
                    //let rules = _qcRemarks.rules                   
                    if (main_from[list]) {
                        var _yesOrNo = main_from[list] || ''
                        if (_yesOrNo == 'Yes') {
                                _qcRemarks.rules = _qcRemarks.rules + "|required"
                        } else {
                            if(_qcRemarks.rules.includes("required|")){
                                _qcRemarks.rules = _qcRemarks.rules.replace("required|", "")
                            }else if(_qcRemarks.rules.includes("required")){
                                _qcRemarks.rules = _qcRemarks.rules.replace("required", "")
                            }
                        }
                    } else {
                        if(_qcRemarks){
                            if(_qcRemarks.rules.includes("required|")){
                                _qcRemarks.rules = _qcRemarks.rules.replace("required|", "")
                            }else if(_qcRemarks.rules.includes("required")){
                                _qcRemarks.rules = _qcRemarks.rules.replace("required", "")
                            }
                        }
                    } 
                }
                    break
                    case 'deterrenceField':{
                        let isDisabledFraud = fields.find((data) => data.model == "fraudSeverity" )
                        let isDeterrenceRequired = fields.find((data) => data.model == "isDeterrenceRequired" ) || []
                        if(main_from['severityOfFraud'] == "Hard" || main_from['fraudSeverity'] == "Hard" ){
                            isDeterrenceRequired.disbabled = true
                        }else if(main_from['severityOfFraud'] == "Soft" || main_from['fraudSeverity'] == "Soft" ){
                            isDeterrenceRequired.disbabled = false
                            if(isDisabledFraud && isDisabledFraud.disbabled  ){
                                isDeterrenceRequired.disbabled = true
                            }
                        }/* else{
                            isDeterrenceRequired.disbabled = true
                        } */


                    }

                        break
                
                //visibility condition checks
                case 'roomType':
                case 'treatingDoctorDegree':
                case 'pollutionCertificateavailable':
                case 'accomadationType':
                case 'whetherStatementfromFamilyDoctorAvailable':
                case 'agreementBtwnPathologistAndLab':
                case 'pathologyReportingAuthority':
                case 'authorityAgreement':
                case 'reportSignedPerson':
                case 'relationWithProposer':
                case 'relationWithInsured':
                case 'idproof':
                case 'paymentMode':
                case 'relationsPersonPatient':
                case 'accompaniedBySomeone':
                case 'indoorCasePaperCollected':
                case 'lossOfCause' :
                case 'insuredOccupation':
                case 'Is the patient Contactable':  
                    var _check_fields = fields.filter((list_sub) => list_sub.model == list )  
                    if (_check_fields) {                       
                        if (form_value[list]) {
                            let temp_field = _.first(_check_fields)
                            if (_.size(temp_field) > 0 && _.size(temp_field.custom_validation) > 0) {
                                let validation = temp_field.custom_validation 
                                if (form_value[list] == 'No' && list == 'accompaniedBySomeone') {
                                    let checkFields = fields.find((list) => list.model == 'personPatientremarks')
                                    checkFields.check_display = 'false'
                                }
                                if (form_value[list] == 'No' && list == 'whetherStatementfromFamilyDoctorAvailable') {
                                    let checkFields = fields.filter((list) =>  list.model == 'doctorQualificationOthers'   )  //list.model == 'hospitalDoctorOthers' || list.model == 'treatingDoctorOthers' ||
                                    checkFields.forEach(element => {
                                        element.check_display = 'false'
                                    });
                                }
                                var yesOrNo = ( form_value[list] !== '' && (form_value[list] == "Yes" || form_value[list] == "Salaried" || form_value[list] == "Saleried" || form_value[list] == "Others" || form_value[list] == "others"  )  ) ? 'Yes' : 'No' //get Yes or No
                                if (form_value[list] == "Others" && list == 'insuredOccupation' ) {
                                    yesOrNo = 'No'
                                }
                                if (list == 'treatingDoctorDegree') {
                                    if (form_value[list][0]) {
                                        let others = form_value[list][0]
                                        yesOrNo = others.text == 'Others' ? 'Yes' : 'No'
                                    }
                                    
                                    
                                }
                                if (validation[yesOrNo]) {
                                    let inter_val = validation[yesOrNo] 
                                    if(inter_val.length>0){
                                        inter_val.forEach((_list)=>{ 
                                            let model = _list.field
                                            let filterData = fields.filter((data)=>model == data.model) || []                                            
                                            if(filterData.length>0){
                                                filterData.forEach((data)=>{                                                         
                                                    data.check_display = _list.check_display
                                                    let rules = data.rules
                                                     if(data.check_display=="false"){
                                                         if(main_from[data.model]){
                                                            main_from[data.model] = ''
                                                         }                                                       
                                                        if(rules.includes("required|")){
                                                            rules = rules.replace("required|", "")
                                                        }else if(rules.includes("required")){
                                                            rules = rules.replace("required", "")
                                                        }
                                                        data.rules = rules                                                       
                                                    }else if(data.check_display=="true"){                                                       
                                                        if((rules==""&&!rules.includes("required"))){
                                                            rules = "required"
                                                        }else if((rules!=""&&!rules.includes("required|")&&!rules.includes("required"))){
                                                            rules = "required|" + rules
                                                        }  
                                                         data.rules = rules  
                                                         validation = []    
                                                    }                                                                                               
                                                })
                                            }                                    
                                        })
                                    }
                              }
                            }
                        }                        
                    }
                break
                case 'descripencyAge' :
                        if(recived_fields && recived_fields.patientDetails){
                            var ageData = fields.filter((list_sub) => list_sub.model == "descripencyAge")    
                            if(ageData.length>0){
                                ageData.forEach((data)=>{ 
                                    data.disbabled = true                               
                                })
                                let {patientDetails} = recived_fields
                                if(patientDetails.actualDob && patientDetails.dob){
                                    let isSameDate = moment(patientDetails.actualDob).isSame(patientDetails.dob)                                    
                                    if(isSameDate){
                                        main_from["descripencyAge"] = 'No'
                                    }else{
                                        main_from["descripencyAge"] = 'Yes'
                                    }
                                }else{
                                    main_from["descripencyAge"] = 'Yes'
                                }                             
                            }
                        }                        
                    break
                case 'patientDischarged' :                        
                        if(recived_fields && recived_fields.patientDetails){
                            let patientDetails = _.first(recived_fields.patientDetails);                            
                            //if(patientDetails.patientDischarged){
                                let filterData = report_side_bar.find((list_sub) => list_sub.page_url == main_page_name)                                
                                if(_.size(filterData)>0 && _.size(filterData.childmenu)>0){
                                    let frmval = (form_value && form_value['patientDischarged']) ? form_value['patientDischarged'] : ''
                                    let patVal =  (patientDetails && patientDetails['patientDischarged']) ? patientDetails['patientDischarged'] : ''

                                    let isDischarged = frmval || patVal || ''                                    
                                    filterData.childmenu.forEach((data)=>{ 
                                        if(data.url==report_sectionCode.discharge_questionnaire){
                                            if(isDischarged=="Yes"){                                                 
                                                data.isChildMenuHide = false
                                            }else{
                                                data.isChildMenuHide = true
                                            }                                             
                                        }                             
                                    })                                    
                                }
                            //}
                            
                        }                        
                    break
                case 'anypreviousconsultation':
                    if ( main_from['anypreviousconsultation']) {
                        let fieldValue = main_from['anypreviousconsultation']
                        var date = fields.find((list_sub) => list_sub.model == 'dateofpreviousconsultation')
                        if (fieldValue == 'Yes') {
                              date.check_display="true"
                        } else {
                              date.check_display="false"
                        }
                    }
                    
                    //var _check_fieldsIO = fields.filter((list_sub) => list_sub.model == 'insuredOccupation')
                                                
                    break
                case 'prevHosp' :                        
                        if(recived_fields && recived_fields.hospitalDetails){
                            let {hospitalDetails} = recived_fields
                            let isPreviousHosp = form_value['prevHosp'] || hospitalDetails['prevHosp'] || ''
                            let val = true
                            if(isPreviousHosp=="Yes"){
                                val = false
                            }                            
                            let filterData = report_side_bar.filter((list_sub) => list_sub.page_url == report_pageCode.pastHospital)                                
                            filterData.forEach((_list)=>{ 
                                if(_list.page_url==report_pageCode.pastHospital){
                                    _list.isParentMenuHide = val
                                    _list.childmenu.forEach((data)=>{ 
                                        data.isChildMenuHide = val
                                    })
                                }                                
                            })
                        }                        
                    break
                case 'patientAvailable' :
                        if(recived_fields && recived_fields.patientDetails){
                            let patientDetails = _.first(recived_fields.patientDetails) 
                                let filterData = report_side_bar.find((list_sub) => list_sub.page_url == main_page_name)                                
                                if(_.size(filterData)>0 && _.size(filterData.childmenu)>0){
                                    let isPatientAvailable =  '' 
                                    
                                    let frmval = (form_value && form_value['patientAvailable']) ? form_value['patientAvailable'] : ''
                                    let patVal =  (patientDetails && patientDetails['patientAvailable']) ? patientDetails['patientAvailable'] : ''

                                    isPatientAvailable = frmval || patVal || ''
                                    filterData.childmenu.forEach((data)=>{ 
                                        if(data.url==report_sectionCode.patient_visit_questionnaire){
                                            if(isPatientAvailable=="Yes"){                                                 
                                                data.isChildMenuHide = false
                                            }else{
                                                data.isChildMenuHide = true
                                            }                                             
                                        }                             
                                    })                                    
                                }
                        }
                    break
                //Dynamic options
                case 'statusOfInvestigation':  
                case 'qcInvStatus' :                           
                            if(main_from[list]){
                                let invStatus = main_from[list] || ''
                                let fraud_severity = main_from['fraudSeverity'] || ''
                                let filterData = [];
                                if (invStatus == 'Additional Requirement') {
                                    filterData = fields.filter((data)=>( data.model!="qcRemarks" )) || []
                                }
                                else if (invStatus == 'Genuine') {
                                    filterData = []
                                    filterData = fields.filter((data) => (data.model == "fault" || data.model == "fraudSeverity" || data.model == "isHospitalSuspicious" || data.model == "isDetailInvestigationRequired"|| data.model == "groundRejection"|| data.model == "isCaseNonDisclosure"|| data.model == "qcdocuments"|| data.model == "isDeterrenceRequired" || data.model == "evidence"  || data.model == "qcRemarks" ) ) || []
                                }
                                let caseData = fields.find((data) => data.model == list) || {}
                                if(filterData.length>0){
                                    filterData.forEach((_data) => {
                                        if (invStatus == 'Genuine' ) {
                                            _data.disbabled = true;
                                            //main_from[_data["model"]] = ''
                                                if(Array.isArray(main_from[_data["model"]])){
                                                    main_from[_data["model"]] = null
                                                }
                                                main_from["evidence"] = ""
                                                if(_data.rules.includes("required|")){
                                                    _data.rules = _data.rules.replace("required|", "")
                                                }else if(_data.rules.includes("required")){
                                                    _data.rules = _data.rules.replace("required", "")
                                                } 
                                        }else if (invStatus == 'Additional Requirement') { 
                                            if(_data.rules.includes("required|")){
                                                _data.rules = _data.rules.replace("required|", "")
                                            }else if(_data.rules.includes("required")){
                                                _data.rules = _data.rules.replace("required", "")
                                            } 
                                            let qcdocuments = fields.find((data) => data.model == "qcdocuments") || []
                                            if (qcdocuments.files && qcdocuments.files.length == 0) {     //for files
                                                qcdocuments.rules = 'required'
                                            }
                                            if (qcdocuments.files && qcdocuments.files.length > 0) {
                                                qcdocuments.rules = ''
                                                qcdocuments.additional_rules = ''
                                            }
                                            fields.forEach((_data) => {
                                                if (caseData.disbabled == false || caseData.disbabled == "false") {
                                                    _data.disbabled = false;
                                                }
                                                
                                            })
                                        }
                                        else {
                                        if (caseData.disbabled == false || caseData.disbabled == "false") {
                                                _data.disbabled = false;
                                        }
                                        if(!_data.rules.includes('required'))
                                            _data.rules = _data.rules + "|required"
                                            if(_data.files&&_data.files.length>0){     //for files
                                                _data.rules = ''
                                            }
                                        }
                                    })
                                }
                                 else {
                                    fields.forEach((_data) => {
                                    if (caseData.disbabled == false   ) {
                                        _data.disbabled = false;
                                    }
                                    if ((!_data.rules.includes('required')) && _data.isMandatory == true ) {
                                        _data.rules = _data.rules + "|required"
                                    }
                                    })
                                    let yesOrNo = main_from['isDeterrenceRequired'] || ''
                                    let qcRemarks = fields.find((data) => data.model == "qcRemarks" ) || []
                                    if (yesOrNo == 'Yes') {
                                        qcRemarks.rules = "required"
                                    } else {
                                        qcRemarks.rules = ""
                                    }
                                } 
                                
                                // to add dynamic options in perticular field
                                let fraudSeverityData = fields.filter((data) => data.model == "fraudSeverity" || data.model == "severityOfFraud" ) || []
                                if(fraudSeverityData.length>0){                                    
                                    let getDefault = _.first(fraudSeverityData).defaultOptions
                                    let optiondata = []
                                    if(getDefault){
                                        optiondata = [...getDefault]
                                    }
                                    let qcdocuments = fields.find((data) => data.model == "qcdocuments") || []
                                    if (qcdocuments.files && qcdocuments.files.length > 0 || invStatus == 'Additional Requirement' ) {
                                        qcdocuments.rules = ''
                                        qcdocuments.additional_rules = ''
                                        
                                    }
                                    //qcdocuments.rules = ""
                                    let isDeterrenceRequired = fields.find((data) => data.model == "isDeterrenceRequired" ) || []
                                    let qcRemarks = fields.find((data) => data.model == "qcRemarks") || [] 
                                    if (invStatus == 'Fraud' && fraud_severity == 'Hard') { 
                                       // main_from['isDeterrenceRequired'] = "Yes"   changed in reportSubmitView
                                        isDeterrenceRequired.disbabled = true
                                        qcRemarks.rules = "required"
                                        if (qcdocuments.files && qcdocuments.files.length > 0) {
                                            qcdocuments.rules = ''
                                            qcdocuments.additional_rules = ''
                                        }
                                    } else {
                                        if (qcdocuments.files && qcdocuments.files.length > 0) {
                                            qcdocuments.rules = ''
                                            qcdocuments.additional_rules = ''
                                        }
                                        if (caseData.disbabled == false ) {
                                            isDeterrenceRequired.disbabled = true
                                        }
                                        // main_from['isDeterrenceRequired'] = ""
                                        // delete main_from['isDeterrenceRequired'] 
                                        // main_from['isDeterrenceRequired'] = "Select"
                                        /* console.log( main_from['isDeterrenceRequired']," main_from['isDeterrenceRequired']")
                                        let yesOrNo = main_from['isDeterrenceRequired'] || ''
                                        let qcRemarks = fields.find((data) => data.model == "qcRemarks" ) || []
                                        if (yesOrNo == 'Yes') {
                                            qcRemarks.rules = "required"
                                        } else {
                                            qcRemarks.rules = ""
                                        } */

                                        //qcRemarks.rules = "required"
                                        //isDeterrenceRequired.rules = ""
                                        if (invStatus == 'Genuine') {
                                            isDeterrenceRequired.disbabled = true
                                        }
                                    }
                                    if (invStatus === "Fraud") {  
                                        let option = optiondata.filter((data) => data.text != "Not Applicable") 
                                        if (main_from.fraudSeverity=="Not Applicable") {
                                            main_from.fraudSeverity = null
                                        }                                         
                                        _.first(fraudSeverityData).options = option
                                        if (caseData.disbabled == false ) {
                                            _.first(fraudSeverityData).disbabled = false
                                        }

                                        let isDisabledFraud = fields.find((data) => data.model == "fraudSeverity" ) 
                                        let isFraud = fields.filter((data) => (data.model == "isDeterrenceRequired" || data.model == "qcRemarks"  ) ) || []
                                        isFraud.forEach((field)=>{
                                            if(!isDisabledFraud.disbabled ){
                                                field.disbabled = false
                                            }
                                        })
                                        
                                    } 
                                    else if (invStatus !== "Fraud") {                                        
                                        let data = _.first(fraudSeverityData)
                                        let getDefault = data.defaultOptions
                                        let optiondata = []
                                        if(getDefault){
                                            optiondata = [...getDefault]
                                        }
                                        
                                        data.options = optiondata
                                        data.disbabled = true     
                                        if (data.model == 'severityOfFraud') {
                                            main_from['severityOfFraud'] = "Not Applicable"
                                        } else if (data.model == 'fraudSeverity') {
                                            main_from['fraudSeverity'] = "Not Applicable"
                                        }
                                        
                                        let isFraud = fields.filter((data) => (data.model == "isDeterrenceRequired" || data.model == "qcRemarks"  ) ) || []
                                        isFraud.forEach((field)=>{
                                            field.disbabled = true
                                            field.rules = ""
                                            if(field.model == 'isDeterrenceRequired'){
                                                main_from[field.model] = 'Select'
                                            }else{
                                                main_from[field.model] = ''
                                            }
                                        })
                                        


                                }
                                }//end
                            }                            
                    break
                // required toggling
                case 'healthPolicyOtherInsurance':
                case 'hospitalCooperation':
                case 'verificationRequired':
                            if(main_from[list]){
                                let yesOrNo = main_from[list] || ''
                                var filterData = [];
                                if (list == 'verificationRequired') {
                                    filterData = []
                                    filterData = fields.filter((data) => data.model == "insuredJobStatus" || data.model == "employeeAddress__districtName" || data.model == "employeeAddress__stateName" || data.model == "employeeAddress__pincode" || data.model == "employeeAddress__postoffice" || data.model == "employeeAddress__address1" || data.model == "name" || data.model == "insuredOccupation") || []
                                } else if (list == 'hospitalCooperation') {
                                    filterData = []
                                    filterData = fields.filter((data) => data.model != "name"   ) || []
                                    //yesOrNo = yesOrNo == 'Yes' ? 'No' : 'Yes'  //toggle Yes/No for hospitalCooperation
                                } else if (list == 'healthPolicyOtherInsurance') {
                                    filterData = []
                                    filterData = fields.filter((data) => data.model == "otherInsuranceDetails") || []
                                }
                                    if(filterData.length>0){
                                        filterData.forEach((_data) => {
                                            if (yesOrNo == "Yes") {
                                                if (list == 'verificationRequired') {      //verification required as Yes, need to make following fields as non-mandatory
                                                    if (_data.rules.includes("required")) {
                                                        if(_data.rules.includes("required|")){
                                                            _data.rules = _data.rules.replace("required|", "")
                                                        }else if(_data.rules.includes("required")){
                                                            _data.rules = _data.rules.replace("required", "")
                                                        } 
                                                    }
                                                }
                                                else if (!_data.rules.includes("required") &&  _data.model != "hospitalType"  ) {
                                                    _data.rules = _data.rules + "|required"
                                                    
                                                }
                                            } else if (yesOrNo == "No" || yesOrNo == "") {
                                                if (list == 'verificationRequired') {      //verification required as Yes, need to make following fields as non-mandatory
                                                    if (!_data.rules.includes("required") &&  _data.model != "hospitalType"  ) {
                                                        _data.rules = _data.rules + "|required"
                                                        
                                                    }
                                                }
                                                else if(_data.rules.includes("required|")){
                                                    _data.rules = _data.rules.replace("required|", "")
                                                }else if(_data.rules.includes("required")){
                                                    _data.rules = _data.rules.replace("required", "")
                                                } 
                                            }  
                                        })
                                }
                            }                            
                        break
                case 'fraudSeverity' :                            
                        if(main_from['fraudSeverity']){
                            let fraudSeverity = main_from['fraudSeverity'] || ''
                            let filterData = fields.filter((data)=>data.model=="isHospitalSuspicious") || [] 
                            let isDeterrenceRequired = fields.find((data) => data.model == "isDeterrenceRequired" ) || []
                                if(filterData.length>0&&fraudSeverity!=''){
                                    filterData.forEach((_data)=>{
                                        if(fraudSeverity=="Hard"){                                                
                                            main_from['isHospitalSuspicious'] = "Yes"
                                            _data.disbabled = true
                                            isDeterrenceRequired.disbabled = true
                                        }else{
                                            main_from['isHospitalSuspicious'] = "No"
                                            _data.disbabled = true
                                        }  
                                    })
                                }
                        }                            
                    break 
                case 'infraAuditRequired':
                        if(main_from['infraAuditRequired']){
                            var _check_fields1 = fields.filter((list_sub) => list_sub.model!=list )  
                            if(main_from['infraAuditRequired']=='Yes'){
                                _check_fields1.forEach(element => {
                                    element.check_display = 'true'
                                });
                            }else{
                                _check_fields1.forEach(element => {
                                    element.check_display = 'false'
                                });
                            }
                        }
                break
                
                default:
                break
            }
        })
    }
}

const CasePageDetails = (page_name) =>{
    switch(page_name){
        case 'policy_details':  case 'policy_detail': 
            return 'GetMemberDetails';

        case 'claim_history':
            return 'GetClaimHistory';

        case 'special_exclusion':   
            return '';

        case 'claim_details': case 'overview': 
            return 'GetClaimDetails';

        case 'legal_search':
            return 'GetLegalSearchCase';
        
        case 'endorsement_details':
            return 'GetEndorsementHistory';
            
            
        case 'member_details': 
            return 'GetMemberDetails';

        case 'beneficiary_details':   
            return 'GetBeneficiaryDetails'; 

        case 'provider_details':   
            return 'GetProviderDetails'; 
            
        case 'trigger_checklist':   
            return 'GetInvestigationTriggers'; 
            

        case 'claim_processing_details':
            return 'GetClaimProcessingDetails';

        case 'hospitalization_details':
            return 'GetHospitalizationDetails';

        case 'bill_details':   
            return 'GetHospitalizationBillDetails';

        case 'repudiation_remarks':
            return 'GetRepudiationRemarks';

        default:
    }
}

const FieldStatusCheck  = (page_level, field_level, side_menu_level={}, fieldAuthority) => {  
    if(side_menu_level.pageAuthority=="READ"){
        return true
    }
    else if(side_menu_level.sectionAuthority=="READ"){
        return true
    }
    else if(side_menu_level.sectionAuthority=="READ_WRITE" && (field_level && field_level.authorityCode=='READ')){
        return true
    }
    else if(side_menu_level.sectionAuthority=="READ_WRITE" && (field_level==null && fieldAuthority=='READ')){
        return true
    }
    else if(field_level && field_level.authorityCode=='READ'){
        return true
    }
    else if((field_level==null && fieldAuthority=='READ')){
        return true
    }
    else {
        return false
    }
}

const ReportSubmitPageDetails = (page_name) =>{       
    if(page_name=="patient_visit_details" || page_name=="patient_demographics"  || page_name=="patient_qc_questionnaire" || page_name=="patient_visit_questionnaire" || page_name=="pvobservation_investigation_findings"){        
        return 'GetPatientDetails'
    }
    else if(page_name=="discharged_questionnaire" || page_name=="pvdocuments" ){
        return 'GetPatientDetails'
    }
    else if(page_name=="hospital_visit" || page_name=="hospital_detail_questionnaire" || page_name=="hospital_detail_qc_questionnaire" || page_name=="hvdocuments" || page_name=="hvobservation_investigation_findings" || page_name=="hospital_details" || page_name=="documents_rhd"){      
        return 'GetHospitalDetails'
    }
     else if(page_name=="case_details"){      
        return 'FetchCaseDetails'
    } 
    else if (page_name == "hospital_infra_audit") {
        return 'GetHospitalInfraDetails'
    }
    else if(page_name=="radiologist_vist" || page_name=="radiologist_vist_questionnaire" || page_name=="rvdocuments" || page_name=="rvobservation_investigation_findings" || page_name=="radiology_details" || page_name=="documentsrrd"){      
        return 'GetRadiologyDetails'
    }
    else if(page_name=="medical_store_visit" || page_name=="medical_store_visit_questionnaire" || page_name=="medical_store_vist_qc_questionnaire" || page_name=="msvdocuments" || page_name=="msvobservation_investigation_findings"){      
        return 'GetMedicalVisit'
    }
    else if(page_name=="lab_visit" || page_name=="lab_vist_questionnaire" || page_name=="lab_visit_qc_questionnaire" || page_name=="hiadocuments" || page_name=="hiaobservation_investigation_findings"|| page_name=="pathology_lab_pathologist_details"|| page_name=="documentsrpl"){      
        return 'GetPathologyDetails'
    }
    else if(page_name=="statement_from_treating_doctor" || page_name=="documents_rtdstm"){
        return 'GetTreatDoctorStmt'
    }
    else if(page_name=="statement_from_family_doctor" || page_name=="documents_rfdstm"){
        return 'GetFamilyDoctorStmt'
    }
    else if(page_name=="vicinity_verification" || page_name=="documents_rvvd"){
        return 'GetVinicityDetails'
    }

    else if(page_name=="police_record_documents" || page_name=="police_records"){
        return 'GetPoliceRecords'
    }
    else if(page_name=="loss_location_visit" || page_name=="loss_locate_documents"){
        return 'GetLossLocationVisitDetails'
    }
    else if(page_name=="employee_verify_documents" || page_name=="employee_verification"){
        return 'GetEmployeeVerificationDetails'
    }
    else if(page_name=="other_insurer" || page_name=="other_insurer_documents"){
        return 'GetOtherInsurerVerifyDetails'
    }

    
    else if(page_name=="payment_receipts_verifications" || page_name=="documentsrprv"){
        return 'GetPaymentDetails'
    }
    else if(page_name=="past_hospital_visit" || page_name=="past_hospital_vist_questionaire" || page_name=="phvdocuments" || page_name=="phvobservation_investigation_findings" ){
        return 'GetPastHospitalDetails'
    }

    else if(page_name=="demography_details" || page_name=="rpddocuments"){
        return 'GetDemographyDetails'
    }
    else if(page_name=="stmt_patient_documents" || page_name=="statement_from_patient"){
        return 'GetPatientStmtDetails'
    }
    else if(page_name=="qc_section"|| page_name=="qc2_section"|| page_name=="qc3_section" || page_name=="invqc_section"){
        return 'GetQcSectionDetails'
    }
    else if(page_name=="evidence_grid"){
        return 'GetEvidenceGrid'
    }
    else if(page_name=="vendor_rating"){
        return 'GetGradeQustionnaires'
    }
    else if(page_name=="trigger_questionnaire" ){
        return 'GetTriggerQuestionnaire'
    }
}

function stripHtml(html)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

function checkType(case_status)
{ 
    switch(case_status){
        case 'numeric':
            return 'number';

        default:
            return case_status;
    }
}

function isJSON(str){
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
    
}




const CaseStatusDisplay  = (case_status_check) =>{
    let case_status = (case_status_check) ? case_status_check.toLowerCase() : ''
    switch(case_status){
     
        case 'success':
            return `<div class="badge badge-success w-100">${case_status_check}</div>`;

        case 'error':
            return `<div class="badge badge-danger w-100">${case_status_check}</div>`;

        case 'pending':
            return `<div class="badge badge-warning w-100">${case_status_check}</div>`;

        default:
            return case_status;

    }
}

const TextTransform = (text, type) =>{
    if(type=="first_capse"){
        if(text){
            let _caseconvertWord = text.toLowerCase().split(' ');
            for (var i = 0; i < _caseconvertWord.length; i++) {
                _caseconvertWord[i] = _caseconvertWord[i].charAt(0).toUpperCase() +
                _caseconvertWord[i].substring(1);
            }
            return _caseconvertWord.join(' ');
        }
        return text
    }
}

const CaseHistoryDisplay  = (case_status) =>{
    switch(case_status){
        case 're-allocate':
            return 'yellow';

        case 'Send Back to QC':
            return 'yellow';
        
        case 'Close':
            return `red`;

        case 'Allocate': case 'Assigned': case 'Accepted':
            return `green`;

        case 'Pending Allocation':
            return `purple`;

            

        default:
            return `red`;
    }
}

const CaseBottomDisplay  = (case_status) =>{
    switch(case_status){
        case 'accept':
            return 'button-bordered-green';
        
        case 'allocate':
            return 'button-green case_details_bottom';
        case 'decline':
            return 'button-bordered-red';
        default:
            return `button-bordered case_details_bottom`;
    }
}

const formatReportFields = (fields, field_values, _temp_object) => { 
    for(let data in fields){
        
        let _curr_obj = fields[data]
        let _split = fields[data].model.split("__");
        if(_split.length>=2){
            let _check_details = _.first(_split);
            if(field_values[_check_details]){
                if(fields[data].model.includes("__")){ 
                    let _split = fields[data].model.split("__");
                    if(_split.length==3){
                        let a =  `${fields[data].model.split("__")[0]}`
                        let b =  `${fields[data].model.split("__")[1]}`                    
                        let c =  `${fields[data].model.split("__")[2]}`                    
                        field_values[`${a}__${b}__${c}`] = field_values[a][b][c]   
                    
                    }
                    else if(_split.length==2){
                        let a =  `${fields[data].model.split("__")[0]}`
                        let b =  `${fields[data].model.split("__")[1]}`               
                        field_values[`${a}__${b}`] = field_values[a][b]   
                      
                    }
                }    
            }
        }
        if(fields[data].type=="fileupload" || fields[data].type=="multi_fileupload" ){            
            fields[data].rules = "";
            fields[data].additional_rules = fields[data].isMandatory ? "required" : '';
            let temp_attach = (field_values.attachments && field_values.attachments.length > 0 )?  field_values.attachments.filter((list)=>list.fieldCode==fields[data].model) : []
            fields[data].files = (temp_attach) ? temp_attach.map((list)=>{
                return {
                    file_name : list.docName,
                    file_id : list.docId,
                }
            }) : []
        }
        else if(fields[data].type=="multi_datetimepicker" || fields[data].type=="multi_text_field" || fields[data].type=="multi_datepicker"  ){   
            
            if (field_values && field_values.fieldValues && field_values.fieldValues.length) {
                field_values.fieldValues = _.orderBy(field_values.fieldValues, ['id'], ['asc']);
                _temp_object[fields[data].model] = new Array();
                let _temp_count = 0;
                field_values.fieldValues.forEach((list, index) => {
                    if (list.fieldCode == fields[data].fieldCode) {
                        if (list.value) {
                            let multiValue = null
                            if (fields[data].type=="multi_datetimepicker") {
                                multiValue = convert_toiso(list.value) 
                            } else if (fields[data].type=="multi_datepicker") {
                                multiValue = convert_multi_date(list.value)
                            } else {
                                multiValue = list.value
                            }
                            _temp_object[fields[data].model][_temp_count] = multiValue
                            field_values.fieldValues[index].inital_index = _temp_count
                            field_values.fieldValues[index].isActive = true
                            _temp_count++
                        }
                    }
                })
                if (_temp_count) {
                    fields[data].field_count = _temp_count
                }
            }
            else{
                 _temp_object[fields[data].model] = new Array();
            }

        }
        else {
            if (field_values[_curr_obj.model] != '') {
            
                _temp_object[_curr_obj.model] = (_curr_obj.model  in field_values) ? field_values[_curr_obj.model] : ''  
                if((_curr_obj.type=="datepicker" || _curr_obj.type=="datetimepicker")){
                    if(isDate(_temp_object[_curr_obj.model])){
                        let str = _temp_object[_curr_obj.model];
                        let darr = str.split("/");
                        let isDataTime =  _.last(darr).includes('AM')|| _.last(darr).includes('am')||_.last(darr).includes('PM')||_.last(darr).includes('pm')                       
                        let _dobj = ''
                            if(isDataTime){
                                _dobj = moment(str,'DD-MM-YYYY HH:mm:ss').toISOString();       
                            }else{
                                _dobj = moment.utc(str,'DD-MM-YYYY').local().toISOString();                           
                            }
                        _temp_object[_curr_obj.model] = _dobj
                    } 
                } 
            }
            else{
                if(_curr_obj.type=="custom_select" || _curr_obj.type=="select"){                            
                    _temp_object[_curr_obj.model] = ''
                }
            }  
        }
    }
}

const formatReportFileFields  = (fields, field_values, _temp_object) =>{ 
   
    let _enabled_model = [];
    for(let data in fields){
        if(fields[data].type=="fileupload_full"){         
            fields[data].model = fields[data].model+'upload';
        }
        let _curr_obj = fields[data]
        let _split = fields[data].model.split("__");
        if(_split.length>=2){
            let _check_details = _.first(_split);
            if(field_values[_check_details]){
                if(fields[data].model.includes("__")){ 
                    let _split = fields[data].model.split("__");
                    if(_split.length==3){
                        let a = `${fields[data].model.split("__")[0]}`
                        let b =  `${fields[data].model.split("__")[1]}`                    
                        let c =  `${fields[data].model.split("__")[2]}`                    
                        field_values[`${a}__${b}__${c}`] = field_values[a][b][c]   
                    
                    }
                    else if(_split.length==2){
                        let a = `${fields[data].model.split("__")[0]}`
                        let b =  `${fields[data].model.split("__")[1]}`               
                        field_values[`${a}__${b}`] = field_values[a][b]   
                      
                    }
                }    
            }
        }
        // let _field_value = field_values.filter((list)=>list.docName==null && list.fieldCode!="")
        let _present_object = _curr_obj;
        let _present_data = field_values.find((list)=>list.docName==null && list.remarks!='' && list.fieldCode==_present_object.model)
       
        if(_present_object.model && _present_data){
            _temp_object[`${_curr_obj.model}_select`] = 'No'
            _temp_object[`${_curr_obj.model}_checklistId`] = (_present_data.checkListId) ? _present_data.checkListId : 0
            _temp_object[_curr_obj.model] = _present_data.remarks;
            _enabled_model.push(_curr_obj.model)
            
            let _find_iddexs = _.keys(_.pickBy(fields, {model: _curr_obj.model+'upload'}))
            if(_find_iddexs.length){
                fields[_find_iddexs[0]].disbabled = true;
                fields[_find_iddexs[0]].rules = '';
                fields[_find_iddexs[0]].isMandatory = false
                fields[_find_iddexs[0]].additional_rules = '';
            }

            fields[data].rules = 'required';
            fields[data].isMandatory = true

            
        }
        else{
            let final_model_name = _curr_obj.model;
            if(final_model_name){
                if(final_model_name.includes('__')){
                    final_model_name = final_model_name.split('__');
                    final_model_name = _.first(final_model_name);
                }
                else if(final_model_name.includes('_')){
                    final_model_name = final_model_name.split('_');
                    final_model_name = _.first(final_model_name);
                }
               
            }
            if(_enabled_model.includes(final_model_name)){
                if(_curr_obj.model.includes('_select')){
                    _temp_object[_curr_obj.model] = 'No'
                }
                else if(_curr_obj.model.includes('__upload')){
                    _curr_obj.disbabled = true
                }
            }
            else{
                if(_curr_obj.model.includes('_select')){
                    _temp_object[_curr_obj.model] = 'Yes'
                }
                else if(_curr_obj.model.includes('__upload')){
                    _curr_obj.disbabled = true
                    _curr_obj.rules = "required";
                }
                else if(_curr_obj.type=="textarea_din"){
                    _curr_obj.disbabled = true
                    _curr_obj.rules = "";
                }
            }  
        }
    }

}

const FromateSingleObject  = (fields, field_values, _temp_object) =>{     
    for(let data in fields){
        let _curr_obj = fields[data]
        let _split = fields[data].model.split("__");
        let _check_details= '';
        if(_split && _split.length>=2){
            _check_details = _.last(_split);
            if(field_values[_check_details]){
                let a = `${_split[0]}`
                let b =  `${_split[1]}`     
                field_values[`${a}__${b}`] = field_values[_check_details]
            }else{
                if(fields[data].type=="fileupload"){  
                    fields[data].rules = fields[data].isMandatory ? "required" : '';
                    fields[data].additional_rules = fields[data].isMandatory ? "required" : '';
                    let temp_attach = (field_values.attachments && field_values.attachments.length > 0 )?  field_values.attachments.filter((list)=>list.fieldCode==_check_details) : []
                    fields[data].files = (temp_attach) ? temp_attach.map((list)=>{
                        return {
                            file_name : list.docName,
                            file_id : list.docId,
                        }
                    }) : []
                }
            }
        }
        if(field_values[_curr_obj.model]!='' && _check_details!=''){
            _temp_object[_curr_obj.model] = (_check_details  in field_values) ? field_values[_check_details] : ''
        }
    }
    
}





const  string2Bin = (str) => {
    var result = [];
    for (var i = 0; i < str.length; i++) {
      result.push(str.charCodeAt(i));
    }
    return result;
  }
  
const  bin2String = (array) => {
    return String.fromCharCode.apply(String, array);
}

const isDate = (dateStr) => {
    
    if(isNaN(dateStr)){ //Checked for numeric
      var dt=new Date(dateStr);
      if(isNaN(dt.getTime())){ //Checked for date
        if(_.isString(dateStr) && ((dateStr && dateStr.includes('/') && dateStr.split('/').length>=3) || (dateStr && dateStr.includes('-') && dateStr.split('-').length==3))){
            return true;
        }
        else{
            return false; //Return string if not date.
        }
       
      }
      else{
        if(_.isString(dateStr) && ((dateStr && dateStr.includes('/') && dateStr.split('/').length>=3) || (dateStr &&  dateStr.includes('-') && dateStr.split('-').length>=3))){
            return true;
        }
        else{
            return false;
        }
        //Return date **Can do further operations here.
      }
    } else{
      return false; //Return string as it is number
    }
  }

const ContentFormate = (formate_name) =>{
    let _formats = {
        "xlsx":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "html":"text/html",
        "htm" : "text/html",
        "shtml":"text/html",
        "css":"text/css",
        "xml":"text/xml",
        "gif":"image/gif",
        "jpeg":"image/jpeg",
        "jpg":"image/jpeg",
        "txt":"text/plain",
        "png":"image/png",
        "tif":"image/tiff",
        "tiff":"image/tiff",
        "wbmp":"image/vnd.wap.wbmp",
        "ico":"image/x-icon",
        "jng":"image/x-jng",
        "bmp":"image/x-ms-bmp",
        "svg":"image/svg+xml",
        "webp":"image/webp",
        "jar":"application/java-archive",
        "war":"application/java-archive",
        "ear":"application/java-archive",
        "hqx":"application/mac-binhex40",
        "doc":"application/msword",
        "pdf":"application/pdf",
        "ps":"application/postscript",
        "eps":"application/postscript",
        "ai":"application/postscript",
        "rtf":"application/rtf",
        "xls":"application/vnd.ms-excel",
        "ppt":"application/vnd.ms-powerpoint",
        "wmlc":"application/vnd.wap.wmlc",
        "kml":"application/vnd.google-earth.kml+xml",
        "kmz":"application/vnd.google-earth.kmz",
        "7z":"application/x-7z-compressed",
        "cco":"application/x-cocoa",
        "jardiff":"application/x-java-archive-diff",
        "jnlp":"application/x-java-jnlp-file",
        "run":"application/x-makeself",
        "pl":"application/x-perl",
        "pm":"application/x-perl",
        "prc":"application/x-pilot",
        "pdb":"application/x-pilot",
        "rar":"application/x-rar-compressed",
        "rpm":"application/x-redhat-package-manager",
        "sea":"application/x-sea",
        "swf":"application/x-shockwave-flash",
        "sit":"application/x-stuffit",
        "tcl":"application/x-tcl",
        "tk":"application/x-tcl",
        "der":"application/x-x509-ca-cert",
        "pem":"application/x-x509-ca-cert",
        "crt":"application/x-x509-ca-cert",
        "xpi":"application/x-xpinstall",
        "xhtml":"application/xhtml+xml",
        "zip":"application/zip",

        "mid":"audio/midi",
        "midi":"audio/midi",
        "kar":"audio/midi",
        "mp3":"audio/mpeg",
        "ogg":"audio/ogg",
        "ra":"audio/x-realaudio",
        "3gpp":"video/3gpp",
        "3gp":"video/3gpp",
        "mpeg":"video/mpeg",
        "mpg":"video/mpeg",
        "mov":"video/quicktime",
        "flv":"video/x-flv",
        "mng":"video/x-mng",
        "asx":"video/x-ms-asf",
        "asf":"video/x-ms-asf",
        "wmv":"video/x-ms-wmv",
        "avi":"video/x-msvideo",
        "m4v":"video/mp4",
        "mp4":"video/mp4"
    }
    return _formats[formate_name]
}


const CheckDocumentFormate = (formate_name) =>{
    let _formats = {
        "html":"text/html",
        "htm" : "text/html",
        "shtml":"text/html",
        "css":"text/css",
        "xml":"text/xml",
        "gif":"image/gif",
        "jpeg":"image/jpeg",
        "jpg":"image/jpeg",
        "txt":"text/plain",
        "png":"image/png",
        "tif":"image/tiff",
        "tiff":"image/tiff",
        "wbmp":"image/vnd.wap.wbmp",
        "ico":"image/x-icon",
        "jng":"image/x-jng",
        "bmp":"image/x-ms-bmp",
        "svg":"image/svg+xml",
        "webp":"image/webp",
        "jar":"application/java-archive",
        "war":"application/java-archive",
        "ear":"application/java-archive",
        "hqx":"application/mac-binhex40",
        "doc":"application/msword",
        "pdf":"application/pdf",
        "ps":"application/postscript",
        "eps":"application/postscript",
        "ai":"application/postscript",
        "rtf":"application/rtf",
        "xls":"application/vnd.ms-excel",
        "ppt":"application/vnd.ms-powerpoint",
        "zip":"application/zip",
    }
    return _formats[formate_name]
}

const CheckVideo = (formate_name) =>{
    let _formats = {
        "mp3":"audio/mpeg",
        "ogg":"audio/ogg",
        "ra":"audio/x-realaudio",
        "3gpp":"video/3gpp",
        "3gp":"video/3gpp",
        "mpeg":"video/mpeg",
        "mpg":"video/mpeg",
        "mov":"video/quicktime",
        "flv":"video/x-flv",
        "mng":"video/x-mng",
        "asx":"video/x-ms-asf",
        "asf":"video/x-ms-asf",
        "wmv":"video/x-ms-wmv",
        "avi":"video/x-msvideo",
        "m4v":"video/mp4",
        "mp4":"video/mp4",
        "wav":"audio/wav",
        "m4a":"audio/m4a",
        "webm":"video/webm"
    }
    return _formats[formate_name]
}

const CheckFileFormate = (formate_name) =>{    
    let format = ["jpg","jpeg", "pdf", "docx", "doc", "txt", "ods", "odt", "tiff","xls", "xlsx","zip"]    
    if(format.includes(formate_name)){
        return formate_name
    }else{
        return ''
    }
 }
 const CheckUploadFormate = (formate_name) =>{    
    let format = ["xls", "xlsx"]    
    if(format.includes(formate_name)){
        return formate_name
    }else{
        return ''
    }
 }
 const CheckUploadFormate1 = (formate_name) =>{    
    let format = ["exe","dmg"]    

    if(format.includes(formate_name)){
        return ''
    }else{
        return formate_name
    }
 }

 const getExtension = (path) =>{  
    var basename = path.split(/[\\/]/).pop()    
    let pos = basename.lastIndexOf(".");    
    if (basename === "" || pos < 1)             
        return "";   
    return basename.slice(pos + 1);
 }

// function isObject (value) {
//     return value && typeof value === 'object' && value.constructor === Object;
// }


const createRule = (manditory, data) =>{
    let _temp_obj = _.omit(data, ['type', 'columnClass']);
    let _const_obj = (manditory) ? 'required' : ''
    if(!manditory){
        _const_obj = '';
    }
    
    if(_temp_obj){
        for(let _temp_list in _temp_obj){
            switch(_temp_list){
                case "min_length":
                _const_obj += `|min:${_temp_obj[_temp_list]}`
                break

                case "confirmed":
                _const_obj += `|confirmed:${_temp_obj[_temp_list]}`
                break

                case "max_length":
                _const_obj+= `|max:${_temp_obj[_temp_list]}`
                break

                case "pan_number":
                _const_obj+= `|pan_number`
                break

                case "claim_number":
                _const_obj+= `|claim_number`
                break

                case "policy_number":
                _const_obj+= `|policy_number`
                break

                case "ifsc_number":
                _const_obj+= `|ifsc_number`
                break

                case "gst_number":
                _const_obj+= `|gst_number`
                break

                case "validation":
                _const_obj+= `|${_temp_obj[_temp_list]}`
                break

                case "email_validation":
                _const_obj+= `|email`
                break

                case "alpha":
                _const_obj+= `|regex:^([a-zA-Z\\-]+)(\\s+)([a-zA-Z\\-]+)?$'`
                break

                case "onedigit":
                _const_obj+= "|one_digit"  //eslint-disable-line
                break

                case "between":
                _const_obj+= `|between:${_temp_obj[_temp_list]}`
                break

                case "fractions":
                _const_obj+= `|fractions`
                break

                case "start_no_zero":
                _const_obj+= `|start_no_zero`
                break

                case "isDecimal":
                _const_obj+= `|decimal_value`
                break

                
                default :
                break
            }
        }
    }
    return _const_obj
  
}
const ArrayMerge = (original_array, target_array) => {
    let _array_build = {}
    for(let _index_list in original_array){
        if(original_array[_index_list]){
            if(typeof original_array[_index_list] == "object"){
                _array_build[_index_list] = {...target_array[_index_list], ...original_array[_index_list]}
            }
            else if(typeof original_array[_index_list] == "string"){
                _array_build[_index_list] =  original_array[_index_list];
            }      
        }
        else if(target_array[_index_list]){
            _array_build[_index_list] = '';
        }
        else{
            _array_build[_index_list] = '';
        }
    }
    return _array_build
}

const FormateFormFields = (list_main,_type) =>{
    let filed_disabled = ''
    let _temp_details = list_main.map((list, index)=>{
        if(index==0){
            filed_disabled = FieldStatusCheck(_type,list)
        }
        let data = (list.attributes && list.attributes.startsWith("{")) ? JSON.parse(list.attributes) : {
            "label_value":"6",        
            "type": "text",
            "columnClass": "col-lg-6",
        }     
        return{
                label: checkType(list.displayName),
                label_display: true,
                additional_rules : '',
                id:index,          
                disbabled: FieldStatusCheck(_type,list),
                model: !list.field.includes(".") ? stripHtml(list.field) : stripHtml(list.field.replaceAll(".", "__")),
                placeholder: list.displayName,
                type: checkType(data.type),
                columnClass: data.columnClass ? data.columnClass : "col-lg-6",
                rules: (list.isMandatory) ? createRule(list.isMandatory, data) : false,
                fieldCode : list.fieldCode,
                options:[],
                sub_fields: [],
                name: stripHtml(list.displayName),
                disabled_upload : filed_disabled
        }
    })
    return _temp_details;
}

const genereateReportPages = (state, payload) => {    
    if(payload && payload.length){
        let _temp = _.orderBy(payload, ['orderNo'],['asc']);
        
        
        _temp = _temp.map((list) => {
            if (list.isVisible!=false) {
                let _details = list.displayName;
                _details = stripHtml(_details)
                _details = _details.trim();

            let childmenu = []   
            let child_menu = list.sections  
            
            child_menu.map((data)=>{
                if(data.isVisible!=false){
                    let child = {}
                    let _details = stripHtml(data.sectionCode)
                    _details = _details.trim();                    
                    _details = _details.replace(" ", "")
                    if(_details.indexOf('  ') >= 0){
                        _details = _details.replace("  "," ")
                    } 

                    child = {
                        icon : data.icon ? data.icon :"flaticon-verified",
                        text:data.displayName,
                        page_type : (data.authorityCode) ? data.authorityCode : '',
                        field_object : (data.objName) ? data.objName : '',
                        url:_details.replace(/ /g,"_").toLowerCase(),
                        description : data.description,
                        order_no : (data.orderNo) ? data.orderNo : 0,
                        status : data.status ? data.status : "completed",
                        sectionCode : (data.sectionCode) ? data.sectionCode : 0,
                        isChildMenuHide : data.isChildMenuHide ? data.isChildMenuHide : false
                    }
                    childmenu.push(child)
                }
            })
          
            childmenu = _.orderBy(childmenu, ['order_no'],['asc'])     
            return {
                id:list.pageCode,  
                pageCode:list.pageCode,                 
                pagename :list.displayName,    
                page_type : (list.isReadonly) ? list.isReadonly : 'READ',   
                page_url : _details.replace(/ /g,"_").toLowerCase(),
                auth_code : list.authorityCode,
                childmenu : childmenu,
                parReadOnly : list.isReadonly,
                description : list.description,
                pagestatus : list.pagestatus ? list.pagestatus : "partially_filled",  
                isParentMenuHide : list.isParentMenuHide ? list.isParentMenuHide : false,
                evidenceId:list.evidenceId,   
                isCompleted:list.isCompleted,           
            }  
            }
            
        })
        _temp = _temp.filter((list)=>list)
        return _temp
    }    
 }
 const DeterrencePageDetails = (page_name) =>{       
    if(page_name!="" ){        
        return 'GetDeterrenceDetails'
    }
}
const processContactDetails = (fieldData, payload, mainObj) =>{ 
    fieldData.forEach(list => {
        list.field = _.last(list.field.split('.'))
    });
    if(payload){
        let contactDetails = [...payload[mainObj]] 
        let _final_details = contactDetails.map(data=>{
            let _temp_array =[];
            let _address_label = '';
            for(let field in data){
                let _temp_fields = fieldData.find((list)=>list.field==field)
                if(_temp_fields){
                    _temp_array.push({
                        label : _temp_fields.displayName,
                        model : _temp_fields.field,
                        value : data[field]
                    })
                }            
            }
            return {
                label : _address_label,
                values : _temp_array
            }
        })
   return _final_details
    }    
}
const processBenifiDetails = (fieldData, payload) =>{ 
    fieldData.forEach(list => {
        list.field = _.last(list.field.split('.'))
    });
    if(payload){
        let contactDetails = payload
        let _final_details = contactDetails.map(data=>{
            let _temp_array =[];
            let _address_label = '';
            for(let field in data){
                let _temp_fields = fieldData.find((list)=>list.field==field)
                if(_temp_fields){
                    _temp_array.push({
                        label : _temp_fields.displayName,
                        model : _temp_fields.field,
                        value : data[field]
                    })
                }            
            }
            return {
                label : _address_label,
                values : _temp_array
            }
        })
   return _final_details
    }    
}
const genereateCaseFromData= (datas) => {   
    if(datas.length>0){
        let _temp_sub_field =  _.sortBy(datas, function (obj) {
            return parseInt(obj.orderNo);
        });               
        let result = _temp_sub_field.map((list, index)=>{   
            let data = (list.attributes && list.attributes.startsWith("{")) ? JSON.parse(list.attributes) : {
                    "label_value":"6",        
                    "type": "text",
                    "columnClass": "col-lg-6",
            }                       
            return{
                    label: list.displayName,
                    label_display: true,                                
                    id:index,  
                    rules: (list.isMandatory) ? 'required' : '',
                    model: !list.field.includes(".") ? stripHtml(list.field) : stripHtml(list.field.replaceAll(".", "__")),
                    placeholder: list.displayName,
                    type: data.type,
                    columnClass: data.columnClass ? data.columnClass : "col-lg-6",                                
                    fieldCode : list.fieldCode,
                    options:[],    
                    check_display:"true",                             
                    name: stripHtml(list.displayName),
                    isMandatory:list.isMandatory
            }
        }) 
        return result
    }else{
        return []
    }
}

const processTabDetails = (fieldData, payload, page_name) =>{ 
    fieldData.forEach(list => {
        list.field = _.last(list.field.split('.'))
    });
    if(payload){
        let _final_details = payload.map(data=>{
            let _temp_array =[];
            for(let field in data){
                let _temp_fields = fieldData.find((list)=>list.field==field)
                if(_temp_fields){
                    _temp_array.push({
                        label : _temp_fields.displayName,
                        model : _temp_fields.field,
                        value : data[field]
                    })
                }            
            }
            return {
                label : page_name,
                values : _temp_array
            }
        })
   return _final_details
    }    
}

const processTabTableDetails = (fieldData, payload, page_name) =>{ 
    let _playload_main = []
    let _playload_header = []
    let _playload_body = []
    if(payload){
        payload.forEach((list, _main_index)=>{
            
            _playload_main[_main_index] = {}
            let _data_holder = list.coverParamMap
            _playload_main[_main_index]['tab_name'] = (_data_holder.coverName) ? _data_holder.coverName : page_name;
            (_data_holder.entityHeads) ? _data_holder.entityHeads.map((list, index) => {
               
                 if(index==0 && (_playload_header && _playload_header.length==0)){

                   
                    _playload_main[_main_index]['tab_body'] = []
                    _playload_main[_main_index]['tab_head'] = []
                    _playload_main[_main_index]['tab_body'][index] = {}

                    for(let list_details in list){
    
                        if(!_.isArray(list[list_details])){
                            let _updated = fieldData.find((list)=>list.field.includes('maintable') && list.field.includes(list_details))
                            if (_updated) {
                                
                                _playload_main[_main_index]['tab_head'].push({
                                    name: stripHtml(list_details),
                                    sortField: stripHtml(list_details),
                                    title: (_updated.displayName) ? stripHtml(_updated.displayName) : '',
                                    titleClass: "text-left",
                                    dataClass: "text-left",
                                    filterable: true,
                                    togglable: true,
                                    orderNo : (_updated.orderNo) ? _updated.orderNo: 0,
                                })
                                _playload_main[_main_index]['tab_body'][index][list_details] = (list[list_details] != '') ? list[list_details] : ''
                               
                            }
                            if(_updated){
                                let _details_ml = _playload_main[_main_index]['tab_head']
                                if(_details_ml && _details_ml.length){
                                    _details_ml = _.sortBy(_details_ml, function (obj) {
                                        return parseInt(obj.orderNo);
                                    }); 
                                }
                            }

                        }
                        else{
                           
                            _playload_main[_main_index]['tab_body'][index]['sub_rows'] = {};
                            _playload_main[_main_index]['tab_body'][index]['sub_rows']['header'] = [];
                            _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'] = [];

                            list[list_details].forEach((submit, index)=>{
                                let _table_order = [];
                                if(submit){
                                    for(let sub_list_key in submit){
                                        let _updated = fieldData.find((list)=>list.field.includes('childtable') && list.field.includes(sub_list_key))
                                        if(_updated && index==0){
                                            _playload_main[_main_index]['tab_body'][index]['sub_rows']['header'].push({
                                                name: stripHtml(sub_list_key),
                                                sortField: stripHtml(sub_list_key),
                                                title: (_updated.displayName) ? stripHtml(_updated.displayName) : '',
                                                titleClass: "text-left",
                                                dataClass: "text-left",
                                                filterable: true,
                                                togglable: true,
                                                orderNo : (_updated.orderNo) ? _updated.orderNo: 0,
                                            })

                                            let _details_ml =  _playload_main[_main_index]['tab_body'][index]['sub_rows']['header']
                                            if(_details_ml && _details_ml.length){
                                                _details_ml = _.sortBy(_details_ml, function (obj) {
                                                    return parseInt(obj.orderNo);
                                                });  
                                            }
                                            _table_order = [];
                                            _playload_main[_main_index]['tab_body'][index]['sub_rows']['header'] = _details_ml
                                            if(_details_ml && _details_ml.length){
                                               _details_ml.forEach((list)=>{
                                                    _table_order.push(list.name)
                                                })
                                            }
                                        }
                                        if(_updated){
                                            _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'].push({name : submit[sub_list_key], parentId:sub_list_key})
                                            let _table_values =  _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'];
                                            if(_table_values && _table_values.length){
                                                let _sorted = _.sortBy(_table_values, function(item){
                                                    return _table_order.indexOf(item.parentId)
                                                });
                                                _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'] = _sorted
                                            }
                                        }
                                    }
                                }
                            })
                        }
                    }
                   
                 }
                 else {
                        _playload_main[_main_index]['tab_body'][index] = {}
                        let _cnt = 0
                        let _table_order = [];
                        for(let list_details in list){
                            if (!_.isArray(list[list_details])) {
                                let _updated = fieldData.find((list) => list.field.includes('maintable') && list.field.includes(list_details))
                                if (_updated) {
                                    if (_cnt == 1) {
                                        _playload_main[_main_index]['tab_body'][index]['id'] = index
                                    }
                                    _playload_main[_main_index]['tab_body'][index][list_details] = (list[list_details]!='') ? list[list_details] : ''
                                    _cnt+=1
                                }
                            }
                            else {
                                _playload_main[_main_index]['tab_body'][index]['sub_rows'] = {};
                                _playload_main[_main_index]['tab_body'][index]['sub_rows']['header'] = [];
                                _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'] = [];
                                list[list_details].forEach((submit, sub_index)=>{
                                    if(submit){
                                        for(let sub_list_key in submit){
                                            let _updated = fieldData.find((list) => list.field.includes('childtable') && list.field.includes(sub_list_key))
                                            if(_updated && sub_index==0){
                                                _playload_main[_main_index]['tab_body'][index]['sub_rows']['header'].push({
                                                    name: stripHtml(sub_list_key),
                                                    sortField: stripHtml(sub_list_key),
                                                    title: (_updated.displayName) ? stripHtml(_updated.displayName) : '',
                                                    titleClass: "text-left",
                                                    dataClass: "text-left",
                                                    filterable: true,
                                                    togglable: true,
                                                    orderNo : (_updated.orderNo) ? _updated.orderNo: 0,
                                                })

                                                let _details_ml =  _playload_main[_main_index]['tab_body'][index]['sub_rows']['header']
                                                if(_details_ml && _details_ml.length){
                                                    _details_ml = _.sortBy(_details_ml, function (obj) {
                                                        return parseInt(obj.orderNo);
                                                    });  
                                                }

                                                _table_order = [];
                                                _playload_main[_main_index]['tab_body'][index]['sub_rows']['header'] = _details_ml
                                                if(_details_ml && _details_ml.length){
                                                   _details_ml.forEach((list)=>{
                                                        _table_order.push(list.name)
                                                    })
                                                }
                                               
                                            }
                                            if(_updated){
                                                _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'].push({name : submit[sub_list_key], parentId:sub_list_key})
                                                let _table_values =  _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'];
                                                if(_table_values && _table_values.length){
                                                    let _sorted = _.sortBy(_table_values, function(item){
                                                        return _table_order.indexOf(item.parentId)
                                                    });
                                                    _playload_main[_main_index]['tab_body'][index]['sub_rows']['body'] = _sorted
                                                }
                                            }
                                        }
                                      
                                    }
                                }) 
                            }
                          
                           
                        }
                 }
                 return _playload_body
                    
            }) : []
        })
    }
    return _playload_main
}



const ApiAccessDetails = (page_name) =>{
    switch(page_name){
        case 'MH_ROLE_QC1':  
        break;
        default:
    }
}

const MassHealthPageDetails = (page_name) =>{  
    if(page_name=="mh_case_details"){
        return 'GetMhInvestigationCaseDetails'
    }else if(page_name=="mh_end_investigator_recommendation"){
        return 'GetMhEndInvestigator'
    }else if(page_name=="agency_qc_recommendation"){
        return 'GetMhAgencyQCRecom'
    }else if(page_name=="qc_recommendation"){
        return 'GetMhQCRecom'
    }else if(page_name=="document_management"){
        return 'GetMhDocList'
    }
}

const MhViewReportPage = (page_name) =>{  
    if(page_name=="mh_inv_report"){
        return 'GetInvReportDetails'
    }
}

const formatFields = (formData,formFields) => {
    let mainKey = '';
    let nestedObject = {};
    for(let data in formData){
        //formate as object when the model has "__"
        if(data.includes('__')){
            let splitOf = data.split('__')
                                        
            let temp = {};
            if(nestedObject[splitOf[0]]){
                temp = nestedObject[splitOf[0]]
            }
            else{
                nestedObject[splitOf[0]] = {}
            }
            mainKey = data;                                    
            temp[splitOf[1]] = formData[mainKey]
            nestedObject[splitOf[0]] = temp 
        }//end
        //formate datepicker and datetimepicker 
        if(isDate(formData[data]) === true){
            let checkData = formFields.find((list)=>(list.model==data&&list.type=='datepicker')) 
            let dateTimeData = formFields.find((list)=>(list.model==data&&list.type=='datetimepicker')) 
            if(checkData){
                formData[data] = convert_ddmmyy(formData[data])
            }else if(dateTimeData){                                       
                formData[data] = moment(formData[data]).format('MM/DD/YYYY h:mm:ss a')
            } 
        }//end                         
        
    }
    for (let key in formData) {
        if(key && key.includes('__')){
            delete formData[key]
        }
    }
    let mainObj = {
        ...formData,
        ...nestedObject,
    };
    return mainObj;
}

const updateImagePath = (imgpath) => {
    if(imgpath){
        console.log("svg", imgpath)
        return require(`@/assets/icons/${imgpath}`)
    }
}

const downloadMobile = (window, payload, case_id) =>{
    if(window && window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                data: payload,
                docname: case_id,
            })
          )
    }
}

export { 
    updateImagePath,
    formatFields,
    buildQueryParam,
    EncodeString,
    CasePageDetails,
    stripHtml,
    CaseStatusDisplay,
    CaseHistoryDisplay,
    CaseBottomDisplay,
    DeocdeString,
    ReportSubmitPageDetails,
    formatReportFields,
    string2Bin,
    bin2String,
    ContentFormate,
    ArrayMerge,
    CheckVideo,
    CheckDocumentFormate,
    checkType,
    createRule,
    ProcessCaseFields,
    isDate,
    FieldStatusCheck,
    FormateFormFields,
    formatReportFileFields,
    CalculateFromDatas,
    CheckFileFormate,
    genereateReportPages,
    DeterrencePageDetails,
    FromateSingleObject,
    getExtension,
    processContactDetails,
    processBenifiDetails,
    genereateCaseFromData,
    processTabDetails,
    processTabTableDetails,
    CheckUploadFormate,
    ApiAccessDetails,
    TextTransform,
    MassHealthPageDetails,
    MhViewReportPage,
    CheckUploadFormate1,
    isJSON,
    downloadMobile
}