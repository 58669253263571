import _ from 'lodash'

const calculateFields = [
    {
        pageName : 'insured_details',
        fields : ['insuredDetails__fraudCommittedBy']
    },
]


const CalculateFromDatas = (formValue, fields, mainForm, pageName) => {
    let details = calculateFields.find((list) => list.pageName == pageName)
    console.log(formValue)
    if (details && details.fields) {
        details.fields.forEach((list) => {
            switch (list) {
                case 'insuredDetails__fraudCommittedBy':
                    if (mainForm[list]) {
                        let companyType = fields.find((list_sub) => list_sub.model == "insuredDetails__companyType")
                        console.log(mainForm,companyType,"mainForm")
                        if (mainForm[list] == 'Person') {
                            //mainForm['insuredDetails__companyType'] = ""
                            companyType.disbabled = true
                        } else {
                            companyType.disbabled = false
                        }
                    }
                    break
                
            }
        })
    }
    //console.log(formValue, fields, mainForm, pageName,"formValue, fields, mainForm, pageName")
}

const formateSingleObject  = (fields, field_values, _temp_object) =>{     
    for (let data in fields) {
        let _curr_obj = fields[data]
        let _split = fields[data].model.split("__");
        let _check_details= '';
        let a = `${_split[0]}`
        let b =  `${_split[1]}`
        _check_details = _.last(_split);    
        if(_split && _split.length>=2){
            if(field_values[a]){
                field_values[`${a}__${b}`] = field_values[a][b]
            }
        }
        if(field_values[_curr_obj.model]!='' && _check_details!=''){
            _temp_object[_curr_obj.model] = (_check_details  in field_values) ? field_values[_check_details] : ''
        }
        // to handle multi field
        if (Array.isArray(field_values[_curr_obj.model]) && fields[data].type == "multi_text_field_UM" ) {
            let count = field_values[_curr_obj.model].length;
            fields[data]['field_count'] = count
        }
        // to handle check boxes
        /* if (fields[data].type == "qccheckboxgroup") {
            field_values[_curr_obj.model].forEach((field) => {})
        } */
    }
    
}

export {
    CalculateFromDatas,
    formateSingleObject,
}