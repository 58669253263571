// import Vue from "vue";
import {first, omit, orderBy, sortBy, map, find, forEach, size, last, findIndex, replace, isEmpty} from "lodash";
import router from "../router";
import moment from 'moment'
import {convert_datetoiso, timeAgo} from '@/shared/timer.js'
import {stripHtml,downloadMobile, CaseHistoryDisplay, EncodeString, checkType, formatReportFields, formatReportFileFields, DeocdeString, ContentFormate, createRule, ProcessCaseFields, isDate, FieldStatusCheck, FormateFormFields, genereateReportPages, processContactDetails, processBenifiDetails,genereateCaseFromData, processTabDetails, processTabTableDetails, isJSON} from "../shared/custom";
import {Report_Pages_constants} from "../shared/constants";
export default {
    LOGIN_ACCESS(state, payload) {
        state.auth.token = payload.token
        state.auth.profile = payload.profile
    },

    EMPTY_FILTER(state){
        state.all_page_feilds = []
        state.multi_section_page_fields = {
            datas : [],
            page_name : '',
            multi_section: '',
            mult_datas : [],
        };
    },

    GET_LOGIN_DATA(state, payload) {
        let _temp_details = payload.responseList
        if (_temp_details.accessToken) {
            localStorage.setItem('token', _temp_details.accessToken)
            router.push('/')
        }
    },

    GET_USER_PROFILE(state,payload){
        if(payload){
            state.theme = payload.theme ? payload.theme  : false
            let {roles, locationCodeList} = payload
            state.profile = omit(payload, ['roles', 'addressDetails']);
            let _addressDetails = payload.addressDetails;
            if(roles.length){

                roles = orderBy(roles, ['priority'],['asc']);
                roles = first(roles);
                state.auth.role_name = roles.name
                for(let role in roles){
                    state.profile[`role__${role}`] = roles[role]
                }

                let _temp_details = EncodeString(roles)
                window.$cookies.set('roles', _temp_details, '1d');
                
            }
            for(let address_details in _addressDetails){
                state.profile[`addressDetails__${address_details}`] = _addressDetails[address_details]
            }
            let _lcList = [];
            if(locationCodeList && locationCodeList.length>0){
                for (let i = 0; i < locationCodeList.length; i++) {
                    _lcList.push(locationCodeList[i]);
                  }
            }
            state.profile[`lcList`] = _lcList;
        }
    },

    SET_THEME(state, payload) {
        console.log( payload);
    },

    GET_MENU_PAGES(state, payload) {
        if(payload && payload.length > 0 ){
            let ordered =  sortBy(payload, function (obj) {
                return parseInt(obj.orderNo);
            });
            ordered && ordered.forEach(route => {
                route.child = route.subMenuPages ? route.subMenuPages :  []
                route.url  = `${replace(route.name.toLowerCase(),new RegExp(' ','g'),'')}`;
                if(route.attributes){
                    let maindata = (route.attributes && route.attributes.startsWith("{") && isJSON(route.attributes)) ? JSON.parse(route.attributes) : {      
                        "type": "single"
                    } 
                    if(maindata){
                        route.secObjName = maindata.obj_name
                    }
                    else{
                        route.secObjName  = '';
                    }
                    route.attributes = maindata 
                }
                else{
                     route.secObjName  = '';
                }
                let subMenus = route.subMenuPages;
                if (route.subMenuPages && route.subMenuPages.length > 0) {
                    if(subMenus){
                        subMenus && subMenus.forEach(sub_route => {
                            sub_route.pageCode = sub_route.code
                            sub_route.mainId = route.id
                            sub_route.code = route.code
                            sub_route.url  = `${replace(sub_route.name.toLowerCase(),new RegExp(' ','g'),'')}`;
                        })
                    }
                }
                route.child = subMenus
            });
            state.menuPages = ordered
        }
    },

    GET_WIDEGET_PAGES(state, payload) {
        let {current_menu} = state
        if(payload && payload.length > 0 ){
            let ordered =  sortBy(payload, function (obj) {
                return parseInt(obj.orderNo);
            });
            ordered && ordered.forEach(route => {
                route.pageCode = route.code
                route.child = route.subMenuPages ? route.subMenuPages :  []
                route.mainurl = `${current_menu.url}details`;
                route.url  = `${replace(route.name.toLowerCase(),new RegExp(' ','g'),'')}`;
                if(route.attributes){
                    let maindata = (route.attributes && route.attributes.startsWith("{") && isJSON(route.attributes)) ? JSON.parse(route.attributes) : {      
                        "type": "single"
                    }  
                    if(maindata){
                        route.secObjName = maindata.obj_name
                        route.referenceType = maindata.referenceType
                        route.docType = maindata.docType
                        route.displayPageObjName =  (maindata.display_page_object_name) ? maindata.display_page_object_name : []
                        route.hidePageObjName =  (maindata.hide_page_object_name) ? maindata.hide_page_object_name : []
                    }
                    else{
                        route.secObjName  = '';
                        route.referenceType  = '';
                        route.docType  = '';
                        route.displayPageObjName = [];
                        route.hidePageObjName = []
                    }
                    
                }
                else{
                     route.secObjName  = '';
                }
                let subMenus = route.subMenuPages;
                if (route.subMenuPages && route.subMenuPages.length > 0) {
                    if(subMenus){
                        subMenus && subMenus.forEach(sub_route => {
                            sub_route.mainId = route.id
                            sub_route.code = route.code
                            sub_route.pageCode = route.code
                            sub_route.url  = `${replace(sub_route.name.toLowerCase(),new RegExp(' ','g'),'')}`;
                        })
                    }
                }
                route.child = subMenus
            });
            let finalMenu = [];
            let targetHide = ordered.filter((list)=>list.displayPageObjName.includes(current_menu.pageCode))
            if(targetHide && targetHide.length){
                finalMenu = targetHide;
            }
            else{
                finalMenu = ordered.filter((list)=>!list.hidePageObjName.includes(current_menu.pageCode))
            }
            console.log('routerouteroute', finalMenu)
            state.widgetPages = finalMenu
        }
    },

    GET_TABLE_PAGE_DETAILS(state, payload){
        state.table_data = (payload) ? payload.data: []
    },

    UDPATE_ACTIVE_PAGE(state, payload){
        const attrs = JSON.parse(payload.attributes);
        state.active_page = {...attrs, ...payload}
    },


    UDPATE_ACTIVE_MENU(state, payload){
        state.active_menu = payload
    },

    GET_POST_PROFILE(state, payload) {
        state.profile_update = payload
    },

    async GET_MULTI_SECTION_PAGE_FIELDS(state, payload){
         let case_form_data = []
        let all_page_feilds = [];   
        state.validate_count_sections = []
        if(payload && payload.length>0){
            let tempPageFields = first(payload)
            let _data = [];  
            let sub_field_list = [];       
            if(tempPageFields && tempPageFields.formPageFieldGroups && tempPageFields.formPageFieldGroups.length){
                let tempGroupSection = tempPageFields.formPageFieldGroups;
                tempGroupSection =  sortBy(tempGroupSection, function (obj) {
                    return parseInt((obj.orderNo) ? obj.orderNo : 0);
                });     
                tempGroupSection = orderBy(tempGroupSection, ['orderNo'],['asc'])    
                await Promise.all(tempGroupSection.map(async (investigation_page_fields)=>{
                    let maindata = (investigation_page_fields.attributes && investigation_page_fields.attributes.startsWith("{") && isJSON(investigation_page_fields.attributes)) ? JSON.parse(investigation_page_fields.attributes) : {      
                        "type": "single"
                    }  
                    let tempFieldsForm = {
                        header :{},
                        body:{},
                        tabelHeader: [],
                    }
                    let fieldList = [];
                    tempFieldsForm.header = omit(investigation_page_fields, ['fields']);
                    tempFieldsForm.header['type'] = maindata.type
                    tempFieldsForm.header['attributes'] = maindata
                    tempFieldsForm.header['filter_buttons'] = (maindata.filterButtons) ? maindata.filterButtons : [];
                    tempFieldsForm.header['table_buttons'] = (maindata.tableButtons) ? maindata.tableButtons : [];
                    tempFieldsForm.header['table_header'] = (maindata.tableHeaders) ? maindata.tableHeaders : [];
                    tempFieldsForm.header['table_type'] = (maindata.tableType) ? maindata.tableType : [];
                    tempFieldsForm.header['popup_code'] = (maindata.popupCode) ? maindata.popupCode : [];
                    tempFieldsForm.header['template_type'] = (maindata.templateType) ? maindata.templateType : [];
                    tempFieldsForm.header['popup_button'] = (maindata.popupButtons) ? maindata.popupButtons : [];
                    tempFieldsForm.header['validate'] = true
                    tempFieldsForm.header['tableDisplay'] = (maindata.dsiplayTable) ? maindata.tableDisplay : false
                    tempFieldsForm.header['roles'] = []
                    tempFieldsForm.header['validateCount'] = []
                    tempFieldsForm.header['addRightsRole'] = []
                    tempFieldsForm.header['girdEditRightsRoles'] = []
                    tempFieldsForm.header['visibleRightsRole'] = []
                    tempFieldsForm.header['visibleButton'] = []
                    tempFieldsForm.header['custome_button'] = false;
                    tempFieldsForm.header['default_button'] = false;
                    tempFieldsForm.header['custome_button_values'] = [];
                    tempFieldsForm.header['is_workflow'] = false;
                    tempFieldsForm.header['sectionObj'] = ''
                    tempFieldsForm.header['tabelHeader'] = []
                    if(maindata && maindata.roleCode){
                        tempFieldsForm.header['roles'] = maindata.roleCode;
                    }
                    if(maindata && maindata.addRightsRole){
                        tempFieldsForm.header['addRightsRole'] = maindata.addRightsRole;
                    }
                    if(maindata && maindata.visibleRightsRole){
                        tempFieldsForm.header['visibleRightsRole'] = maindata.visibleRightsRole;
                    }
                    if(maindata && maindata.visibleButton){
                        tempFieldsForm.header['visibleButton'] = maindata.visibleButton;
                    }
                    if(maindata && maindata.girdEditRightsRoles){
                        tempFieldsForm.header['girdEditRightsRoles'] = maindata.girdEditRightsRoles;
                    }
                    if(maindata && maindata.obj_name){
                        tempFieldsForm.header['sectionObj'] = maindata.obj_name;
                    }
                    if(maindata && maindata.custome_button){
                        tempFieldsForm.header['custome_button'] = maindata.custome_button;
                    }
                    if(maindata && maindata.custome_button_values){
                        tempFieldsForm.header['custome_buttons'] = maindata.custome_button_values;
                    }
                    if(maindata && maindata.is_workflow==true){
                        tempFieldsForm.header['is_workflow'] = true;
                    }

                    if(maindata && maindata.custome_button_action_id){
                        tempFieldsForm.header['custome_button_action_id'] = maindata.custome_button_action_id;
                    }

                    if(maindata && maindata.validate_count){
                        state.validate_count_sections.push(tempFieldsForm.header.sectionObj)
                    }

                    
                    if(maindata && maindata.default_button){
                        tempFieldsForm.header['default_button'] = maindata.default_button;
                    }
                    tempFieldsForm.header.model = stripHtml(replace(tempFieldsForm.header.displayName.split(' ').join('')))
                    if(investigation_page_fields){
                        let _temp_data = {...investigation_page_fields};
                        if (typeof _temp_data !== 'undefined' && _temp_data.formPageFields && _temp_data.formPageFields.length){
                            state.current_page_details = omit(_temp_data,  ['formPageFieldGroups'])
                            let _temp_sections = _temp_data.formPageFields; 
                            _temp_sections =  sortBy(_temp_sections, function (obj) {
                                return parseInt((obj.orderNo) ? obj.orderNo : 0);
                            }); 
                            if(_temp_sections && _temp_sections.length>=1){
                            
                                _temp_sections.forEach((list, index)=>{
                                   
                                    let data = (list && list.attributes && isJSON(list.attributes)) ? JSON.parse(list.attributes) : {
                                        "label_value":"6",        
                                        "type": "text",
                                        "columnClass": "",
                                    }  
                                    let fieldStatusCheck = FieldStatusCheck(tempGroupSection, list.authorities, {}, list.authorityCode);  
                                    if(data && !data.addField){
                                        data.addField = false;
                                    } 

                                    if(data && !data.filter_display){
                                        data.filter_display = true;
                                    } 
                                    else{
                                        data.filter_display = false;
                                    }

                                    if(data && !data.girdEdit){
                                        data.girdEdit = false;
                                    } 
                                        
                                    if(tempFieldsForm.header.type=='multiple'){
                                        if(!data.columnClass){
                                            data.columnClass = "col-lg-3";
                                        }
                                    }
                                    else if(tempFieldsForm.header.type=='table' || tempFieldsForm.header.type=='tableChecked' || tempFieldsForm.header.type=='addTable' || tempFieldsForm.header.type=='table_display'){
                                        // tempFieldsForm.header['validate'] = false;
                                        if(tempFieldsForm.header.type=='addTable'){
                                            tempFieldsForm.header.tabelHeader.push({
                                                key:'slno',
                                                sortable: false,
                                                name: "SNO",    
                                                sortField: "SNO",
                                                label: 'S No'
                                            },
                                            {
                                                key:'selected',
                                                sortable: false,
                                                label: ''
                                            })
                                        }
                                        else if(tempFieldsForm.header.type=='tableChecked'){
                                           tempFieldsForm.header.tabelHeader.push({
                                                key:'slno',
                                                sortable: false,
                                                name: "SNO",    
                                                sortField: "SNO",
                                                label: 'S No'
                                            },
                                            {
                                                key:'selected',
                                                sortable: false,
                                                label: ''
                                            })
                                        }

                                        if(checkType(data.type)!='hidden'){
                                            tempFieldsForm.header.tabelHeader.push({
                                                key:(list.fields && !list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(last(list.fields.split("."))),
                                                sortable: true,
                                                name : !(list && list.fields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                                sortField: !(list && list.fields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                                title : list.displayName,
                                                grid_edit : data.girdEdit,
                                                disbabled: fieldStatusCheck,
                                                initDisbabled: fieldStatusCheck,
                                                api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                                editableTable: maindata.girdEditRightsRoles,
                                                fieldCode:  list.fieldCode,
                                                rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                                initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                                label: list.displayName,
                                                model: !(list && list.fields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                                options : [],
                                                custom_validation: data,
                                                type:checkType(data.type),
                                                ...list
                                            })
                                        }
                                    }
                                    else if(data.columnClass==''){
                                        data.columnClass =  "col-lg-3";
                                    }
                                    
                                    
                                    let fieldData = {
                                        sub_index : (sub_field_list.length>=1 && (sub_field_list.type=='fileupload'  || sub_field_list.type=='fileupload_full' || sub_field_list.type=='textarea_din' || sub_field_list.type=='select_file_upload')) ? 0 : 'false',
                                        sub_label : list.displayName,
                                        label: checkType(list.displayName),
                                        filter_display : data.filter_display,
                                        label_display: (data.label_display==false) ? false : true,
                                        label_value: (data && data.label_value) ? data.label_value : '12',
                                        additional_rules : '',
                                        id:index,          
                                        grid_edit : data.GirdEdit,
                                        productCategory : (list.productCategory) ? list.productCategory : null,
                                        disbabled: fieldStatusCheck,
                                        initDisbabled: fieldStatusCheck,
                                        model: !(list && list.fields && list.fields.includes(".")) ? stripHtml(list.fields) : stripHtml(list.fields.replaceAll(".", "__")),
                                        check_validations : ((data && data.check_validations) ? data.check_validations : false),
                                        check_field_validations : ((data && data.check_field_validations) ? data.check_field_validations : false),
                                        placeholder: (data && data.placeholder) ? data.placeholder : list.displayName,
                                        // type: (stripHtml(list.displayName)!='Status') ? checkType(data.type) : 'toggle',    //(list.field == 'eyewitnessStatement') ? 'multi_text_field' :
                                        type: checkType(data.type),
                                        decimalLength : (data && data.decimalLength) ? data.decimalLength : '',
                                        columnClass: (data && data.columnClass) ? data.columnClass : "col-lg-4 col-md-6",
                                        rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                        initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                        sortInput: (data && data.ascorder && data.ascorder!=='false') ? true : false,
                                        fieldCode : list.fieldCode,
                                        additional_attributes: list.additionalAttributes,
                                        options:[],
                                        rows : 0.4,
                                        max_rows : 8,
                                        section_obj : maindata.obj_name,
                                        loader : ((data && data.type && (data.type.includes('select') || data.type.includes('_check'))) && !data.type.includes('dependent') && data.inter_dependent!='true') ? true : false,
                                        check_display :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                        initCheckDisplay :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                        custom_validation: data,
                                        api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                        sub_fields: sub_field_list,
                                        name: stripHtml(list.displayName),
                                        min_date:data.isFutureDate ? data.isFutureDate=='true' ? moment(Date.now()).toISOString():'' :'' ,
                                        max_date: data.isFutureDate ? data.isFutureDate=='false' ? moment(Date.now()).toISOString():'' :'' ,
                                        field_count : ( data && data.type && data.type =="multi_text_field" || data.type=="multi_datetimepicker" || data.type=="multi_datepicker"  ) ? 1 : null,
                                        isMandatory:list.isMandatory,
                                        isFileRemove : (data && data.isFileRemove) ? data.isFileRemove : "true",
                                        addTable  : data.addField
                                    }
                                    fieldList.push(fieldData);
                                    all_page_feilds.push(fieldData);


                                })
                                if(tempFieldsForm.header.type=='multiple'){
                                    let checkFields = [...fieldList]
                                    tempFieldsForm.body = [];
                                    tempFieldsForm.body.push(checkFields)
                                    let baseForm = [];
                                    if(tempFieldsForm.body && tempFieldsForm.body.length){
                                        tempFieldsForm.body.forEach((form_list)=>{
                                            form_list.forEach((list, index)=>{
                                                baseForm[index] = {};
                                                baseForm[index][list.model] = [];
                                                baseForm[index][list.model][0] = null;
                                            })
                                        })
                                        state.multi_section_pannel_form_data = baseForm
                                    }

                                }
                                else if(tempFieldsForm.header.type=='addTable'){
                                    tempFieldsForm.body = []
                                    tempFieldsForm.body[0] = []
                                    tempFieldsForm.body[0]=fieldList.filter((list)=>list.addTable==true)
                                    let baseForm = [];
                                    if(tempFieldsForm.body && tempFieldsForm.body.length){
                                        tempFieldsForm.body.forEach((form_list)=>{
                                            form_list.forEach((list, index)=>{
                                                baseForm[index] = {};
                                                baseForm[index][list.model] = [];
                                                if(list && list.custom_validation && list.custom_validation.default_value){
                                                    baseForm[index][list.model][0] = list.custom_validation.default_value
                                                }
                                                else if(list.model=='date'){
                                                    baseForm[index][list.model][0]= moment(new Date()).toISOString();
                                                }
                                                else if(list.model=='userName'){
                                                    baseForm[index][list.model][0]= state.root_state.profile.userId
                                                }
                                                else if(list.model=='verticalCodeFrom'){
                                                    baseForm[index][list.model][0]=  (state.root_state && state.root_state.profile.verticalDetails && state.root_state.profile.verticalDetails.code) ? state.root_state.profile.verticalDetails.code : '';
                                                }
                                                else{
                                                    baseForm[index][list.model][0] = (list.model=='requestedBy') ? state.root_state.profile.userId : null;
                                                }
                                                
                                            })
                                        })
                                        state.table_add_form_data[tempFieldsForm.header.sectionObj] = baseForm
                                        state.table_add_form_data_init[tempFieldsForm.header.sectionObj] = JSON.parse(JSON.stringify(baseForm));
                                    }
                                }
                                else{
                                    tempFieldsForm.body = fieldList
                                }
                            }
                            _data.push(tempFieldsForm);
                        }
                    }
                }))

                state.multi_section_page_fields = {
                    datas : _data,
                    page_name : tempPageFields.description,
                    subSectionCode : tempPageFields.subSectionCode,
                    type:'multi_section_form',
                }
                state.all_page_feilds = all_page_feilds
            }
        }
        else{
            state.all_page_feilds = []
            state.current_page_details = {}
            state.multi_section_page_fields = {
                datas : [],
                page_name : '',
                multi_section: '',
                mult_datas : [],
                case_form_data
            };
        }
    },
    async GET_POPUP_FIELD(state, payload){
        let case_form_data = []
        if(payload && payload.length>0){
            let tempPageFields = first(payload)
            let _data = [];  
            let sub_field_list = [];      
            let all_page_feilds = [];   
            if(tempPageFields && tempPageFields.fieldGroups && tempPageFields.fieldGroups.length){
                let tempGroupSection = tempPageFields.fieldGroups;
                tempGroupSection =  sortBy(tempGroupSection, function (obj) {
                    return parseInt((obj.orderNo) ? obj.orderNo : 0);
                });     
                tempGroupSection = orderBy(tempGroupSection, ['orderNo'],['asc'])    
                
                await Promise.all(tempGroupSection.map(async (investigation_page_fields)=>{
                    let maindata = (investigation_page_fields.attributes && investigation_page_fields.attributes.startsWith("{") && isJSON(investigation_page_fields.attributes)) ? JSON.parse(investigation_page_fields.attributes) : {      
                        "type": "single"
                    }  
                    let tempFieldsForm = {
                        header :{},
                        body:{},
                        tabelHeader: [],
                    }
                    let fieldList = [];
                    tempFieldsForm.header = omit(investigation_page_fields, ['fields']);
                    tempFieldsForm.header['type'] = maindata.type
                    tempFieldsForm.header['validate'] = true
                    tempFieldsForm.header['tabelHeader'] = []
                    tempFieldsForm.header.model = stripHtml(replace(tempFieldsForm.header.displayName.split(' ').join('')))
                    if(investigation_page_fields){
                        let _temp_data = {...investigation_page_fields};
                        if (typeof _temp_data !== 'undefined' && _temp_data.fields && _temp_data.fields.length){
                            let _temp_sections = _temp_data.fields; 
                            _temp_sections =  sortBy(_temp_sections, function (obj) {
                                return parseInt((obj.orderNo) ? obj.orderNo : 0);
                            }); 
                            if(_temp_sections && _temp_sections.length>=1){
                                _temp_sections.forEach((list, index)=>{
                                   
                                    let data = (list && list.attributes && isJSON(list.attributes)) ? JSON.parse(list.attributes) : {
                                        "label_value":"6",        
                                        "type": "text",
                                        "columnClass": "",
                                    }   
                                    if(tempFieldsForm.header.type=='multiple'){
                                        if(!data.columnClass){
                                            data.columnClass = "col-lg-2";
                                        }
                                    }
                                    else if(tempFieldsForm.header.type=='table' || tempFieldsForm.header.type=='popupTable' || tempFieldsForm.header.type=='addTable' || tempFieldsForm.header.type=='table_display'){
                                        tempFieldsForm.header['validate'] = false;
                                        tempFieldsForm.header.tabelHeader.push({
                                            key:!list.field.includes(".") ? stripHtml(list.field) : stripHtml(last(list.field.split("."))),
                                            sortable: true,
                                            label: list.displayName
                                        })
                                    }
                                    else if(!data.columnClass){
                                        data.columnClass =  "col-lg-3";
                                    }
                                    
                                    if(list.isVisible){
                                        let fieldData = {
                                            sub_index : (sub_field_list.length>=1 && (sub_field_list.type=='fileupload'  || sub_field_list.type=='fileupload_full' || sub_field_list.type=='textarea_din' || sub_field_list.type=='select_file_upload')) ? 0 : 'false',
                                            sub_label : list.displayName,
                                            label: checkType(list.displayName),
                                            label_display: true,
                                            additional_rules : '',
                                            id:index,          
                                            disbabled: (list.isReadOrWrite==true) ? false : true,
                                            model: !list.field.includes(".") ? stripHtml(list.field) : stripHtml(list.field.replaceAll(".", "__")),
                                            check_validations : ((data && data.check_validations) ? data.check_validations : false),
                                            placeholder: (data && data.placeholder) ? data.placeholder : list.displayName,
                                            type: checkType(data.type),    //(list.field == 'eyewitnessStatement') ? 'multi_text_field' :
                                            decimalLength : (data && data.decimalLength) ? data.decimalLength : '',
                                            columnClass: (data && data.columnClass) ? data.columnClass : "col-lg-4 col-md-6",
                                            rules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            initRules: (data && data.isRequired) ? createRule(data.isRequired, data) : createRule(false, data),
                                            fieldCode : list.fieldCode,
                                            options:[],
                                            rows : 0.4,
                                            max_rows : 8,
                                            loader : ((data && data.type && (data.type.includes('select') || data.type.includes('_check'))) && !data.type.includes('dependent') && data.inter_dependent!='true') ? true : false,
                                            check_display :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                            custom_validation: data,
                                            api_call : (data && data.value_dependent && data.value_dependent=="true") ? true : false,
                                            sub_fields: sub_field_list,
                                            name: stripHtml(list.displayName),
                                            min_date:data.isFutureDate ? data.isFutureDate=='true' ? moment(Date.now()).toISOString():'' :'' ,
                                            max_date: data.isFutureDate ? data.isFutureDate=='false' ? moment(Date.now()).toISOString():'' :'' ,
                                            field_count : ( data && data.type && data.type =="multi_text_field" || data.type=="multi_datetimepicker" || data.type=="multi_datepicker"  ) ? 1 : null,
                                            isMandatory:list.isMandatory,
                                            isFileRemove : (data && data.isFileRemove) ? data.isFileRemove : "true",
                                        }
                                        all_page_feilds.push(fieldData)
                                        fieldList.push(fieldData);
                                    }

                                })
                                if(tempFieldsForm.header.type=='multiple'){
                                    let checkFields = [...fieldList]
                                    tempFieldsForm.body = [];
                                    tempFieldsForm.body.push(checkFields)
                                    let baseForm = [];
                                    if(tempFieldsForm.body && tempFieldsForm.body.length){
                                        tempFieldsForm.body.forEach((form_list)=>{
                                            form_list.forEach((list, index)=>{
                                                baseForm[index] = {};
                                                baseForm[index][list.model] = [];
                                                baseForm[index][list.model][0] = null;
                                            })
                                        })
                                        state.multi_section_popup_form_data = baseForm
                                    }

                                }
                                else{
                                    tempFieldsForm.body = fieldList
                                }
                            }
                            _data.push(tempFieldsForm);
                        }
                    }
                }))
                state.multi_section_page_fields = {
                    datas : _data,
                    page_name : tempPageFields.description,
                    subSectionCode : tempPageFields.subSectionCode,
                    type:'multi_section_form',
                }
                state.all_page_feilds = all_page_feilds
            }
           
        }
        else{
            state.all_page_feilds = []
            state.multi_section_page_fields = {
                datas : [],
                page_name : '',
                multi_section: '',
                mult_datas : [],
                case_form_data
            };
        }
    },

    UPDATE_PANEL_DETAILS(state, payload){
        let {datas} = state.multi_section_page_fields;
        if(datas && datas.length >= payload.form_index){
            let registrationDetails = state.multi_section_pannel_form_data
            if(payload.type==true){
                registrationDetails = state.multi_section_popup_form_data
            }
            if(payload.action=="deduction"){
                registrationDetails.forEach((list)=>{
                    for(let details in list){
                        list[details] = list[details].filter((list, index)=>index != payload.main_index)
                    }
                })
            }
            else if(payload.action=="add"){
                registrationDetails.forEach((list)=>{
                    for(let details in list){
                        list[details].push(null)
                    }
                })
            }
        }
    },

    SAVE_RESULTS(state, payload){
       if(payload.wbQualityReportMasterDto){
            let tempFeild = {}
            let getDatas = {...state.multi_section_form_data}
            payload.wbQualityReportMasterDto.forEach((list)=>{
                tempFeild[list.code] = list.score
            })
            state.multi_section_form_data = {...getDatas, ...tempFeild}
            state.multi_section_form_data_init = {...getDatas, ...tempFeild}
       }
       state.saved_details = payload
    },

    SAVE_REMARKS(state, payload){
        state.saved_remarks = payload
    },

    GET_CLAIM_TYPES(state, payload){        
        if(payload.length){
            let claimType = []    
            state.claims = orderBy(payload, ['description'],['asc'])            
            let claim
            
            if(window.sessionStorage.getItem('claimType')){
                claim = DeocdeString(window.sessionStorage.getItem('claimType'))
            }else{
                claim = ''
            }            
            
            let _claims = find(payload, (list)=>list.code==(claim ? claim : "CASHLESS"))
            claimType.push(EncodeString(_claims.code))
            //window.$cookies.set('claimType', claimType, '1d') 
            //window.$cookies.set('claimTypeId', EncodeString(_claims.id), '1d')
            window.sessionStorage.setItem('claimTypeId', EncodeString(_claims.id)) 
            window.sessionStorage.setItem('claimType', claimType)  
            state.ilm_pro_type = _claims
        }
    },

    GET_USER_PERMISSON(state,payload){
        state.permissions = payload
    },

    GET_TAT_BAND(state,payload){
        if(payload.length){
            let _temp_datas = payload.map((list)=>{
                return {
                    value : `${list.min}-${list.max}`,
                    min : parseInt(list.min),
                    text : `${list.min} - ${list.max}`,
                }
            })
            _temp_datas = orderBy(_temp_datas, ['min'],['asc']);
            state.tat_bands = _temp_datas;
        }
    },

    GET_INVESTIGATION_STATUS(state,payload){
        if(payload && payload.length){
            let _temp_datas = payload.map((list)=>{
                return {
                    value : list.id,
                    text : `${list.name}`,
                }
            })
            state.investigation_status = _temp_datas;
        }
    },

    GET_REPORT_TYPE(state,payload){
        if(payload.length){
            let _temp_datas = payload.map((list)=>{
                return {
                    value : `${list.name}`,
                    text : `${list.name}`,
                    code : list.code
                }
            })
            state.report_type = _temp_datas;
        }
    },
    UPDATE_APP(state, payload){
        state.app_type = payload
    },

    GET_NOTIFICATION_COUNT(state, payload){
        if(payload.responseData){
            state.recent_notification = payload.responseData     
        }
    },

    GET_ALL_NOTIFICATION_COUNT(state, payload){
        state.notification_count = payload.count
        state.all_notification = payload.listdto   
    },

    

    GET_RECENT_NOTIFICATION(state, payload){
        state.recent_notification = payload        
    },

    GET_ALL_NOTIFICATION(state, payload){
        state.all_notification = payload        
    },

    SAVE_NOTIFICATION(){
        // state, payload
    },

    WORK_QUEUE(state,payload){
        state.dashbaord_work_queue = payload
    },


    GET_USERNAME_DATA(state, payload) {
        let _temp_value = map(payload, (list) => {
            return {
                text: list.title,
                value: list.id,
            }
        })
        state.userNameArray = _temp_value
    },

    GET_MENU_DATA(state, payload){
        let _groups =[]
        forEach(payload.responseList, (list_details)=>{
          
            _groups.push({
                MM_MENU_NAME: list_details.MM_MENU_NAME,
                MM_MENU_LEVEL: list_details.MM_MENU_LEVEL,
                MM_MENU_PARENT: list_details.MM_MENU_PARENT,
                sumView: list_details.sumView,
                MM_CUSTOM_NAME: (list_details.MM_CUSTOM_NAME) ? list_details.MM_CUSTOM_NAME : (list_details.MM_MENU_NAME.toLowerCase()).split(" ").join("_")
            });
        })

        let _result = Object.entries(_groups).map(([k, v]) => ({ [k]: v }));
        let _ss = [];
        for (let i = 0; i < _result.length; i++) {
            let j = Object.keys(_result[i]);
            _ss.push(_result[i][j]);
        }

        state.side_menu = _ss
        state.loader = false
    },

    SHOW_LOADER(state) {
        state.loader = true
    },

    GET_VENDOR_DATA(state,payload){
        let _temp_details = (payload && payload.responseList && payload.responseList.data) ? payload.responseList.data.map((list)=>{
            return {
                id : list.IC_INDEX,
                text : list.IC_COY_NAME,
            }
        }) : []
       
        state.vendor_list = _temp_details
    },

    CASE_DETAILS(state,payload){
        if(payload.data){
            state.case_details.data = payload.data;
            state.case_details.loader = false
        }
        else{
            state.case_details.data = [];
            state.case_details.loader = false
        }
    },

    GET_CASE_DETAILS_PAGE(state,payload){
        if(payload.length){
            let _temp = orderBy(payload, ['orderNo'],['asc']);
            _temp = _temp.map((list)=>{
                let _details = list.displayName;
                _details = stripHtml(_details)
                _details = _details.trim();
                return {
                    id:parseInt(list.pageCode),
                    orderNo:parseInt(list.orderNo),
                    icon : "flaticon-right-chevron-1 pr-1",
                    text :list.displayName,       
                    url : _details.replace(/ /g,"_").toLowerCase(),
                    auth_code : list.authorityCode,
                }
            })
            _temp = orderBy(_temp, ['orderNo'],['asc']);
            state.case_details_page = _temp;
        }
        else{
            state.case_details.data = [];
        }
    },

    REPORT_SUBMIT_MENU(state, payload){
        if(payload.length>0){
            state.report_sidebar = payload;
        }
        else{
            state.report_sidebar = [];
        }
    },

    GET_POST_REPORT_SUBMIT(state,payload){
        state.report_submit_datas = payload
    },
    
    GET_STATE_CITY(state,payload){
        state.getStateAndCity = payload && payload.pincodeDetail ? payload.pincodeDetail :{}
    },


  async  REPORT_SUBMIT_DETAILS(state,payload){
        let {report_sidebar} = state
        if(payload){
           let page_name
           let _page_details = {};
           let _type = '';
           let result = [];
           let  _temp_data = first(payload)
           page_name = (_temp_data) ? _temp_data.displayName : '';
           for (var _report_sidebar in report_sidebar) {
               let _child_menu = report_sidebar[_report_sidebar].childmenu
               _page_details = report_sidebar[_report_sidebar]
               _child_menu = _child_menu.find((list)=>list.text==page_name)
               if(_child_menu){
                  _type = _child_menu;
                  break;
               }
           }
          
             if (typeof _temp_data !== 'undefined' && _temp_data.fieldGroups && _temp_data.fieldGroups.length){
                let _temp_fieldGroups =  _temp_data.fieldGroups; 
                    await Promise.all(_temp_fieldGroups.map(async (fieldGroups)=>{
                    
                    let _sub_fields =  ((fieldGroups.fields && Array.isArray(fieldGroups.fields)) ? FormateFormFields(fieldGroups.fields,_type) : [])
                    if(_sub_fields && _sub_fields.length)
                    _sub_fields =  sortBy(_sub_fields, function (obj) {
                        return parseInt(obj.orderNo);
                    });   
                    if(fieldGroups && fieldGroups.fields.length){
                        let fields  = orderBy(fieldGroups.fields, ['orderNo'],['asc']); 
                            fields.forEach((list, sub_index)=>{  
                                let data = (list.attributes && list.attributes.startsWith("{")) ? JSON.parse(list.attributes) : {
                                    "label_value":"6",        
                                    "type": "text",
                                    "columnClass": "col-lg-6",
                                    "inter_dependent":""
                                }
                                                                                                                                 
                                result.push({
                                        sub_index : (_temp_fieldGroups.length>=1 && (data.type=='fileupload'  || data.type=='fileupload_full' || data.type=='textarea_din' || data.type=='select_file_upload')) ? 0 : 'false',
                                        sub_label : fieldGroups.displayName,
                                        label: checkType(list.displayName),
                                        label_display: true,
                                        additional_rules : '',
                                        id:sub_index,          
                                        disbabled: FieldStatusCheck(_type, list, _page_details),
                                        model: !list.field.includes(".") ? stripHtml(list.field) : stripHtml(list.field.replaceAll(".", "__")),
                                        placeholder: data.placeholder ? data.placeholder : list.displayName,
                                        type: checkType(data.type),    //(list.field == 'eyewitnessStatement') ? 'multi_text_field' :
                                        decimalLength : data.decimalLength ? data.decimalLength : '',
                                        columnClass: data.columnClass ? data.columnClass : "col-lg-6",
                                        rules: (list.isMandatory) ? createRule(list.isMandatory, data) : createRule(false, data),
                                        init_rules: (list.isMandatory) ? createRule(list.isMandatory, data) : createRule(false, data),
                                        fieldCode : list.fieldCode,
                                        options:[],
                                        rows : 0.4,
                                        max_rows : 8,
                                        check_display :  ((data.inter_dependent) ? (data.inter_dependent=="true") ? "false" : "true" : "true"),
                                        custom_validation: data,
                                        sub_fields: _sub_fields,
                                        name: stripHtml(list.displayName),
                                        min_date:data.isFutureDate ? data.isFutureDate=='true' ? moment(Date.now()).toISOString():'' :'' ,
                                        max_date: data.isFutureDate ? data.isFutureDate=='false' ? moment(Date.now()).toISOString():'' :'' ,
                                        field_count : ( data.type =="multi_text_field" || data.type=="multi_datetimepicker" || data.type=="multi_datepicker"  ) ? 1 : null,
                                        isMandatory:list.isMandatory,
                                        isFileRemove : (data.isFileRemove) ? data.isFileRemove : "true",
                                        //is24hrs :(data.is24hrs) ? (data.is24hrs=="true") ? true : false : false,
                                })
                            })     
                                                  
                        return result
                        
                        }
                    })
                    
                )
                state.report_submit_details = {
                    fields : result,
                    page_name : page_name,
                    field_values : [],                 
                }  
             }  
             else{
                state.report_submit_details = {
                    fields : [],
                    page_name : page_name,
                    field_values : [],                 
                }  
            }
            state.report_submit_details.loader = false
        }
        else{
            state.report_submit_details = [];
            state.report_submit_details.loader = false
        }
    },


    GET_REPORT_PATIENT_DETAILS(state, payload){      
       let {report_page_request} = state
       let {filed_object, description, page_code, sectionCode, evidenceId} = report_page_request       
       let {fields, page_name} = state.report_submit_details         
       let _temp_object = {} 
       let _temp_page_name = ""
       let _last_obj = '';
       let _list_type = '';
       let _parent_obj = '';
       let _main_obj = {}
       let _main_id = '';
       let field_values = {}
       if(description==Report_Pages_constants.trigger_questionnaire && payload){           
            let {triggerQuestionnaire=[]} = payload
            let mainPage = state.report_sidebar.find((list)=>list.page_url==page_code)  
            let fieldDisabled = false   
            if(mainPage&&mainPage.parReadOnly=="READ"){
                fieldDisabled = true
            }     
            state.report_submit_details = {};      
            if(triggerQuestionnaire && triggerQuestionnaire.length>0){      
                let header = [{label:"S.No.", key:"id"}, {label:"Trigger List", key:"triggerDescription"}, {label:"Effective or not effective", key:"status"}, {label:"Investigator Remarks", key:"invRemarks"},{label:"QC1 Remarks", key:"qcRemarks"},{label:"QC2 Remarks", key:"qc2Remarks"},{label:"QC3 Remarks", key:"qc3Remarks"}]                
                let body = triggerQuestionnaire
                body.forEach(element => {
                    element.evidenceId = evidenceId
                });
                state.report_submit_details = {               
                    type: description,                
                    page_name : page_name,
                    header,   
                    body,
                    sendData : payload,
                    fieldDisabled         
                }
            }
        }
        else if(description=="evidence_grid"){ 
            let subPage = {};
            let fieldDisabled = false
            state.report_sidebar.forEach((list)=>{
                if(list.childmenu && list.childmenu.length){
                    list.childmenu.forEach((list)=>{
                        if(list.sectionCode==sectionCode){
                            subPage = list
                        }
                    }) 
                }
            })  

            if(size(subPage)>0){ 
                fieldDisabled = subPage.page_type=="READ_WRITE" ? false :true
            } 
            
            let _tables = [{
                main_table : [{
                        title : '',
                        class:'table_dats_holder',
                        header : [{
                            key: 'groundRejectionName',
                            label: 'Ground of Repudiation',
                            sortable: false
                        },{
                            key: 'fraudType',
                            label: 'Type of fraud',
                            sortable: false
                        }],
                        body:[]
                }],
                sub_table : [{
                        title : '',
                        class:'table_dats_holder',
                        header : [{
                            key: 'name',
                            label: '',
                            sortable: false
                        },
                        {
                            key: 'groundRejectionApplicable',
                            label: '',
                            sortable: false
                        },{
                            key: 'groundRejectionValue',
                            label: '',
                            sortable: false
                        }],
                        body:[]
                }],
                evidence_table : [{
                        title : 'Evidence Calculator',
                        class:' evidence-calculator-table',
                        header : [{
                            key: 'calculation_parameters',
                            label: 'Calculation Parameters',
                            sortable: false,
                            class: 'text-left' 
                        },
                        {
                            key: 'count',
                            label: 'Count',
                            sortable: false,
                            class: 'text-center' 
                        }],
                        body:[]
                }]

            }];

            payload.forEach((list,index)=>{
                list.evidenceId = evidenceId
                if(index === 0){
                    _tables[0].sub_table[0].title = "Evidence Grid - " +list.groundRejectionName
                }
                _tables[0].main_table[0].body.push({
                    groundRejectionName : list.groundRejectionName,
                    fraudType : list.fraudType,
                    groundRejectionCode : list.groundRejectionCode,
                    groundRejectionApplicable : list.groundRejectionApplicable,
                })
                list.evidences.forEach((list_sub)=>{
                    _tables[0].sub_table[0].body.push({
                        evidenceId : list_sub.evidenceId,
                        groundRejectionName : list.groundRejectionName,
                        groundRejectionCode: list.groundRejectionCode,
                        fraudType : list.fraudType,
                        isDisabled : fieldDisabled,
                        isWa : list_sub.isWa,
                        groundQCValue : list_sub.groundQCValue,
                        groundRejectionApplicable : list_sub.groundRejectionApplicable,
                        id : list_sub.id,
                        groundRejectionValue : [],
                        name : list_sub.name,
                    })
                })
            })


           
          
            state.report_submit_details = {
                fields : '',
                type: 'evidence_grid',
                parent_object : '',
                main_id :'',
                child_object : '',
                sub_page : '',
                page_name : "Evidence Grid",
                table_datas: _tables,
                current_data:payload,
                display_button : fieldDisabled
            }   
        }
        else if(description=="table" && payload){ 
            //vendor grading
            let fieldDisabled = false
            if(state.report_sidebar.length>0){
            let mainPage = state.report_sidebar.find((list)=>list.pageCode==page_code) || []          
            let subPage =  mainPage.childmenu.find((list)=>list.sectionCode==sectionCode) || []            
            if(mainPage.parReadOnly=="READ"){
                fieldDisabled = true
            }else{
                if(size(subPage)>0){ 
                    fieldDisabled = subPage.page_type=="READ_WRITE" ? false :true
                 }
            }
             
            }
            if(size(payload)>0){ 
                let {gradeQuestion} = payload
                let header = []     
                let body = [] 
                forEach(gradeQuestion,(data, index)=>{ 
                    delete data.id
                    if(index==0){  
                        header = Object.keys(data)                         
                    } 
                    data.isDisabled = fieldDisabled
                    body.push(data)
                })      
                state.report_submit_details = {};
                state.report_submit_details = {                
                    type: 'table',
                    page_name : page_name,
                    header,
                    body,
                    received_fields : payload,
                    fieldDisabled,
                    evidenceId
                }   
            }else{
                state.report_submit_details = {};
            }
        }      
        else if(fields && fields.length && description!="table"){    
            let _temp_main = ''
            if(filed_object.includes('.')){
                _parent_obj = first(filed_object.split('.'))
            }
            else{
                _parent_obj = filed_object
            }
            _temp_main = payload[_parent_obj]
            field_values = Object.assign({},first(_temp_main))
           
            _main_id = (field_values && field_values.id) ? field_values.id :''
            if(filed_object.includes('.')){
                let _splits =  filed_object.split('.')
                if(_splits.length){
                    let _check_obj = payload
                 
                    for(let _split in _splits){
                        _last_obj = _splits[_split]
                        _check_obj = _check_obj[_splits[_split]]
                        if(_check_obj && _check_obj.length>0 && _split==0){
                            _parent_obj = _splits[_split]
                            _check_obj = first(_check_obj)
                         
                            _main_id = (_check_obj && _check_obj.id) ? _check_obj.id :''
                            _main_obj = _check_obj
                        }
                    }
                    if(typeof _check_obj == 'string'){
                        field_values = _check_obj
                    }
                    else{
                        field_values = Object.assign([],_check_obj)
                    }
                }
            }
            else{
                
                _main_obj = _temp_main;
            }
           
            let _temp_obj = {}
            let _temp_loop = {}
            if(description=="Questionnaire"){
                _list_type = "array"
                _temp_loop = field_values
                _temp_page_name = _last_obj
                for(let field_value in _temp_loop){
                    if(isDate(_temp_loop[field_value].value)){                        
                        let str = _temp_loop[field_value].value
                        let darr = str.split("/");
                        let isDataTime =   last(darr).includes('AM')|| last(darr).includes('am')||last(darr).includes('PM')||last(darr).includes('pm')                      
                        let _dobj=''
                        if(isDataTime){
                            _dobj = moment(str).toISOString()                            
                        }else{
                            _dobj = moment.utc(str,'DD-MM-YYYY').local().toISOString();                          
                        }
                        
                        _temp_obj[_temp_loop[field_value].fieldCode] =  _dobj
                    }
                    else{
                        _temp_obj[_temp_loop[field_value].fieldCode] = _temp_loop[field_value].value
                    }
                }
                state.report_submit = _temp_obj
            }
            else if(description=="fileUpload"){
             
                formatReportFileFields(fields, field_values, _temp_object)  
                _temp_loop = field_values
                _temp_page_name = _last_obj
                for(let _field in fields){
                    fields[_field].model = fields[_field].model.replace('uploadupload','upload')
                    let _temp_field = _temp_loop.filter((list)=>(`${list.fieldCode}__upload`== fields[_field].model) || `${list.fieldCode}upload`== fields[_field].model)
                    if(_temp_field.length==0){
                        _temp_field = _temp_loop.filter((list)=>list.fieldCode == fields[_field].model)
                    }
                    if(fields[_field].type=="fileupload_full"){
                        if(_temp_field && _temp_field.length>0){
                            fields[_field].rules = "";
                            fields[_field].additional_rules = "required";
                            fields[_field].files = _temp_field.map((list)=>{
                                return {
                                    file_name : list.docName,
                                    file_id : list.docId,
                                    checkListId : (list.checkListId) ? list.checkListId : '',
                                }
                            })

                            fields[_field].files = fields[_field].files.filter((files)=>files.file_name!='' && files.file_name!=null)
                        }
                        else{
                            // fields[_field].rules = "required";
                            // fields[_field].additional_rules = "required";
                            fields[_field].files = [];
                        }
                    }
                }  
                           
            }            
            else{
                if(typeof field_values == 'string'){
                    _temp_object[`${_last_obj}`] = field_values
                }
                else{
                    formatReportFields(fields, field_values, _temp_object)
                }
            }

            state.report_submit_details = {};
            state.report_submit_details = {
                fields : fields,
                type: _list_type,
                parent_object : _parent_obj,
                child_object : _last_obj,
                sub_page : _temp_page_name,
                page_name : page_name,
                main_id :_main_id,
                evidenceId
            }   
            state.report_submit_details.recived_fields = {};
            state.report_submit_details.recived_fields[`${_parent_obj}`] = _main_obj
            state.report_submit_field_values = _temp_object
            state.report_submit_intial = field_values
                     
        }      
    },

    GET_REPORT_QUTIONARY_DETAILS(state, payload){      
        let {report_page_request} = state
        let {filed_object, description} = report_page_request
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {} 
        let _temp_page_name = ""
        let _last_obj = '';
        let _list_type = '';
        let _parent_obj = '';
        let _main_obj = {}
        let field_values = {}
         if(fields && fields.length){
            let _temp_main = payload[filed_object]
            _parent_obj = filed_object
            field_values = Object.assign({},first(_temp_main))
            if(filed_object.includes('.')){
                let _splits =  filed_object.split('.')
                if(_splits.length){
                    let _check_obj = payload
                    for(let _split in _splits){
                        _last_obj = _splits[_split]
                        _check_obj = _check_obj[_splits[_split]]
                      
                        if(_check_obj.length>0 && _split==0){
                            _parent_obj = _splits[_split]
                            _check_obj = first(_check_obj)
                            _main_obj = _check_obj
                        }
                    }
                    field_values = Object.assign([],_check_obj)
                }
            }
           
           
            let _temp_obj = {}
            let _temp_loop = {}
            
             if(description=="Questionaire"){
                _list_type = "array"
                _temp_loop = field_values
                _temp_page_name = _last_obj
                for(let field_value in _temp_loop){
                   _temp_obj[_temp_loop[field_value].fieldCode] = _temp_loop[field_value].value
                }
             }
             else if(description=="fileUpload"){
              
                _temp_loop = field_values
                _temp_page_name = _last_obj
                for(let _field in fields){
                    let _temp_field = _temp_loop.filter((list)=>list.fieldCode== fields[_field].model)
                    if(_temp_field){
                        fields[_field].rules = "";
                        fields[_field].files = _temp_field.map((list)=>{
                            return {
                                file_name : list.docName,
                                file_id : list.docId,
                                checkListId : (list.checkListId) ? list.checkListId : '',
                            }
                        })
                        fields[_field].files = fields[_field].files.filter((files)=>files.file_name!='')
                    }
                    
                }
             }
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 type: _list_type,
                 parent_object : _parent_obj,
                 sub_page : _temp_page_name,
                 page_name : page_name,
                 recived_fields :  {patientDetails : _main_obj},
                 sub_sections : [],
            }
            state.report_submit = _temp_obj
         } 
         else{
            state.report_submit_details = {};
            state.report_submit_details = {
                fields : fields,
                type: 'array',
                parent_object : 'patientDetails',
                sub_page : _temp_page_name,
                page_name : page_name,
                recived_fields :  {patientDetails : _main_obj}
           }
         }       
        
         state.report_submit_field_values = _temp_object        
     },
 

    GET_REPORT_HOSPITAL_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload.length){
            let field_values = Object.assign({},first(payload))       
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields :  {hospitalDetails : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_REPORT_RADIOLOGY_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))       
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields :  {radiologyDetails : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_REPORT_MEDICAL_VISIT(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))       
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields :  {medocalStoreVisit : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_PATHOLOGY_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))        
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields :  {pathologyDetails : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_DEMOGRAPHY_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))        
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields : {demographyDetails : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_PATIENT_STMT(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))   
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields : {patientStatementDetails : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_TREAT_DOCTOR_STMT(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))         
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields :  {treatingDoctorStatementDetails : first(payload)},
                 
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_FAMILY_DOCTOR_STMT(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))      
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                fields : fields,
                page_name : page_name,
                type: '',
                parent_object : 'patientDetails',
                sub_page : 'discharge',
                recived_fields :  {familyDoctorStatementDetails : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_VINICITY_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))    
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 recived_fields :  {vicinityVerificationDetails : first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_PAYMENT_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload){
            let field_values = Object.assign({},first(payload))      
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields :  {paymentReceiptVerificationDetails: first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_REPORT_PAST_HOSPITAL_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload.length){
            let field_values = Object.assign({},first(payload))   
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 type: '',
                 parent_object : 'patientDetails',
                 sub_page : 'discharge',
                 recived_fields :  {pastHospitalDetails :first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_REPORT_QC_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload.length){
            let field_values = Object.assign({},first(payload))   
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 recived_fields :  {qcDetails :first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

     GET_REPORT_CASE_DETAILS(state, payload){      
        let {fields, page_name} = state.report_submit_details;      
        let _temp_object = {}      
         if(fields && fields.length && payload.length){
            let field_values = Object.assign({},first(payload))   
             formatReportFields(fields, field_values, _temp_object)  
             state.report_submit_details = {};
             state.report_submit_details = {
                 fields : fields,
                 page_name : page_name,
                 recived_fields :  {caseDetails :first(payload)}
             }            
         }        
         state.report_submit_field_values = _temp_object        
     },

    GET_QUICK_LINKS(state, payload){
        if(payload.length){
            state.quick_links = payload;
        }
        else{
            state.quick_links = [];
        }
    },

    GET_INVESTIGATION_HISTORY(state, payload){
        if(payload.length){
            let _temp_Details = payload.map((list)=>{
                let _temp_datas = list.createdDate;
                _temp_datas = (_temp_datas) ? _temp_datas.split(" ") : [];
                let _temp_date = ''
                let _temp_time = ''
                if(_temp_datas.length==3){
                    _temp_time = (_temp_datas[1]=='0:00' ? '12:00' :  _temp_datas[1])+' '+_temp_datas[2]
                    _temp_date = _temp_datas[0]
                }
                return {
                    id:list.id,
                    color: CaseHistoryDisplay(list.currentValue),
                    date : _temp_date,
                    time : _temp_time,
                    status_name : list.statusName,
                    updatd_by : list.createdName,
                    active : true,
                    remarks : list.remarks,
                    reasons : list.reasons,
                    email: list.createdName,
                }
            })
            state.investigation_history = _temp_Details;
        }
        else{
            state.investigation_history = [];
        }
    },

    GET_CLAIM_SUM(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        if(datas.length && payload){
            let field_values = first(payload)
            for(let data in datas){
                let _curr_obj = datas[data];
                datas[data].value = (_curr_obj.field  in field_values) ? field_values[_curr_obj.field] : ''
            }
            state.investigation_page_fields = {};
            state.investigation_page_fields = {
                datas : datas,
                page_name : page_name
            }
        }
    },

    GET_HOSPITAL_DETAILS(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        let {claimHospitalDetails} = payload;
        let field_values = claimHospitalDetails;
        if(datas.length && claimHospitalDetails){
            for(let data in datas){
                let _curr_obj = {...datas[data], ...payload};
                datas[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
            }
            state.investigation_page_fields = {};
            state.investigation_page_fields = {
                type:"text",
                datas : datas,
                page_name : page_name
            }
        }
    },

    
    GET_REPUDATION_DETAILS(state, payload){
     
        let {datas, page_name} = state.investigation_page_fields;
        let {denialReasons} = payload;
        let field_values = denialReasons;
        if(datas.length && denialReasons){
            for(let data in datas){
                let _curr_obj = {...datas[data], ...payload};
                datas[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
            }
            state.investigation_page_fields = {};
            state.investigation_page_fields = {
                datas : datas,
                page_name : page_name
            }
        }
    },

    GET_BEN_DETAILS(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        let {benificiaryDetails} = payload;
        let tabData = []
        if(benificiaryDetails.length>0 && datas.length>0){
            let temp = [...datas]
            let fields = []
            let fileterData = []
            let field_values = first(benificiaryDetails);
            if(datas.length && benificiaryDetails){
                fileterData = temp.filter((list)=>list.field&&list.field.includes('benificiaryDetails')) 
                
                if(fileterData && fileterData.length>0){
                    let data = payload['benificiaryDetails']
                    let temp_data = data.filter((list)=>list.role&&list.role=="BENFICIARY")
                    if(temp_data&&temp_data.length>0){
                        tabData = processBenifiDetails(fileterData,temp_data)
                    }else{
                        tabData = []
                    }
                }else{
                    fields = temp
                }
                if(fields&&fields.length>0){
                    for(let data in fields){
                        let _curr_obj = {...fields[data], ...payload};
                        fields[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
                    }
                }
                state.investigation_page_fields = {};
                state.investigation_page_fields = {
                    datas : fields,
                    page_name : page_name,
                    type:'text',
                    tabData
                }
            }
        }
    },

    

    GET_CLIM_HISTORY(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        state.investigation_page_fields = {};
        if(payload.length){
            let _table_header = datas.map((list)=>{
                if(stripHtml(list.field)!='null'){
                    let _check = (list.field.includes('.')) ? last(list.field.split('.')) : list.field;
                    return   {
                        name: stripHtml(_check),
                        sortField: stripHtml(_check),
                        title: stripHtml(list.displayName),
                        titleClass: "text-left",
                        dataClass: "text-left",
                        filterable: true,
                        togglable: true
                    }
                }
            })
            
            _table_header = _table_header.filter((list)=>list.name!='')
            state.investigation_page_fields = {
                datas : _table_header,
                table : payload,
                page_name : page_name,
                type:'table'
            }
        }
    },

    GET_CLAIM_DETAILS_SUM(state, payload){
        let {datas, page_name, case_form_data} = state.investigation_page_fields;
        let {claimAttribute} = payload;
        let field_values = claimAttribute;
        if(datas.length && claimAttribute){
            for(let data in datas){
                let _curr_obj = {...datas[data], ...payload};
                datas[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
            }
            state.investigation_page_fields = {};
            state.investigation_page_fields = {
                datas : datas,
                page_name : page_name,
                type:'text',
                field_values,
                payloadData : payload,
                case_form_data
            }
        }
    },
    FETCH_CLAIM_DETAILS_SUM(state, payload){
        state.fetch_claim_details = []
        if(payload && payload.length>0){
            state.fetch_claim_details = payload
        }
    },

    GET_HOSPITAL_BILL_DETAILS(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        state.investigation_page_fields = {};
        let field_values = payload.hospitalizationBillDetails;
        if(field_values.length){
            let _table_header = datas.map((list)=>{
                let _check = (list.field.includes('.')) ? last(list.field.split('.')) : list.field;
                if(stripHtml(list.field)!='null'){
                    return   {
                        name: stripHtml(_check),
                        sortField: stripHtml(_check),
                        title: stripHtml(list.displayName),
                        titleClass: "text-left",
                        dataClass: "text-left",
                        filterable: true,
                        togglable: true
                    }
                }
            })
            
            _table_header = _table_header.filter((list)=>list.name!='')
            state.investigation_page_fields = {
                datas : _table_header,
                table : field_values,
                page_name : page_name,
                type:'table'
            }
        }
    },



    
    
    GET_INVESTIGATION_TRIGGERS(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        state.investigation_page_fields = {};
        let field_values = payload;
        let _invest_id = '';
        if(field_values.length){
            let _table_header = datas.map((list)=>{
                if(stripHtml(list.field)!='null'){
                    let _check = (list.field.includes('.')) ? last(list.field.split('.')) : list.field;
                    _invest_id = list.investigationId;
                    return   {
                        name: stripHtml(_check),
                        sortField: stripHtml(_check),
                        title: stripHtml(list.displayName),
                        titleClass: "text-left",
                        dataClass: "text-left",
                        filterable: true,
                        togglable: true
                    }
                }
            })
            
            _table_header = _table_header.filter((list)=>list.name!='')
            state.investigation_page_fields = {
                datas : _table_header,
                table : field_values,
                page_name : page_name,
                invest_id : _invest_id,
                type:'table'
            }
        }
    },

   
    GET_TRIGGERS(state,payload){
        let _invest_id = '';
        if(payload.length){
            _invest_id = first(payload);
            _invest_id = _invest_id.investigationId;
            let _temp_datas = payload.map((list)=>{
                return {
                    ...list,
                    triggerDescription : (list.triggerDescription) ? list.triggerDescription : list.description
                }
            })
            state.triggers = {
                data : _temp_datas,
                page_id : _invest_id,
            }
        }
        else{
            state.triggers =  {
                data : [],
                page_id : '',
            }
        }
    },

    GET_ALL_TRIGGERS(state,payload){
        if(payload.length){
            state.all_triggers = payload.map((list)=>{
                return {
                    value : list.triggerCode,
                    text : list.triggerDescription,
                    ...list
                }
            })
        }
        else{
            state.all_triggers = [];
        }
    },


    async GET_MEMBER_ID(state, payload){ 
        let _invest_id = '';       
        let {datas=[], page_name, multi_section, mult_datas, case_form_data} = state.investigation_page_fields;  
        if(datas && datas.length>0 && payload && (!multi_section)){
            let temp = [...datas]
            let fileterContactData = []
            let fileterMemberData = []
            let tabData = []
            let fields    
            fileterContactData = temp.filter((list)=>list.field&&list.field.includes('contactDetails')) || []
            fileterMemberData = temp.filter((list)=>list.field&&list.field.includes('memberDetails')) || []
            if(fileterContactData.length>0){
                fields = temp.filter((list)=>list.field&&!list.field.includes('contactDetails')) || []
                tabData = processContactDetails(fileterContactData,payload,'contactDetails')     
            }else if(fileterMemberData.length>0){
                fields = temp.filter((list)=>list.field&&!list.field.includes('memberDetails')) || []
                tabData = processContactDetails(fileterMemberData,payload,'memberDetails') 
            }else{
                fields = temp
            }            

            let _temp_holder = payload.memberDetails
            let field_values = {...first(_temp_holder), ...payload}
            if(fields.length>0){
                for(let data in fields){
                    let _curr_obj = {...fields[data], ...payload};
                    fields[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
                }
            }    
            
            state.investigation_page_fields = {};
            state.investigation_page_fields = {
                all_datas : datas,
                datas : fields,
                page_name : page_name,
                type:'text',
                multi_card_type : multi_section,
                field_values,
                tabData,
                case_form_data
            }            
        }
        else if(multi_section){
            let _payload = {}
            let _temp_holder = payload.memberDetails
            let field_values = {...first(_temp_holder), ...payload}
            let temp = [...mult_datas]
            let fields = [];

            temp.forEach((field_list, index)=>{
                if(field_list){
                    if(field_list && field_list.tab_object && field_list.tab_object.includes(',')){
                        let _temp = field_list.tab_object.split(',')
                        if(_temp && _temp.length){
                            _temp.forEach((list)=>{
                                if(list!='responseData'){
                                    _payload = (list in payload) ? payload[list] : {}
                                }
                                else{
                                    // _payload = (list in payload) ? {...payload , ...payload[list]} : {}
                                }
                            })
                        }                    
                    }
                    else{
                        if(field_list.tab_object!='responseData'){
                            _payload = (field_list.tab_object in payload) ? payload[field_list.tab_object] : {}
                        }
                        else{
                            _payload = (field_list.tab_object in payload) ? {...payload} : {}
                        }
                    }
                }
                if(field_list.tab_type=="text"){
                    fields = [...field_list.tab_field, ...fields];
                    if(fields.length>0){
                        for(let data in fields){
                            let _curr_obj = {...fields[data], ...payload};
                            fields[data].value = ProcessCaseFields(_curr_obj, _payload, field_values)
                        }
                    } 
                }
                else if(field_list.tab_type=="tab"){
                    let tabData = []
                    let temp = [...field_list.tab_field]
                    let fileterData = []
                    fileterData = temp.filter((list)=>list.field) || []
                    if(fileterData && fileterData.length>0){
                        tabData = processTabDetails(fileterData,_payload, field_list.tab_name)
                        mult_datas[index].custom_tab = tabData;
                    }
                }
                else if(field_list.tab_type=="table"){
                    if(_payload.length){
                        let _table_header = datas.map((list)=>{
                            if(stripHtml(list.field)!='null'){
                                let _check = (list.field.includes('.')) ? last(list.field.split('.')) : list.field;
                                _invest_id = list.investigationId;
                                return   {
                                    name: stripHtml(_check),
                                    sortField: stripHtml(_check),
                                    title: stripHtml(list.displayName),
                                    titleClass: "text-left",
                                    dataClass: "text-left",
                                    filterable: true,
                                    togglable: true
                                }
                            }
                        })
                        
                        _table_header = _table_header.filter((list)=>list.name!='')
                        mult_datas[index].table_header = _table_header;
                        mult_datas[index].table_body = _payload;
                        mult_datas[index].invest_id  = _invest_id 
                    }
                }
                
            })
            state.investigation_page_fields = {};
            state.investigation_page_fields = {
                all_datas : mult_datas,
                datas : fields,
                page_name : page_name,
                type:'text',
                multi_card_type : multi_section,
                field_values,
                case_form_data
            }   
        }
    },


    async GET_CASE_LIST_FIELD(state, payload){ 
        let _invest_id = '';       
        let {page_name, multi_section, mult_datas, case_form_data} = state.investigation_page_fields;  
        if(multi_section){
            let _payload = {}
            let _temp_holder = payload.memberDetails
            let field_values = {...first(_temp_holder), ...payload}
            let temp = [...mult_datas]
            let fields = [];

            temp.forEach((field_list, index)=>{
                if(field_list){
                    if((field_list && field_list.tab_object && field_list.tab_object.includes(',')) && (field_list.tab_type=="tab" || field_list.tab_type=="table" || field_list.tab_type=="tabtable")){
                        let _temp = field_list.tab_object.split(',')
                        if(_temp && _temp.length){
                            _temp.forEach((list)=>{
                                if(list!='responseData'){
                                    let _temp_spkit = list.split('.')
                                    if(_temp_spkit && _temp_spkit.length){
                                        let _temp_playload = payload
                                        _temp_spkit.forEach((list)=>{
                                            _temp_playload = (list in _temp_playload) ? _temp_playload[list] : {}
                                        })
                                        _payload = _temp_playload
                                    }
                                    else{
                                        _payload = (list in payload) ? [...payload[list]] : {}
                                    }
                                   
                                }
                                else{
                                    // _payload = (list in payload) ? {...payload , ...payload[list]} : {}
                                }
                            })
                        }                    
                    }
                    else{
                        if(field_list.tab_object!='responseData'){
                            if(field_list.tab_object && field_list.tab_object.includes('.')){
                                let _temp_spkit = field_list.tab_object.split('.')
                                if(_temp_spkit && _temp_spkit.length){
                                    let _temp_playload = payload
                                    _temp_spkit.forEach((list)=>{
                                        _temp_playload = (list in _temp_playload) ? _temp_playload[list] : {}
                                    })
                                    _payload = _temp_playload
                                }
                            }
                            else{
                                _payload = (field_list.tab_object in payload) ? payload[field_list.tab_object] : {}
                            }
                        }
                        else{
                            _payload = payload
                        }
                    }
                }
                if(field_list.tab_type=="text"){
                    fields = [...field_list.tab_field, ...fields];
                    if(fields.length>0){
                        for(let data in fields){
                            let _curr_obj = {...fields[data], ...payload};
                            fields[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
                        }
                    } 
                }
                else if(field_list.tab_type=="tab"){
                    let tabData = []
                    let temp = [...field_list.tab_field]
                    let fileterData = []
                    fileterData = temp.filter((list)=>list.field) || []
                    if(fileterData && fileterData.length>0){
                        tabData = processTabDetails(fileterData,_payload, field_list.tab_name)
                        mult_datas[index].custom_tab = tabData;
                    }
                }
                else if(field_list.tab_type=="tabtable"){
                    let tabData = []
                    let temp = [...field_list.tab_field]
                    let fileterData = []
                    fileterData = temp.filter((list)=>list.field) || []
                    if(fileterData && fileterData.length>0){
                        tabData = processTabTableDetails(fileterData,_payload, field_list.tab_name)
                        mult_datas[index].table_tab_data = tabData;
                    }
                }
                else if(field_list.tab_type=="table"){
                    let _header = [];
                    let temp = [...field_list.tab_field]
                    if(temp.length){
                        let _table_header = temp.map((list)=>{
                            if(stripHtml(list.field)!='null'){
                                let _check = (list.field.includes('.')) ? last(list.field.split('.')) : list.field;
                                _invest_id = list.investigationId;
                                return   {
                                    name: stripHtml(_check),
                                    sortField: stripHtml(_check),
                                    title: stripHtml(list.displayName),
                                    titleClass: "text-left",
                                    dataClass: "text-left",
                                    filterable: true,
                                    togglable: true
                                }
                            }
                        })
                        
                        _table_header = _table_header.filter((list)=>list.name!='')
                        if(_table_header.length>0){
                            _header.push({
                                name: "SNO",
                                title: "No",    
                                titleClass: "text-left aligned",
                                dataClass: "text-left aligned"
                            })
                            _header = [..._header,..._table_header]
                        }
                        mult_datas[index].table_header = _header;
                        mult_datas[index].table_body = _payload;
                        mult_datas[index].invest_id  = _invest_id 
                    }
                }
                
            })
            state.investigation_page_fields = {};
            state.investigation_page_fields = {
                all_datas : mult_datas,
                datas : fields,
                page_name : page_name,
                type:'text',
                multi_card_type : multi_section,
                field_values,
                case_form_data,
                payloadData : payload,
            }   
        }
    },

    GET_POLICY_SUMMARY(state, payload){ 
         
        if(payload.length){
            state.policy_summary = payload;
        }
        else{
            state.policy_summary = [];
        }
    },

    GET_POLICY_DETAILS(state, payload){
        if(payload.length){
            state.policy_details = payload;
        }
        else{
            state.policy_details = [];
        }
    },

    DOWNLOAD_POLICY_DETAILS(state, payload){
        if(payload.length){
            state.policy_document = payload;
        }
        else{
            state.policy_document = [];
        }
    },

    GET_ENDORESMENT_HISTORY(state, payload){
        if(payload.length){
            state.endorsement_history = payload;
        }
        else{
            state.endorsement_history = [];
        }
    },

    GET_PROVIDER_DETAILS(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        let {providerDetails, contactDetails} = payload;
        if(contactDetails.length > 0){
            contactDetails = find(contactDetails, (list)=>list.typeOfContact=='Permanent')
        }
        
        if(providerDetails){
            let field_values =  {...providerDetails, ...contactDetails}
            if(datas.length){
                for(let data in datas){
                    let _curr_obj = {...datas[data], ...payload};
                    datas[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
                }
                state.investigation_page_fields = {};
                state.investigation_page_fields = {
                    datas : datas,
                    page_name : page_name,
                    type:'text'
                }
            }
        }
    },

    GET_CLAIM_PROCESS_DETAILS(state, payload){
        let {datas, page_name} = state.investigation_page_fields;
        let {claimHospitalDetails} = payload;
        if(claimHospitalDetails.length>0 && datas.length>0){
            let field_values = first(claimHospitalDetails);
            if(datas.length && claimHospitalDetails){
                for(let data in datas){
                    let _curr_obj = {...datas[data], ...payload};
                    datas[data].value = ProcessCaseFields(_curr_obj, payload, field_values)
                }
                state.investigation_page_fields = {};
                state.investigation_page_fields = {
                    datas : datas,
                    page_name : page_name,
                    type:'text'
                }
            }
        }
    },

    
    GET_CASE_LIST(state, payload){
        if(payload){
            state.case_list = payload;
        }
        else{
            state.case_list = [];
        }
    },

    GET_CASE_PAGE_FIELDS(state, payload){
        let case_form_data = []
        let _temp_field_groups = [];
        if(payload.length>0){
            let investigation_page_fields = payload;
            let _data = [];
            let _page_name = '';
            let _check_multi_section = false; 
            let _multi_section_field = [];
            if(investigation_page_fields && investigation_page_fields.length){
                let _temp_data = first(investigation_page_fields);
                if (typeof _temp_data !== 'undefined' && _temp_data.subSections && _temp_data.subSections.length){
                   let _temp_sub_sections = _temp_data.subSections; 
                    _temp_sub_sections =  sortBy(_temp_sub_sections, function (obj) {
                        return parseInt((obj.orderNo) ? obj.orderNo : 0);
                    }); 
                   if(_temp_sub_sections && _temp_sub_sections.length==0){
                        _temp_sub_sections = first(_temp_sub_sections)
                        if(_temp_sub_sections && _temp_sub_sections.fieldGroups.length){
                            _temp_field_groups  = _temp_sub_sections.fieldGroups
                            _temp_field_groups.forEach(list => {
                                list.fields.forEach((sub_field_list, index)=>{
                                    let _data_main = (sub_field_list.attributes && sub_field_list.attributes.startsWith("{")) ? JSON.parse(sub_field_list.attributes) : {
                                        "label_value":"6",        
                                        "type": "text",
                                        "columnClass": "col-lg-6",
                                    }   
                                    list.fields[index].orderNo = parseInt(sub_field_list.orderNo)
                                    list.fields[index].type = _data_main.type
                                })
                                let _temp_filed =  sortBy(list.fields, function (obj) {
                                    obj.displayName = obj.displayName.trim()
                                    return parseInt(obj.orderNo);
                                })
                                _page_name = list.displayName
                                _data = _temp_filed
                            });
                            if(_page_name=='Legal Search'){
                                let fields = first(_temp_sub_sections.fieldGroups).fields || []
                                if(fields && fields.length>0){
                                    case_form_data = genereateCaseFromData(fields)
                                }
                            }
                        }
                       
                   }
                   else if(_temp_sub_sections && _temp_sub_sections.length>=0){
                        _check_multi_section = true
                        _temp_sub_sections.forEach((_temp_sub_sections)=>{
                           
                            let _main_section = {
                                tab_name : _temp_sub_sections.displayName,
                                tab_type : _temp_sub_sections.description,
                                tab_object : _temp_sub_sections.objName,
                                custom_tab : [],
                                table_tab_data : [],
                                tab_field : '',
                                table_header : [],
                                table_body : [],
                            }
                            if(_temp_sub_sections && _temp_sub_sections.fieldGroups.length){
                                let _temp_field_groups  = _temp_sub_sections.fieldGroups
                                _temp_field_groups.forEach(list => {
                                    list.fields.forEach((sub_field_list, index)=>{
                                        let _data_main = (sub_field_list.attributes && sub_field_list.attributes.startsWith("{")) ? JSON.parse(sub_field_list.attributes) : {
                                            "label_value":"6",        
                                            "type": "text",
                                            "columnClass": "col-lg-6",
                                        }   
                                        list.fields[index].orderNo = parseInt(sub_field_list.orderNo)
                                        list.fields[index].type = _data_main.type
                                    })
                                    let _temp_filed =  sortBy(list.fields, function (obj) {
                                        obj.displayName = obj.displayName.trim()
                                        return parseInt(obj.orderNo);
                                    })
                                    _page_name = list.displayName
                                    _data = _temp_filed
                                });

                                if(_page_name=='Legal Search'){
                                    let fields = first(_temp_sub_sections.fieldGroups).fields || []
                                    if(fields && fields.length>0){
                                        case_form_data = genereateCaseFromData(fields)
                                    }
                                }
                            }
                            _main_section.tab_field = _data;
                            _multi_section_field.push(_main_section)
                        })
                   }
                }
            }

            state.investigation_page_fields = {
                datas : _data,
                page_name : _page_name,
                type:'text',
                multi_section : _check_multi_section,
                mult_datas : _multi_section_field,
                case_form_data
            }
        }
        else{
            state.investigation_page_fields = {
                datas : [],
                page_name : '',
                multi_section: '',
                mult_datas : [],
                case_form_data
            };
        }
    },
    
    LOOKUP_BY_TYPE(state, payload){   
        state.lookupby_type = []
        if(payload && payload.length){
            let _temp_data = first(payload).lookUpValues            
            let values = _temp_data.map((list)=>{
                return{
                    text : list.value,
                    value : list.value,
                }
            })            
            state.lookupby_type = values           
        }        
        else{
            state.lookupby_type = [];
        }
    },
    

    GET_ALLOWED_ACTIONS(state, payload){
        if(payload && payload.length){
            let _temp_details = payload.map((list)=>{
                let _data = (list.attribute && list.attribute.startsWith("{")) ? JSON.parse(list.attribute) : {
                    "class_name": "button-bordered",
                }   
                return {
                    id:list.id,
                    name : (list.displayName) ? list.displayName : '',
                    button_status : (list.isEnable) ? true : false,
                    action_id : list.actionId,
                    has_popup : (_data.hasPopUp) ?  _data.hasPopUp : false,
                    attribute : list.attribute,
                    action_code : (_data && _data.action_code) ? _data.action_code : '',
                    type :  list.type,
                    button_type :  (list.type=="Action") ? 'button' : 'button',
                    value : (list.displayName) ? (list.displayName.replaceAll(" ", "")).toUpperCase() : '',
                    class_name: _data.class_name,
                    code : list.code ? list.code : ''
                }
            })
            _temp_details = _temp_details.filter((list)=>list.name!='')
            state.allowed_actions = [..._temp_details];
        }
        else{
            state.allowed_actions = [];
        }
    },

    GET_POP_ALLOWED_ACTIONS(state, payload){
        if(payload && payload.length){
            let _temp_details = payload.map((list)=>{
                let _data = (list.attribute && list.attribute.startsWith("{")) ? JSON.parse(list.attribute) : {
                    "class_name": "button-bordered",
                }   
                return {
                    id:list.id,
                    name : (list.displayName) ? list.displayName : '',
                    button_status : (list.isEnable) ? true : false,
                    action_id : list.actionId,
                    has_popup : (_data.hasPopUp) ?  _data.hasPopUp : false,
                    attribute : list.attribute,
                    type :  list.type,
                    button_type :  (list.type=="Action") ? 'button' : 'button',
                    value : (list.displayName) ? (list.displayName.replaceAll(" ", "")).toUpperCase() : '',
                    class_name: _data.class_name,
                    code : list.code ? list.code : ''
                }
            })
            _temp_details = _temp_details.filter((list)=>list.name!='')
            state.pop_allowed_actions = [..._temp_details];
        }
        else{
            state.pop_allowed_actions = [];
        }
    },

    
    
    GET_CASE_CARD_DETAILS(state, payload){        
        if(payload){
            state.investigation_by_id = payload;
            state.case_card_data.map((list)=>{
                let caseValue = list.title
                let {investigationDetails, investigationTask} = payload 
                switch (caseValue) {
                    case "Case Id":
                        list.value = (investigationDetails) ? investigationDetails.invNo : ''
                        list.priority = investigationDetails ? investigationDetails.priority=="1" ? true :false : ''
                        list.isNonPotential = investigationDetails ? investigationDetails.isNonPotential=="1" ? true :false : false
                        break;  
                    case "Case Progress":
                        list.value = (investigationDetails) ? investigationDetails.progressPercent : ''
                        list.progressvalue = (investigationDetails) ? investigationDetails.progressPercent : ''
                        break; 
                    case "Claim Type":
                        list.value = (investigationDetails) ?  investigationDetails.claimType : ''                  
                        break;   
                    case "Case Assigned to":
                        list.value = (investigationTask) ? investigationTask.assignedToName : ''                  
                        break;  
                    case "Case Status":
                        list.value = (investigationTask) ? investigationTask.statusName : ''                
                        break;
                    case "TAT":
                        list.value = (investigationTask) ? investigationTask.tatBand : ''              
                        break  
                }
            })              
        }else{
            state.case_card_data = []
            state.investigation_by_id = {}
        }
          
    },  

    GET_MH_CARD_DETAILS(state, payload){        
        if(payload){
            state.investigation_by_id = payload;
            state.case_card_data.map((list)=>{
                let caseValue = list.title
                let {assignToName = '', caseId= '', claimType= '', status= '', tat= '', isFlag = false} = payload 
                switch (caseValue) {
                    case "Case Id":
                        list.value = caseId
                        list.isFlag = isFlag ? isFlag : false
                        break;  
                    case "Claim Type":
                        list.value = claimType                  
                        break;   
                    case "Case Assigned to":
                        list.value = assignToName                
                        break;  
                    case "Case Status":
                        list.value = status            
                        break;
                    case "TAT":
                        list.value = tat              
                        break  
                }
            })              
        }else{
            state.case_card_data = []
            state.investigation_by_id = {}
        }
          
    },

    GET_REPORT_PAGES(state, payload){     
        if(payload && payload.length){           
            state.report_sidebar = genereateReportPages(state, payload);            
        }
        else{
            state.report_sidebar = [];
        }  
     },

     GET_REPORT_PAGES_STATUS(state, payload){     
        if(payload && payload.length){           
            state.report_sidebar_for_status = genereateReportPages(state, payload);            
        }
        else{
            state.report_sidebar_for_status = [];
        }          
     },

    GET_DOC_LIST(state, payload){
        state.doc_list = payload.documentDetails
    },

    VIEW_COPY_POLICY(state, payload){        
        if(payload.length){
            state.view_copypolicy = payload;
        }
        else{
            state.view_copypolicy = [];
        }
    },

    SAVE_RATINGS(state, payload){
        state.save_ratings_response = payload;
    },
    VERIFY_PAN_DETAILS(state, payload){
    },
    REPORT_GRADE_QUESTIONNAIRES(state, payload){ 
        let {report_page_request} = state
        let { description} = report_page_request
        if(description=="table"){
            let header = []     
            let body = [] 
            forEach(payload,(data, index)=>{ 
                if(index==0){               
                    header = Object.keys(data)       
                    header.push("score")        
                }         
                body.push(data)
            }) 
            state.report_grade_questionnaires = {
                header,
                body
            } 
        }                
    },

    GET_HIERERACHY_DASHBOARD(state, payload){
        if(payload){
            state.dashboard_hiererachy = payload.responseData  
           
        }else{
            state.dashboard_hiererachy = []
          
        }
    },

    GET_DASHBOARD_COUNT(state, payload){
        let temp = []
        if(payload){
            temp = payload.map((list, index)=>{                
                return {
                    ...list,
                    card_main_class : `col-sm-12 col-md-4 col-lg-4 col-xl d-flex pr-md-0 ${(index>0) ? 'pr-lg-0' : 'pl-lg-0'}`,
                    card_count : (list) ? list.status_count : 0,
                    card_icon :  '',
                    card_title :  list.status_name,
                    card_link : (list) ? list.status_id : '',
                    id :  list.status_id
                }             
            }) 
            state.dashboard_status_count = temp   
           
        }else{
            state.dashboard_status_count = []
          
        }
    },
    CLEAR_POP_FORM_DATA(state){
        state.popup_pannel_form_data = {}
        state.pop_section_pannel_form_data = []
    },
    CLEAR_FORM_DATA(state){
        state.multi_section_form_data = {}
        state.multi_section_form_data_init = {}
        state.multi_section_pannel_form_data = []
        state.uploaded_document = [];
        state.popup_form_data = {};
    },

    CLEAR_FORM_POP_DATA(state){
        state.popup_form_data = {};
    },

    UPDATE_SINGLE_FORM_DATA(state, payload){
        state.popup_pannel_form_data = {...state.popup_pannel_form_data, ...payload}
    },

    RESPONSE_FAILURE(state, payload) {
        state.request_failure.status = true
        state.request_failure.message = payload.message
        state.request_failure.title = payload.title
        state.request_failure.varient =  payload.varient
        state.loader = false
    },    

    RESET_REQUEST(state) {
        state.request_failure.status = ''
    },

    GET_EVIDENCE_SCORE(state, payload){
        state.evdence_grid = payload
    },

    RESPONSE_SUCCESS(state, payload) {  
        state.request_failure.status = true
        state.request_failure.message = payload.message
        state.request_failure.title = payload.title
        state.request_failure.varient =  payload.varient
        state.loader = false
    },

    SET_LOGIN(state, payload){
        let _temp_details = payload.tokenParsed
        state.auth.token = payload.token;
        state.auth.auth = true
        state.auth.token_refresh = payload.refreshToken;
        state.auth.name = _temp_details.name;
        state.auth.email =  _temp_details.email;
        state.auth.type = _temp_details.typ;
        state.auth.keyclock = payload;
        state.auth.username = _temp_details.preferred_username;
        
    },

    REMOVE_LOGIN(){
        // state.auth.token = '';
        // state.auth.auth = '';
        // state.auth.token_refresh = '';
        // state.auth.name = '';
        // state.auth.email = '';
        // state.auth.type = '';
        // state.auth.keyclock = '';
    },

    UPDATE_INTREAM(state, payload){
        let _temp = state[payload.state_name]
        state[payload.state_name] = {..._temp, ...payload.value}
      
    },

    UPDATE_REPORT_INTREAM(state, payload){
        state[payload.state_name][payload.field] = payload.value           
    },

    UPDATE_STATE_VALUE(state, payload){
        state[payload.state_name] = payload.value           
    },

    ACTION_REASON(state, payload){
        state.action_reason = {}        
        if(payload.length){
            let _temp_data = first(payload)            
            state.action_reason.page_name = _temp_data.name
            state.action_reason.page_code = _temp_data.code
            state.action_reason.datas = _temp_data.reasonTypeValues.map((list)=>{
                return{
                    value : list.value,
                    text : list.value,
                }
            })
        }
        else{
            state.action_reason = {}     
            state.action_reason.page_name = ''
            state.action_reason.page_code = ''
            state.action_reason.datas = ''
        }
        
    },

    SAVE_WAIVE_OFF(state, payload){
        state.save_waive_off = payload
    },

    GET_EVIDENCE_BY_ID(state, payload) {
        state.isManualTrigger = payload.isManualTrigger
        let _temp_check = [];
        /* ------------------------- for select all disable ------------------------- */
        (((payload.evidenceMaster && payload.evidenceMaster.length == 0) || payload.evidenceMaster == null)
                && payload.isManualTrigger == false)
                    ? state.isSelectAllDisable = true : state.isSelectAllDisable = false                 
        
        if(payload.evidenceMaster || payload.invEvidences){
            let _temp_id = 0
            for(let evidenc_list in payload){
                if(payload[evidenc_list] && Array.isArray(payload[evidenc_list]) ){
                    let _evidence = payload[evidenc_list].map((list)=>{
                        _temp_id = _temp_id+1
                        return {
                            id : list.evidenceId,
                            name : list.name,
                            value :_temp_id+1,
                            evidenceId : list.evidenceId,
                            evidenceCode: list.evidenceCode,
                            checked : (evidenc_list == 'invEvidences') ? true : false
                        }
                    })
                
                    _evidence = _evidence.filter((list)=>list.name!=null && list.name!="" );
                    _temp_check.push(..._evidence)
                }
            }
           
            state.investigation_evidence = _temp_check
        }
        
        else{
            state.investigation_evidence = []
        }
    },

    GET_TAT_COUNT(state, payload){
        if(payload.length){
            state.tat_count = payload.map((list)=>{
                return {
                    day : list.tatBand,
                    value : list.count
                }
            })
        }
        else{
            state.tat_count = [{
                day : 'No Tatbands',
                value : ''
            }]
        }
        
    },
    GET_CASE_DROPDOWN(state, payload){
        state.caseDropdown = []
        if(payload && payload.dropdowns &&  payload.dropdowns.length > 0 ){
            payload.dropdowns.forEach(option => {
                state.caseDropdown.push({
                    name : option.text,
                    text : option.text,
                    value : option.value,
                })
            })
        }
    },
    GET_DASHBOARD_DROPDOWN(state, payload){
        state.dashboardDropdown = []
        if(payload && payload.dropdowns &&  payload.dropdowns.length > 0 ){
            payload.dropdowns.forEach(option => {
                state.dashboardDropdown.push({
                    name : option.text,
                    text : option.text,
                    value : option.value,
                })
            })
        }
    },
    GET_CASE_CHART_DATA(state, payload){
        state.caseChart = payload
    },
    GET_DASHBOARD_CHART_DATA(state, payload){
        state.dashboardChart = payload
    },
    GET_DASHBOARD_CHART(state, payload){
        let labelData = [];
        let chartData = [];
        let {responseData} = payload
        if(responseData && responseData.length){
            responseData.forEach((list)=>{
                chartData.push(list.count)
                labelData.push(list.date)
            })
        }
        state.dashbaord_data = {
            label : labelData,
            data : chartData,
        }
    },

    GET_FETCH_BUCKET_STATUS(state, payload){
        if(payload.length){
           let _temp = payload.map((list)=>{
                return {
                    text : list.name,
                    value : list.code
                }
            })
            
            state.report_bucket_status = _temp.filter((list)=>list.text!=null)
        }
        else{
            state.tat_count = [{
                "text":"Bucket Status",
                "value":""
            }]
        }
    },

    GET_INVESTIGATORS_LIST(state, payload){
      
        if(payload && payload.data &&payload.data.length){
            let _details = payload.data.map((list)=>{
                return {
                    text:list.investigatorName,
                    value:list.investigator_id,
                }
            })
            _details = _details.filter((list)=>list.text!=null && list.text!='null')
            state.investigatos = {
                case_id:'',
                case_investigator : _details
            }
        }
        else{
            state.investigatos = {
                case_id:'',
                case_investigator : []
            }
        }
    },

    POST_NOTIFY_UPDATE(state,payload){
        state.save_notify_form = payload
    },

    POST_INTREAM_UPDATE(state,payload){
        state.save_waive_off = payload
    },

    
    FORM_UPLOAD_DOCUMENT(state,payload){
        state.uploaded_document.push(payload.files)
    },


    async GET_DOWNLOAD_DOCUMENT(state,payload){
       let {apiRequest, responseData} = payload
       if((apiRequest.requests.docName && apiRequest.requests.docName.includes('.')) || (apiRequest.requests.docType)){
            let _content_type = ''
           if(apiRequest.requests.docType){
              _content_type = `${apiRequest.requests.docName}.${apiRequest.requests.docType}`
              _content_type = _content_type.split('.')
           }
           else{
             _content_type = apiRequest.requests.docName.split('.')
           }
          
           _content_type = last(_content_type);
           _content_type = ContentFormate(_content_type)
           downloadMobile(window, responseData, apiRequest.requests.docName)
           var a = document.createElement("a"); //Create <a>
           a.href = `data:${_content_type};base64,` + responseData; //Image Base64 Goes here
           a.download = apiRequest.requests.docName; //File name Here
           a.click(); //Downloaded file

       }
    },

    async GET_DOWNLOAD_DOCUMENT_PDF(state,payload){
        let {case_id} = state.report_page_request
        if(case_id){
            let _content_type = 'pdf';
            _content_type = ContentFormate(_content_type)
            var a = document.createElement("a"); //Create <a>
            a.href = `data:${_content_type};base64,` + payload; //Image Base64 Goes here
            a.download = `${case_id}.pdf`; //File name Here
            a.click(); //Downloaded file
        }
     },

    REPORT_REQ_DETAILS(state,payload){
        state.report_page_request = payload
    },

    GET_DELETE_DOCUMENT(state,payload){
        console.log('state,payload', state,payload)
    },

    UPDATE_DELETE_FILE(state,payload){
        let _state = state[payload.parent_obj]
        if(_state){
            let _find_field = _state
            if(_find_field && _find_field.fields.length){
                let _temp_filed =  _find_field.fields.find((list_namin)=>list_namin.model==payload.field_name)
                let _temp_index =  findIndex(_find_field.fields, (list_namin)=>list_namin.model==payload.field_name);
                if(_temp_filed && _temp_filed.files && _temp_filed.files.length){
                    let _temp_files = _temp_filed.files.filter((list)=>list.file_id!=payload.field_id)
                    _state.fields[_temp_index].files = _temp_files
                    if(_temp_files && _temp_files.length){
                        _state.fields[_temp_index].rules = "";
                        _state.fields[_temp_index].additional_rules = "";
                    }
                }
            }
           
        }
        state[payload.parent_obj] = {}
        state[payload.parent_obj] = {...state[payload.parent_obj], ..._state}

    },
    
    SAVE_INVEST_TRIGGERS(state,payload){
        state.save_invest_tiggers = payload
    },

    POST_UI_LOGS(){
        
    },
    LEGAL_SEARCH_PAGE_FIELDS(state,payload){
        let datas =  payload || []  
        state.legal_search_page_fields = []
        if(datas.length>0){
            let _temp_sub_field =  sortBy(datas, function (obj) {
                return parseInt(obj.orderNo);
            });               
            let result = _temp_sub_field.map((list, index)=>{   
                let data = (list.attributes && list.attributes.startsWith("{")) ? JSON.parse(list.attributes) : {
                        "label_value":"6",        
                        "type": "text",
                        "columnClass": "col-lg-6",
                }                       
                return{
                        label: list.displayName,
                        label_display: true,                                
                        id:index,  
                        rules: (list.isMandatory) ? 'required' : '',
                        init_rules: (list.isMandatory) ? 'required' : '',
                        model: !list.field.includes(".") ? stripHtml(list.field) : stripHtml(list.field.replaceAll(".", "__")),
                        placeholder: list.displayName,
                        type: data.type,
                        columnClass: data.columnClass ? data.columnClass : "col-lg-6",                                
                        fieldCode : list.fieldCode,
                        options:[],    
                        check_display:"true",                             
                        name: stripHtml(list.displayName),
                }
            }) 
            state.legal_search_page_fields = result
        } 
    },
    GET_LEGAL_SEARCH_LIST(state, payload){
        state.legal_search_list = {};
        let { results=[] } = payload 
        state.legal_search_list['data'] = results
    },    
    
    GET_LEGAL_SEARCH_REMARK_LIST(state, payload) {
        state.legalSearchRemarkList = payload.responseData;
        state.legalSearchRemarkStatus = payload.isResponseValid ? payload.isResponseValid : false ;
        
    },    
    GET_REPORT_HEADERS_FILTERS(state,payload){
        let data =  payload || []  
        let filterFields = []
        let tableHeaders = []
        state.report_page_fields = {
            filterData : filterFields,
            tableHeaders
        }
        if(size(data)>0&&data.reportFilters&&data.reportHeaders){
            if(data.reportFilters.length>0){
                let _temp_sub_field =  sortBy(data.reportFilters, function (obj) {
                    return parseInt(obj.id);
                }); 
                filterFields =  _temp_sub_field.map((list,index)=>{   
                    let data = (list.attributes && list.attributes.startsWith("{")) ? JSON.parse(list.attributes) : {                                 
                            "type": "text"                            
                    }  
                    let selectOptions
                    if(data.type=="select"){
                        if(list.selectValues){
                            selectOptions = [{
                               "text":`Select ${list.filterName}`,
                               "value":null,
                           }]   
                           selectOptions.push(...list.selectValues);
                       }else{
                           selectOptions = []
                       }
                    }else{
                        selectOptions = list.selectValues
                    }                    
                                       
                    return{
                            label: list.filterName,                                                          
                            id:index,  
                            rules: list.isMandatory ? "required" : '' ,
                            init_rules: list.isMandatory ? "required" : '' ,
                            model: list.model,
                            placeholder: list.filterName,
                            type: data.type,
                            columnClass: data.columnClass ? data.columnClass : "xs12",                                
                            fieldCode : list.code,
                            options:selectOptions,        
                            name: list.filterName,
                            apiUrl: list.apiUrl ? list.apiUrl : '',
                            min_date : '',
                    }
                })                
                state.report_page_fields['filterData'] = filterFields
            }   
            if(data.reportHeaders.length>0){
                let _temp_sub_header =  sortBy(data.reportHeaders, function (obj) {
                    return parseInt(obj.id);
                });
                tableHeaders =  _temp_sub_header.map((list)=>{   
                    let data = (list.attributes && list.attributes.startsWith("{")) ? JSON.parse(list.attributes) : {                                 
                            titleClass: "text-left",
                            dataClass: "text-left",
                            filterable: true,
                            togglable: true                          
                    } 
                    if(list.sortField){
                        return{
                            name: list.headerName,    
                            sortField: list.sortField,
                            title: list.title,
                            titleClass: data.titleClass,
                            dataClass: data.dataClass,
                            filterable: data.filterable,
                            togglable: data.togglable
                        }
                    }else{
                        return{
                            name: list.headerName, 
                            title: list.title,
                            titleClass: data.titleClass,
                            dataClass: data.dataClass,
                            filterable: data.filterable,
                            togglable: data.togglable
                        }
                    }                   
                    
                }) 
                state.report_page_fields['tableHeaders'] = tableHeaders
            }  
                   
        }
    },
   
    MAKE_ALL_AS_READ(state,payload){
        state.notification_status = payload
    },

    APPEND_FORM_VALUES(state,payload){
        if(size(payload)>0){
            state[payload.state] = payload.values
        }
    },
    GET_REPORT_PDF(state,payload){
        state.get_report_pdf = payload        
    },   

    GET_INVESTIGATION_COUNT(state, payload) {
        state.mhInvesticationCount = payload
    },

    GET_FORM_PAGE(state, payload){
        let tempSubPages = []
       
        if(payload && payload.length){
          
            payload.forEach((list_sub)=>{
                let tempSubPagesObjs = {}
                if(list_sub && list_sub.formPageSection){
                    list_sub.formPageSection.forEach((list_sub_sections)=>{
                        let pageData = (list_sub_sections.additionalInfo && list_sub_sections.additionalInfo.startsWith("{")) ? JSON.parse(list_sub_sections.additionalInfo) : {      
                            "pageCode": ""
                        }  
                        tempSubPagesObjs = {
                            description: list_sub_sections.description,
                            displayName: (list_sub_sections.displayName && list_sub_sections.displayName!='NA') ? list_sub_sections.displayName : list_sub_sections.description,
                            authorityCode: list_sub_sections.authorityCode,
                            sectionCode: list_sub_sections.sectionCode,
                            mainObjName : list_sub.objName,
                            url :  `/${replace(list_sub_sections.displayName.toLowerCase(),new RegExp(' ','g'),'-')}`,
                            mainDescription : list_sub.description,
                            mainDisplayName : list_sub.displayName,
                            mainSectionCode : list_sub.pageCode,
                            orderNo : parseFloat(list_sub_sections.orderNo),
                            secObjName : list_sub_sections.objName,
                            pageData: pageData
                        }
                        tempSubPages.push(tempSubPagesObjs)
                    })
                }
            })
        }
        tempSubPages = orderBy(tempSubPages, ['orderNo'],['asc']);
        tempSubPages = tempSubPages.filter(list=>list.description!='null')
        tempSubPages.forEach((list, index)=>{
            list.menuIndex = index
        })
        state.section_pages = tempSubPages;
    },

    SAVE_FORM_PAGE(state, payload){
        console.log('SAVE_FORM_PAGE', state, payload)
    },
    ACTIVE_SECTION(state, payload){
        state.active_section = payload
    },
    REMOVE_LOCAL_SOC(state, payload){
        let getDocList = state.uploaded_document
        if(getDocList && getDocList.length){
            state.uploaded_document = getDocList.filter((list)=>list.docSeq!=payload.docSeq)
        }
    },
    REMOVE_API_SOC(state, payload){
        let getDocList = state.doc_list
        if(getDocList && getDocList.length){
            let finalDOc = getDocList.filter((list)=>list.docSeq!=payload.docSeq)
            state.doc_list = finalDOc
        }
        else{
            state.doc_list = [...getDocList]
        }
    },

    REMOVE_UPLOADED_DOC(state, payload){
        let getDocList = state.uploaded_document
        if(getDocList && getDocList.length){
            let finalDOc = getDocList.filter((list)=>list.docSeq!=payload.docSeq)
            state.uploaded_document = finalDOc
        }
        else{
            state.uploaded_document = [...getDocList]
        }
    },
    UPDATE_TABLE_DATA(state, payload){
        let tempData = JSON.parse(JSON.stringify(state.tabel_form_data));
        if(tempData && tempData[payload.sectionName]){
            tempData[payload.sectionName].push(payload.sectionData);
            state.table_add_form_data = {};
            state.tabel_form_data = {}
            state.tabel_form_data = {...tempData}
        }
        else if(isEmpty(tempData) || typeof tempData[payload.sectionName]=='undefined'){
            tempData[payload.sectionName] = []
            tempData[payload.sectionName].push(payload.sectionData);
            state.table_add_form_data = {};
            state.tabel_form_data = {}
            state.tabel_form_data = {...tempData}
        }
    },
    RATING_PAGE_FIELDS(state, payload){
        state.ratingPageField = payload
    },
    RATING_DETAILS(state, payload){
        if(payload && payload.serviceRatingDetails){
            state.fetch_service_ratingDetails = payload.serviceRatingDetails;
        }
    },
    UPFATE_NEFT_DETAILS(state, mainpayload){
        if(Object.keys(mainpayload.insurerdBankDetails).length){
            state.UpdateNeftDetails=mainpayload.insurerdBankDetails;
        }
    },
    GET_PAGE_DETAILS(state, mainpayload){
        if (window.location.hash.includes("panverification")){
            state.tempdataPan = mainpayload
        }
        if(mainpayload && mainpayload['communicationDetails']){
            state.communication_list_datas = mainpayload['communicationDetails'];
        }
        if(mainpayload && mainpayload.invoiceDetails){
            state.invoiceButtonStatus = mainpayload.invoiceDetails.status ? mainpayload.invoiceDetails.status : "";
        }    
        // if(mainpayload && mainpayload['invoiceDetails']){
        //     state.feeInvoiceDatas = mainpayload['invoiceDetails'];
        //     (state.feeInvoiceDatas && state.feeInvoiceDatas.billDate) ? state.feeInvoiceDatas['billDate'] = convert_newDate_format(state.feeInvoiceDatas['billDate']) : state.feeInvoiceDatas;
        //     state.multi_section_form_data =  {...state.feeInvoiceDatas}      

        // }
        // mainpayload.invoiceDetails.billDate = convert_datetoiso(mainpayload.invoiceDetails.billDate)
        let tempObj = {};
        let {secObjName} = state.current_page_menu;
        if(!secObjName){
            secObjName = (state.active_page && state.active_page.secObjName) ? state.active_page.secObjName : ''
        }
        let payload = {};
        if(secObjName){ 
            payload ={...mainpayload[secObjName]}
        }
        if(!secObjName){
            payload ={...mainpayload}
        }       
     
        let all_page_fields =  state.all_page_feilds
        state.tabel_form_data = {};
        if(payload){
            for(let formObj in payload){
                if(Array.isArray(payload[formObj])){
                    let temPsection = state.multi_section_page_fields.datas.find(list=>list.header.authorityCode==formObj);
                    if(temPsection){
                        if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type=='table' || temPsection.header.type=='addTable' || temPsection.header.type=='popupTable' || temPsection.header.type=='table_display')){
                            state.tabel_form_data[temPsection.header.authorityCode] = []
                            if((payload[formObj] && payload[formObj].length)){
                                state.tabel_form_data[temPsection.header.authorityCode] = payload[formObj]
                            }
                            
                        }
                        else if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type=='reviewTable')){
                            temPsection && temPsection.body.forEach((formList)=>{
                                let checkFields =  payload[formObj].find((list)=>formList.model.includes(list.code))
                                if(checkFields){
                                    formList.weightage = checkFields.weightage
                                    tempObj[`${formList.model}`] = checkFields.score
                                }
                            })
                        }
                    }
                    else{
                        let sectionFields = state.multi_section_page_fields.datas
                        if(sectionFields){
                            let tempField = sectionFields.find(list=>list.header.type=="multiple")
                            let tempIndex =  findIndex(sectionFields, (list)=>list.header.type=="multiple");
                            let tempFieldSet = []
                            let firstSet  = {};
                            let finalArray = [];
                            if(tempIndex && tempField){
                                if(payload[formObj] && payload[formObj].length){
                                    firstSet = first(state.multi_section_page_fields.datas[tempIndex].body)
                                    for(let incForm = 0; incForm<payload[formObj].length; incForm++){
                                        tempFieldSet.push(firstSet)
                                    }
                                }

                                if(tempFieldSet && tempFieldSet.length){ 
                                    let temValueArray = [];
                                    payload[formObj].forEach((list)=>{
                                        let incVal = 0;
                                        for(let fieldSet in firstSet){
                                            let findTarget = list[last(firstSet[fieldSet].model.split('__'))]
                                            if(typeof temValueArray[incVal] == 'undefined'){
                                                temValueArray[incVal] = {}
                                            }
                                            if(typeof temValueArray[incVal][firstSet[fieldSet].model] == 'undefined'){
                                                temValueArray[incVal][firstSet[fieldSet].model] = []
                                            }
                                            temValueArray[incVal][firstSet[fieldSet].model].push(findTarget)
                                            incVal++;
                                        }
                                    })

                                    finalArray = temValueArray
                                    state.multi_section_pannel_form_data = finalArray
                                    state.multi_section_page_fields.datas[tempIndex].body = tempFieldSet
                                }
                            }
                            else if(formObj=='runtime'){
                            let formFields = sectionFields[0].body
                               let fieldData = payload[formObj]
                               if(formFields && formFields.length){
                                    fieldData.forEach((list)=>{
                                        let targetFIelds = formFields.find((sub_list)=>sub_list.fieldCode==list.fieldCode);
                                        if(targetFIelds){
                                            tempObj[`${targetFIelds.model}`] = list.fieldValue;
                                        }
                                    })
                               }
                            }
                        }
                    } 
                }
                else if(typeof payload[formObj]=='object'){
                    for(let subObj in payload[formObj]){
                        let checkType = all_page_fields.find(list=>list.model==`${formObj}__${subObj}` && list.type.includes('date'))
                        if(checkType){
                            tempObj[`${formObj}__${subObj}`] = convert_datetoiso(payload[formObj][subObj])
                        }
                        else{
                            tempObj[`${formObj}__${subObj}`] = payload[formObj][subObj]
                        }
                    }
                }
                else{
                    let checkType = all_page_fields.find(list=>list.model==formObj && (list.type.includes('date') || list.type.includes('timepicker')))

                    if(checkType){
                        tempObj[`${formObj}`] = convert_datetoiso(payload[formObj])
                    }
                    else{
                        tempObj[`${formObj}`] = payload[formObj]
                    }
                }
            }
            
        }
        else{
            
            tempObj = {}
            for(let formList in mainpayload){
                if(!Array.isArray(mainpayload[formList]) &&  (typeof mainpayload[formList]!='object')){
                    tempObj[formList] = (mainpayload[formList]) ? mainpayload[formList] : ''
                }
            }
        }
        let _multiText = all_page_fields.filter(list=>list.type.includes('multi_'))
        if(_multiText && _multiText.length){
            _multiText.forEach((list)=>{
                if(payload && !payload[list.model]){
                    tempObj[list.model] = []
                }
                else {
                    tempObj[list.model] = payload[list.model]
                }
            })
        }
        if(mainpayload && mainpayload['invoiceDetails'] && mainpayload['invoiceDetails']['billDate']){
            tempObj['billDate'] =  moment.utc(mainpayload['invoiceDetails']['billDate']).format();        
        }
        else if(mainpayload && mainpayload['neftDetails']){
            tempObj = {...mainpayload['neftDetails']}
        }
        state.multi_section_form_data = tempObj
        state.multi_section_form_data_init = tempObj
    },
    GET_CLAIM_DETAILS(state, mainpayload){
        let tempObj = {};
        let {secObjName} = state.active_menu;
        if(!secObjName){
            secObjName = (state.active_page && state.active_page.secObjName) ? state.active_page.secObjName : ''
        }
        let payload ={...mainpayload[secObjName]}
        if(!secObjName){
            payload ={...mainpayload}
        }

        let all_page_fields =  state.all_page_feilds
        state.tabel_form_data = {};
        if(payload){
            for(let formObj in payload){
                
                if(Array.isArray(payload[formObj])){
                    let temPsection = state.multi_section_page_fields.datas.find(list=>list.header.authorityCode==formObj);
                    if(temPsection){
                        if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type=='table' || temPsection.header.type=='addTable' || temPsection.header.type=='popupTable' || temPsection.header.type=='table_display')){
                            state.tabel_form_data[temPsection.header.authorityCode] = []
                            if((payload[formObj] && payload[formObj].length)){
                                state.tabel_form_data[temPsection.header.authorityCode] = payload[formObj]
                            }
                            
                        }
                        else if(temPsection && temPsection.header.type && temPsection && (temPsection.header.type=='reviewTable')){
                            temPsection && temPsection.body.forEach((formList)=>{
                                let checkFields =  payload[formObj].find((list)=>formList.model.includes(list.code))
                                if(checkFields){
                                    formList.weightage = checkFields.weightage
                                    tempObj[`${formList.model}`] = checkFields.score
                                }
                            })
                        }
                    }
                    else{
                        let sectionFields = state.multi_section_page_fields.datas
                        if(sectionFields){
                            let tempField = sectionFields.find(list=>list.header.type=="multiple")
                            let tempIndex =  findIndex(sectionFields, (list)=>list.header.type=="multiple");
                            let tempFieldSet = []
                            let firstSet  = {};
                            let finalArray = [];
                            if(tempIndex && tempField){
                                if(payload[formObj] && payload[formObj].length){
                                    firstSet = first(state.multi_section_page_fields.datas[tempIndex].body)
                                    for(let incForm = 0; incForm<payload[formObj].length; incForm++){
                                        tempFieldSet.push(firstSet)
                                    }
                                }

                                if(tempFieldSet && tempFieldSet.length){ 
                                    let temValueArray = [];
                                    payload[formObj].forEach((list)=>{
                                        let incVal = 0;
                                        for(let fieldSet in firstSet){
                                            let findTarget = list[last(firstSet[fieldSet].model.split('__'))]
                                            if(typeof temValueArray[incVal] == 'undefined'){
                                                temValueArray[incVal] = {}
                                            }
                                            if(typeof temValueArray[incVal][firstSet[fieldSet].model] == 'undefined'){
                                                temValueArray[incVal][firstSet[fieldSet].model] = []
                                            }
                                            temValueArray[incVal][firstSet[fieldSet].model].push(findTarget)
                                            incVal++;
                                        }
                                    })

                                    finalArray = temValueArray
                                    state.multi_section_pannel_form_data = finalArray
                                    state.multi_section_page_fields.datas[tempIndex].body = tempFieldSet
                                }
                            }
                            else if(formObj=='runtime'){
                            let formFields = sectionFields[0].body
                               let fieldData = payload[formObj]
                               if(formFields && formFields.length){
                                    fieldData.forEach((list)=>{
                                        let targetFIelds = formFields.find((sub_list)=>sub_list.fieldCode==list.fieldCode);
                                        if(targetFIelds){
                                            tempObj[`${targetFIelds.model}`] = list.fieldValue;
                                        }
                                    })
                               }
                            }
                        }
                    }
                }
                else if(typeof payload[formObj]=='object'){
                    for(let subObj in payload[formObj]){
                        let checkType = all_page_fields.find(list=>list.model==`${formObj}__${subObj}` && list.type.includes('date'))
                        if(checkType){
                            tempObj[`${formObj}__${subObj}`] = convert_datetoiso(payload[formObj][subObj])
                        }
                        else{
                            tempObj[`${formObj}__${subObj}`] = payload[formObj][subObj]
                        }
                    }
                }
                else{
                    let checkType = all_page_fields.find(list=>list.model==formObj && (list.type.includes('date') || list.type.includes('timepicker')))
                    if(checkType){
                        tempObj[`${formObj}`] = convert_datetoiso(payload[formObj])
                    }
                    else{
                        tempObj[`${formObj}`] = payload[formObj]
                    }
                }
            }
            
        }
        else{
            
            tempObj = {}
            for(let formList in mainpayload){
                if(!Array.isArray(mainpayload[formList]) &&  (typeof mainpayload[formList]!='object')){
                    tempObj[formList] = (mainpayload[formList]) ? mainpayload[formList] : ''
                }
            }
        }
        let _multiText = all_page_fields.filter(list=>list.type.includes('multi_'))
        if(_multiText && _multiText.length){
            _multiText.forEach((list)=>{
                if(payload && !payload[list.model]){
                    tempObj[list.model] = []
                }
                else {
                    tempObj[list.model] = payload[list.model]
                }
            })
        }
        state.claim_details = tempObj;
    },
    GET_LOOKUP_VALUES(state,payload){
        let {responseData,apiRequest} = payload
        if(responseData && responseData.lookupTypeValues &&responseData.lookupTypeValues.length){    
            
            let values = responseData.lookupTypeValues.map((list)=>{
                return{
                    text : list.value,
                    value : list.value,
                    code: list.value,
                }
            }) 
            
            if(apiRequest.requests.fieldName && apiRequest.requests.fieldType=='select'){
                state.lookupby_type = [ ...values]
            }
            else if(apiRequest.requests.fieldType=='select'){
                state.lookupby_type = values
            }
            else{
                state.lookupby_type = values
            }
        }        
        else{
            state.lookupby_type = [];
        }
    },

    GET_MASTER_LOOKUP(state, payload){
        let {responseData,apiRequest} = payload
        if(responseData && responseData.length){  
            let fieldsDetails  = orderBy(responseData, ['id'],['asc']); 
            let values = []
            if(apiRequest && apiRequest.requests && apiRequest.requests.code=='LINEOFBUS') {
                values = fieldsDetails.map((list)=>{
                    return{
                        text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        value : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        code: (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                    }
                }) 
            }
            else if(apiRequest && apiRequest.requests && apiRequest.requests.code=='INVESTIGATION_OFFICER_NAME') {
                values = fieldsDetails.map((list)=>{
                    return{
                        text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.email,
                        value : (list.userId) ? list.userId.trim()  : (list.userId) ? list.userId.trim()  : list.userId,
                        code: (list.code) ? list.code.trim()  : (list.code) ? list.code.trim()  : list.code,
                    }
                }) 
            }
            else if(apiRequest && apiRequest.url.includes('/fetchCaseStatus')){
                let tempList = fieldsDetails.map((list)=>{
                    return{
                        text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        value : (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        code: (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                    }
                })   
                values = [{text:'-- Select a Status --', value : '', code: ''}, ...tempList];
            }
            else{
                
                values = fieldsDetails.map((list)=>{
                    return{
                        text : (list.value) ? list.value.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        value : (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                        code: (list.code) ? list.code.trim()  : (list.firstName) ? list.firstName.trim()  : list.name,
                    }
                })   
            }

            if(apiRequest && apiRequest.url.includes('/fetchCaseStatus')){
                state.case_status = fieldsDetails
            }

           
                
            if(apiRequest.requests.fieldType=='select'){
                state.lookupby_type = values
            }
            else{
                state.lookupby_type = values
            }
        }        
        else{
            state.lookupby_type = [];
        }
    },

    UDPATE_CLAIM_REF(state, payload){
        state.claim_ref_number = payload
    },

    GET_USER_COUNT(state, payload){
       state.user_details = {...state.user_details, ...payload}
    },

    GET_SUMMARY_COUNT(state, payload){
        state.summary_details = {...state.user_details, ...payload}
    },

    UPDATE_FROM_DATE(state, payload){
        if(payload.form_key){
            state[payload.form_key] = payload.form_value
        }
    },

    GET_PINCODE_DATA(state, payload){
        let tempPayload = payload
        let tempData = {};
        for(let temp_payload in tempPayload){
            if(typeof tempPayload[temp_payload]=='string'){
                tempData[temp_payload] = tempPayload[temp_payload]
            }
            else if(temp_payload.includes('area')){
                state.area_list = tempPayload[temp_payload].map((list)=>{
                    return {
                        value : list.area,
                        text : list.area,
                    }
                })
            }
        }
        if(Object.keys(tempData).length){
            state.multi_section_form_data = {...state.multi_section_form_data, ...tempData}
            state.multi_section_form_data_init =  {...state.multi_section_form_data, ...tempData}
        }
    },

    GET_FLASH_MESSAGE(state, payload){
        let checkData = [];
        if(payload && payload.responseData){
            checkData = payload.responseData.map((list)=>{
                list.delayed_date =  timeAgo(convert_datetoiso((list.createdDate)))
                return list;
            })
            state.flash_message = checkData
        }
        else{
            state.flash_message = [] 
        }
    },

    GET_DOC_LIST_DASHBOARD(state, payload){
        let checkData = [];
        if(payload && payload.responseData){
            checkData = payload.responseData.map((list)=>{
                let maindata = (list.attributes && list.attributes.startsWith("{") && isJSON(list.attributes)) ? JSON.parse(list.attributes) : {      
                    "className": "",
                    "imgName" : "",
                } 
                return {...list, ...maindata};
            })
            state.dashbaord_doc_list = checkData
        }
        else{
            state.dashbaord_doc_list = [] 
        }
    },

    DOWNLOAD_BASE_64_EXECL(state,payload){
        let mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        downloadMobile(window, payload.base64String, payload.name.replace(/ /g, '-')+'.xlsx')
        let a = document.createElement('a');
        a.href = mediaType+payload.base64String;
        a.download = payload.name.replace(/ /g, '-')+'.xlsx';
        // a.textContent = 'Download file!';
        document.body.appendChild(a);
        a.click(); //Downloaded file
    },

    CURRENT_PAGE(state,playload){
        state.current_menu = playload
    },

    CURRENT_PAGE_MENU(state,playload){
        state.current_page_menu = playload
    },

    GET_COMMUNICATION_LIST(state,playload){
        state.communication_list = (playload.communicationDetails) ? playload.communicationDetails : {}
    },
    GET_SURVEYOR_DATA(state,payload){
        if(payload && payload.surveyDetails){
            state.surveyor_details_data =  payload.surveyDetails
        }
    },
    VERIFY_PAN_NUMBER(state,payload){
        if(payload && payload.panDetails && payload.panDetails.validFlag){
            state.valid_pan_verification = payload.panDetails;
        }
        else{
           state.valid_pan_verification = {}
        }
    },
    // REVIEW_PAGE_FIELDS(state,payload){
    //     if(payload){
    //         state.today_review_values = payload
    //     }
    // },
    MIS_REPORT_DATAS(state,payload){
        if(payload && payload.length){
            state.mis_report_data = payload
        }
    }   
    
}

